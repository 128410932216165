import { createSlice } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
// import firebase from 'firebase'
import { IProject } from '../../interfaces'
import type { AppDispatch } from '../store'
import { customEvent } from '../../utils/customHooks'
import firebase from 'firebase/compat/app'

interface ProjectsState {
  projects: IProject[]
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
  currentProject: IProject
  projectId: string | null
  roles: { [key: string]: string[] }
}

const initialState: ProjectsState = {
  projects: [],
  loading: 'idle',
  error: null,
  currentProject: {} as IProject,
  projectId: null,
  roles: {} as { [key: string]: string[] },
}

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload
    },
    setAllProjects: (state, action) => {
      state.projects = action.payload
      state.loading = 'succeeded'
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
    resetProjectState: (state) => {
      state.projects = []
      state.currentProject = {} as IProject
      state.projectId = null
    },
  },
})

export const {
  setCurrentProject,
  setProjectId,
  setAllProjects,
  setRoles,
  resetProjectState,
} = projectSlice.actions

export default projectSlice.reducer

export const fetchRoles = async (dispatch: AppDispatch): Promise<void> => {
  const roleDoc = await database.collection('roles').doc('project').get()
  const roles = roleDoc.data()
  dispatch(setRoles(roles))
}

export const fetchAllProjects = async (
  accountId: string,
  dispatch: AppDispatch,
): Promise<() => void> => {
  const projDocQuery = database
    .collection('projects')
    .where(`accountId`, '==', accountId)

  const unsubscribe = projDocQuery.onSnapshot((snapShot) => {
    const projects = snapShot.docs.map((doc) => {
      const parsedData = JSON.stringify(doc.data())
      const project = {
        ...JSON.parse(parsedData),
        id: doc.id,
      }
      return project
    })
    dispatch(setAllProjects(projects))
  })

  return () => unsubscribe()
}
export const fetchAllAccountsProjects = async (
  accountIds: string[],
  dispatch: AppDispatch,
): Promise<void> => {
  const unsubscribeFunctions: (() => void)[] = []
  const allProjects: IProject[] = []

  try {
    const projectPromises: Promise<IProject[]>[] = accountIds.map(
      (accountId) => {
        const projDocQuery = database
          .collection('projects')
          .where('accountId', '==', accountId)

        return new Promise((resolve) => {
          const unsubscribe = projDocQuery.onSnapshot((snapshot) => {
            const projects = snapshot.docs.map((doc) => {
              const parsedData = doc.data() as IProject
              return { ...parsedData, id: doc.id }
            })
            resolve(projects)
          })
          unsubscribeFunctions.push(unsubscribe)
        })
      },
    )

    const resolvedProjects = await Promise.all(projectPromises)
    allProjects.push(...resolvedProjects.flat())

    dispatch(setAllProjects(allProjects))
  } catch (error) {
    console.error('Error fetching projects:', error)
  } finally {
    unsubscribeFunctions.forEach((unsubscribe) => unsubscribe())
  }
}

export const addProject = async (
  accountId: string,
  name: string,
  userId: string,
  description?: string,
): Promise<void> => {
  const project = {
    accountId,
    name,
    description,
    status: 'active',
    users: {
      [userId]: { role: 'projectAdmin' },
    },
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updateAt: firebase.firestore.FieldValue.serverTimestamp(),
  }
  await database
    .collection('projects')
    .add(project)
    .then((doc) =>
      customEvent('project_created', {
        accountId: accountId,
        projectId: doc.id,
        user_userId: userId,
      }),
    )
}

export const editProject = async (
  id: string,
  name: string,
  description?: string,
): Promise<void> => {
  const project = {
    name,
    description,
    updateAt: firebase.firestore.FieldValue.serverTimestamp(),
  }
  await database.collection('projects').doc(id).update(project)
}

export const archiveProject = async (id: string): Promise<void> => {
  await database.collection('projects').doc(id).update({ status: 'archived' })
}

export const addMember = async (
  projectId: string,
  memberId: string,
  role: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    [`users.${memberId}`]: { role: role },
  })
}

export const editMemberRole = async (
  projectId: string,
  member: {
    [key: string]: { role: string }
  },
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.set(
    {
      [`users`]: member,
    },
    { merge: true },
  )
}

export const removeKeyword = async (
  projectId: string,
  keyword: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    keywords: firebase.firestore.FieldValue.arrayRemove(keyword),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const addNegativeKeyword = async (
  projectId: string,
  keyword: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    negativeKeywords: firebase.firestore.FieldValue.arrayUnion(keyword),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const removeNegativeKeyword = async (
  projectId: string,
  keyword: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    negativeKeywords: firebase.firestore.FieldValue.arrayRemove(keyword),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const updateProjectName = async (
  projectId: string,
  name: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    name,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const updateProjectWebsite = async (
  projectId: string,
  website: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    website,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const updateProjectDescription = async (
  projectId: string,
  description: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    description,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const updateProjectLanguage = async (
  projectId: string,
  contentGenerationLanguage: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  console.log('In UpdatingFunc - ProjectDocRef |=> ', projectDocRef)
  await projectDocRef.update({
    setting: {
      contentGenerationLanguage,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const updateStatusOfProject = async (
  projectId: string,
  status: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    status,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}
