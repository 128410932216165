import React, { useEffect, useState, useContext } from 'react'
// import { Share } from "@material-ui/icons"
import ReactGA from 'react-ga4'

import PopupUI from './PopupUI'
// import { compose } from "recompose"
// import { useFirestore } from "react-redux-firebase"
// import { connect } from "react-redux"
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import { lstnBasictheme } from '../../theme/lstn-theme-basic'
import { useTranslation } from 'react-i18next'
import { functions } from '../../firebaseApp'
import Toast from '../Toast/Toast'
import {
  IAPi,
  IFolders,
  IOrg,
  ITranscript,
  ITranscriptRow,
  IUser,
  IUserFeatures,
} from '../../interfaces'
import { shareChildFolders } from '../../utils/foldersData'
import firebase from 'firebase/compat/app'
import { roleType } from '../../enums'
import { RolesContext } from '../../context/ContextProvider'
import { useAppSelector } from '../../store/store'
import { database } from '../../firebaseApp'

export interface IUserEmails {
  accounts: {
    [key: string]: {
      role: string
      status: string
    }
  }
  organizations: IOrg[]
  profilePicture: string
  id?: string
  email?: string
  name?: string
  onboardingSteps: {
    uploadVideos: boolean
    transcriptionEdit: boolean
  }
  preferredLanguage?: string
  receiveNotification?: boolean
  transcriptionLanguage?: string
  showWarning?: boolean
  gaClientId?: string
  showPunctuation?: boolean
  filterProfanity?: boolean
  isEnterprise?: boolean
  createdAt?: Date
  features?: IUserFeatures
  apiKeys?: Array<IAPi>
  country_code?: string
  userId: string
}
export interface ShareValues {
  emails: string[]
  folderId: string
  role: string
}

export interface saveData {
  roleType?: string
  role?: string
  emails: IUserEmails[]
}
export interface IshareList {
  user: IUserEmails
  role: string
  shareData?: IUser
}
interface RolesContextType {
  selectedRole: string
  setLoader: (value: boolean) => void
}
interface Iprops {
  checkChildFoldersAccess: (folderId: string, name: string) => boolean
  closePopup: () => void
  isOpen: boolean
  rowsData: ITranscriptRow[] | null
  userId: string
}
function PopupContainer(props: Iprops) {
  const { t } = useTranslation()
  const [shareList, setShareList] = useState<IshareList[]>([])
  const [role, setRole] = useState('')
  const { selectedRole, setLoader } = useContext(
    RolesContext,
  ) as RolesContextType
  // const firestore = useFirestore()
  const { users } = useAppSelector((store) => store.user)
  const { allFolders, allSharedFolders, allTranscripts, allSharedTranscripts } =
    useAppSelector((store) => store.transcriptSlice)
  const [mergeAllFolders, setMergeAllFolders] = useState<IFolders[]>([])
  const [mergeAllTranscripts, setMergeAllTranscripts] = useState<ITranscript[]>(
    [],
  )
  // const handleClick = (e) => {
  // 	e.stopPropagation();
  // 	setIsOpen(true)
  // }
  useEffect(() => {
    setMergeAllFolders([...allFolders, ...allSharedFolders])
    setMergeAllTranscripts([...allTranscripts, ...allSharedTranscripts])
  }, [allFolders, allSharedFolders, allTranscripts, allSharedTranscripts])

  const getShareList = () => {
    try {
      if (props.rowsData && props.rowsData.length > 0) {
        setShareList([])
        let counter = 0
        const a = {}
        props.rowsData.map((folderData) => {
          if (folderData) {
            const userValue =
              folderData.status === roleType.FOLDER
                ? mergeAllFolders.filter(
                    (d: { id: string }) => d.id === folderData?.id,
                  )
                : mergeAllTranscripts.filter(
                    (d: ITranscript) => d.id === folderData?.id,
                  )
            const userId = userValue[0].userId
            const user: IUser = users[userId!]
            a[userId!] = { user: { ...user, userId }, role: 'owner' }

            const shareRefData: object | undefined =
              userValue[0].sharedUserRoles
            if (shareRefData) {
              Object.keys(shareRefData).map((d) => {
                if (userId !== d) {
                  const userId = d
                  const userData = users[userId]
                  if (a[userId] && a[userId].role !== shareRefData[d]) {
                    a[userId] = {
                      user: { ...userData, userId },
                      shareData: user,
                      role: roleType.VARIES,
                    }
                  } else if (
                    counter > 1 &&
                    (a[userId] == undefined || a[userId] == null)
                  ) {
                    a[userId] = {
                      user: { ...userData, userId },
                      shareData: user,
                      role: roleType.VARIES,
                    }
                  } else {
                    a[userId!] = {
                      user: { ...userData, userId },
                      shareData: user,
                      role: shareRefData[d],
                    }
                  }
                }
              })
            }
          }
          counter++
        })
        Object.values(a).map((d) => {
          // @ts-ignore
          setShareList((oArr: IshareList[]) => [...oArr, d])
        })
      }
    } catch (error) {
      console.error('Error in retriving Roles', error)
    }
  }

  const onUpdate = async (data: { emails: { [key: string]: string } }) => {
    try {
      if (data) {
        setLoader(true)
        await updatefolderShareID(data, props.rowsData as ITranscriptRow[])
        setLoader(false)
      }
    } catch (error) {
      console.error(error)
      Toast({
        title: t && t('folder.toastTitle'),
        text: (error as Error).message,
        variant: 'error',
      })
      // ReactGA.exception({
      //   description: error.message,
      //   fatal: false,
      // })
      ReactGA.gtag('event', 'onUpdate_exception', {
        description: (error as Error).message,
        fatal: false,
      })
    }
  }

  const onSave = async (data: saveData) => {
    try {
      if (data.emails && data.emails.length > 0) {
        setLoader(true)
        await folderShareID(
          { ...data, role: selectedRole },
          props.rowsData as ITranscriptRow[],
        )
        setLoader(false)
      }
    } catch (error) {
      console.error('Error in shareFolder onSave:: ', error)
      Toast({
        title: t && t('folder.toastTitle'),
        text: (error as Error).message,
        variant: 'error',
      })
      // ReactGA.exception({
      //   description: error.message,
      //   fatal: false,
      // })
      ReactGA.gtag('event', 'onSave_exception', {
        description: (error as Error).message,
        fatal: false,
      })
    }
  }

  const updatefolderShareID = async (
    data: { emails: { [key: string]: string } },
    rowData: ITranscriptRow[],
  ) => {
    try {
      if (rowData.length > 0) {
        await Promise.all(
          rowData.map(async (file) => {
            const permission = props.checkChildFoldersAccess(file.id, file.name)
            if (permission) {
              let ref: firebase.firestore.DocumentReference
              if (file.status === 'Folder')
                ref = database.doc(`folders/${file.id}/`)
              else ref = database.doc(`transcripts/${file.id}`)

              const folderData =
                file.status === 'Folder'
                  ? mergeAllFolders.filter((d: IFolders) => d.id === file.id)[0]
                  : mergeAllTranscripts.filter(
                      (d: ITranscript) => d.id === file.id,
                    )[0]
              let existingShareUserIds: object = folderData.sharedUserRoles
                ? folderData.sharedUserRoles
                : {}

              if (data && data.emails) {
                Object.keys(data.emails).map(async (d) => {
                  existingShareUserIds = {
                    ...existingShareUserIds,
                    [d]: data.emails[d],
                  }

                  await ref.update({
                    sharedUserRoles: existingShareUserIds,
                    sharedUserIds: firebase.firestore.FieldValue.arrayUnion(d),
                  })

                  if (file.status === roleType.FOLDER)
                    await shareChildFolders(
                      file.id,
                      d,
                      props.userId,
                      data.emails[d],
                      mergeAllTranscripts,
                      mergeAllFolders,
                    )
                })
              }

              Toast({
                title: t && t('folder.toastTitle'),
                text: t && t('folder.toastMessageUpdate'),
                variant: 'info',
              })
            }
            return true
          }),
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  const folderShareID = async (data: saveData, rowData: ITranscriptRow[]) => {
    try {
      if (rowData.length > 0) {
        await Promise.all(
          rowData.map(async (file) => {
            // let name: string = ""

            const permission = await props.checkChildFoldersAccess(
              file.id,
              file.name,
            )
            if (permission) {
              let ref: firebase.firestore.DocumentReference
              if (file.status === 'Folder') {
                ref = database.doc(`folders/${file.id}/`)
                // name = 'folder'
              } else {
                ref = database.doc(`transcripts/${file.id}`)
                // name = "transcript"
              }

              const folderData =
                file.status === 'Folder'
                  ? mergeAllFolders.filter(
                      (d: { id: string }) => d.id === file.id,
                    )[0]
                  : mergeAllTranscripts.filter(
                      (d: ITranscript) => d.id === file.id,
                    )[0]
              let existingShareUserIds: object = folderData.sharedUserRoles
                ? folderData.sharedUserRoles
                : {}

              for (let index = 0; index < data.emails.length; index++) {
                const email = data.emails[index]
                existingShareUserIds = {
                  ...existingShareUserIds,
                  [email.userId]: data.role,
                }

                await ref.update({
                  sharedUserRoles: existingShareUserIds,
                  sharedUserIds: firebase.firestore.FieldValue.arrayUnion(
                    email.userId,
                  ),
                })

                if (file.status === roleType.FOLDER)
                  await shareChildFolders(
                    file.id,
                    email?.userId,
                    props.userId,
                    data.role!,
                    mergeAllTranscripts,
                    mergeAllFolders,
                  )

                const afterShareEmail =
                  functions.httpsCallable('afterShareEmail')

                if (folderData) {
                  await afterShareEmail({
                    shareByUser: folderData.userId,
                    userId: email.userId,
                    action: 'shared',
                    type: 'folder',
                    url: '/transcript/folder/' + folderData.id,
                  })
                }
              }
            }
            // ReactGA.event({
            // 	action: `${name}_shared`,
            // 	category: `${name}s`,
            // 	label: file.id
            // })
            Toast({
              title: t && t('folder.toastTitle'),
              text: t && t('folder.toastMessage') + ' ' + file.name,
              variant: 'info',
            })
          }),
        )
      }
    } catch (err) {
      console.error('Error in folderShare ID', err)
    }
  }

  useEffect(() => {
    if (props.rowsData) {
      getShareList()
    }
  }, [props.rowsData, mergeAllFolders])

  return (
    <MuiThemeProvider theme={lstnBasictheme}>
      <PopupUI
        {...props}
        role={role}
        setRole={setRole}
        setIsOpen={props.closePopup}
        roleType={'fileMgmt'}
        defaultSelect={'sharedEdit'}
        onSave={onSave}
        onUpdate={onUpdate}
        shareList={shareList}
      />
    </MuiThemeProvider>
  )
}

// const mapStateToProps = (state) => {
// 	let allTranscripts: ITranscript[] = []
// 	let allFolders: IFolders[] = []

// 	if(state.firestore.ordered.allTranscripts){
// 		allTranscripts = state.firestore.ordered.allTranscripts
// 	}
// 	if(state.firestore.ordered.allSharedTranscripts){
// 		allTranscripts = [...allTranscripts,...state.firestore.ordered.allSharedTranscripts]
// 	}

// 	if(state.firestore.ordered.allFolders){
// 		allFolders = state.firestore.ordered.allFolders
// 	}
// 	if(state.firestore.ordered.allSharedFolders){
// 		allFolders = [...allFolders,...state.firestore.ordered.allSharedFolders]
// 	}

// 	return {
// 	  	userData: state.firestore.ordered.users,
// 		users: state.firestore.data.users,
// 		allTranscripts,
// 		allFolders
// 	}
// }

// const mapDispatchToProps = () => {
// 	return{

// 	}
// }

// const enhance = compose(withFirestore, connect(mapStateToProps, mapDispatchToProps))

export default PopupContainer
