export const markdownWordConverter = (markdown: string) => {
  let plainText = markdown.replace(/```[\s\S]*?```|`[^`\n]*`/g, '')

  plainText = plainText.replace(/<\/?[^>]+(>|$)/g, '')

  plainText = plainText.replace(/!\[([^\]]*)\]\([^)]*\)/g, '$1')

  plainText = plainText.replace(/\[([^\]]+)\]\([^)]*\)/g, '$1')

  plainText = plainText.replace(/(^|\n)#+\s*/g, ' ')

  plainText = plainText.replace(/(\*\*|__|\*|_)(.*?)\1/g, '$2')

  plainText = plainText.replace(/^\s*>+/gm, '')

  plainText = plainText.replace(/^\s*[-*+]\s+/gm, '')

  plainText = plainText.replace(/^\s*\d+\.\s+/gm, '')

  plainText = plainText.replace(/\s+/g, ' ').trim()

  const wordCount = plainText
    .split(/\s+/)
    .filter((word) => word.length > 0).length

  return wordCount
}
