import React, { useEffect, useState, useTransition } from 'react'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Tabs,
  TabsHeader,
  TabsBody,
  TabPanel,
  Tab,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import PricingCard from '../UpgradeModal/Card'
import { useTranslation } from 'react-i18next'
import { functions } from '../../firebaseApp'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { helperPlanData } from '../../store/features/planSlice'
import DialogComponent from '../Dialog'
import { IPlanData } from '../../store/features/planSlice'
import { customEvent } from '../../utils/customHooks'
import { PaymentSplashScreen } from '../SplashScreen'

interface IProps {
  UpgradeOpen: boolean
  handleUpgrade: () => void
}

interface Payload {
  accountId: string
  returnUrl: string
  planSpecificPortal: string
}
const UpgradeModal: React.FC<IProps> = ({ UpgradeOpen, handleUpgrade }) => {
  const { t } = useTranslation()
  const { plans } = useAppSelector((store) => store.plan)
  const { userStatus } = useAppSelector((store) => store.user)
  const [currentPlan, setCurrentPlan] = useState<IPlanData>()
  const [open, setOpen] = useState(false)

  const { currentProject } = useAppSelector((store) => store.project)
  const { user } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const accountId = currentAccount?.id
  // console.log(userStatus?.plan, 'plans')

  const [arePlansLoading, setArePlansLoading] = useState(true)
  const [isRedirectUrl, setIsRedirectUrl] = useState(false)
  const [isPending, startPending] = useTransition()

  useEffect(() => {
    if (plans.length > 0) {
      setArePlansLoading(false)
    }
  }, [plans])

  const dispatch = useAppDispatch()
  useEffect(() => {
    helperPlanData({ dispatch })
  }, [])
  useEffect(() => {
    if (plans && userStatus) {
      plans?.map((plan: IPlanData) => {
        if (
          userStatus &&
          userStatus.plan &&
          userStatus.plan.product === plan.planID
        ) {
          // console.log(plan.prices[0].unit_amount,'currentPlan')
          setCurrentPlan(plan)
        }
      })
    }
  }, [plans, userStatus])

  const handleOpen = (): void => {
    if (!open) {
      customEvent('demo_request_modal_opened', {
        projectId: currentProject?.id,
        accountId: currentProject?.accountId,
        user_userId: user.uid,
        actionSource: 'upgrade-dialog',
      })
    }

    setOpen(!open)
  }

  const handlePortal = async (id: string): Promise<void> => {
    try {
      setIsRedirectUrl(true)
      const payload: Payload = {
        accountId: accountId,
        returnUrl: window.location.origin + '/social-replies',
        planSpecificPortal: id,
      }
      const getCustomerPortalSessionUrl = functions.httpsCallable(
        'getCustomerPortalSessionUrl',
      )
      const response = await getCustomerPortalSessionUrl(payload)
      const url: string = response.data.url
      startPending(() => {
        window.location.href = url
      })
    } catch (error) {
      console.error(error)
      setIsRedirectUrl(false)
    }
  }

  return (
    <Dialog
      open={UpgradeOpen}
      handler={handleUpgrade}
      size={'xl'}
      className="overflow-scroll h-[95vh] w-full rounded-none"
      placeholder={undefined}
    >
      {arePlansLoading ? (
        // <div className="flex justify-center items-center min-h-[50vh]">
        //   <Spinner className="text-lstnGreen-500" />
        // </div>
        <PaymentSplashScreen />
      ) : isRedirectUrl || isPending ? (
        <PaymentSplashScreen />
      ) : (
        <div>
          <DialogHeader
            placeholder={undefined}
            className="flex justify-center my-6"
          >
            <div>
              <Typography
                color="black"
                className="text-3xl font-bold text-center sm:mb-2"
              >
                {t('upgradeModal.title')}
              </Typography>
              <Typography className="text-base font-normal hidden sm:block text-center">
                {t('upgradeModal.content')}
              </Typography>
            </div>
          </DialogHeader>
          <DialogBody
            placeholder={undefined}
            className="flex justify-center items-center"
          >
            <div className="block sm:hidden">
              <Tabs value="Starter" className="w-full mx-auto nav-tabs">
                <TabsHeader placeholder={undefined} className="w-80 mx-auto">
                  {plans
                    .filter(
                      (plan) =>
                        plan &&
                        plan.metadata.isVisible === 'true' &&
                        plan.stripe_metadata_allowedCredits != null,
                    )
                    .sort((a, b) => {
                      const secondsA = parseInt(
                        a.stripe_metadata_allowedCredits,
                        10,
                      )
                      const secondsB = parseInt(
                        b.stripe_metadata_allowedCredits,
                        10,
                      )
                      return secondsA - secondsB
                    })
                    .map((plan, index: React.Key | null | undefined) => {
                      return (
                        <Tab
                          key={index}
                          value={plan.name}
                          placeholder={undefined}
                        >
                          {plan.name}
                        </Tab>
                      )
                    })}
                </TabsHeader>
                <TabsBody placeholder={undefined}>
                  {plans
                    .filter(
                      (plan) =>
                        plan &&
                        plan.metadata.isVisible === 'true' &&
                        plan.stripe_metadata_allowedCredits != null,
                    )
                    .sort((a, b) => {
                      const secondsA = parseInt(
                        a.stripe_metadata_allowedCredits,
                        10,
                      )
                      const secondsB = parseInt(
                        b.stripe_metadata_allowedCredits,
                        10,
                      )
                      return secondsA - secondsB
                    })
                    .map((plan, index: React.Key | null | undefined) => {
                      return (
                        <TabPanel key={index} value={plan.name}>
                          <PricingCard
                            key={index}
                            index={index as number}
                            {...plan}
                            currentPlan={currentPlan}
                            selectedPlan={userStatus?.plan}
                            handleclick={handlePortal}
                            handleOpen={handleOpen}
                          />
                        </TabPanel>
                      )
                    })}
                </TabsBody>
              </Tabs>
            </div>
            <div className="hidden sm:grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mx-auto items-center">
              {plans
                .filter(
                  (plan) =>
                    plan &&
                    plan.metadata.isVisible === 'true' &&
                    plan.stripe_metadata_allowedCredits != null,
                )
                .sort((a, b) => {
                  const secondsA = parseInt(
                    a.stripe_metadata_allowedCredits,
                    10,
                  )
                  const secondsB = parseInt(
                    b.stripe_metadata_allowedCredits,
                    10,
                  )
                  return secondsA - secondsB
                })
                .map((plan, index: React.Key | null | undefined) => {
                  if (plan && plan.metadata.isVisible === 'true') {
                    return (
                      <PricingCard
                        key={index}
                        index={index as number}
                        {...plan}
                        currentPlan={currentPlan}
                        selectedPlan={userStatus?.plan}
                        handleclick={handlePortal}
                        handleOpen={handleOpen}
                      />
                    )
                  } else {
                    return null
                  }
                })}
            </div>
            <DialogComponent open={open} handleClose={handleOpen} />
          </DialogBody>
        </div>
      )}
    </Dialog>
  )
}

export default UpgradeModal
