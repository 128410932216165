import React, { useState, useEffect, useContext } from 'react'
import { roleType } from '../../enums'
import { IOrg, ITranscriptRow, IUserFeatures } from '../../interfaces'
// import { IUserStatusState } from '../../store/reducers/userStatus'
import { ExportValues } from '../ExportMenu/ExportMenu'
import ActionItems from '../Transcripts/ActionPanelItems'
// import { useFirestore, withFirestore } from 'react-redux-firebase'
// import { compose } from 'recompose'
// import { connect } from 'react-redux'
import { RolesContext } from './../../context/ContextProvider'
import { useAppSelector } from '../../store/store'
import { database } from '../../firebaseApp'
import { IUserStatus } from '../../interfaces/IUser'

interface userStatus {
  fetchStatusFailure: boolean
  fetchStatusProgress: boolean
  fetchStatusSuccess: boolean
  loading: string
  userStatus: IUserStatus
}
interface IProps {
  checkedRows: boolean
  checked: boolean
  onDelete: () => void
  onExport: (exportData: ExportValues) => void
  onRename: () => void
  onNewFolder: () => void
  userStatus: userStatus
  transcriptIds: string[]
  userFeatures?: IUserFeatures
  handleMoveFolder: () => void
  checkedRowsData: ITranscriptRow[]
  getItemsIds: (folderId: string) => void
  organizations: IOrg
  userId: string
  onBulkExport: (transcriptIds: string[], data: ExportValues) => void
  getFolderHeirarchy: (userId: string) => void
  location?: string
  openPopup?: (data: ITranscriptRow[]) => void
  handleSearchChange: () => void
}

const ActionArrange = (props: IProps) => {
  const [editFile, setEditFile] = useState(false)
  const [moveEnable, setMoveEnable] = useState(false)
  const [deleteEnable, setDeleteEnable] = useState(false)
  const [shareEnable, setShareEnable] = useState(false)
  const [folderCreateRole, setFolderCreateRole] = useState<string[]>(['*'])
  const { checked, checkedRowsData, checkedRows } = props
  const { setCreateTranscript } = useContext(RolesContext)
  // const firestore = useFirestore()
  const { roles } = useAppSelector((store) => store.user)

  useEffect(() => {
    const checkCreateFolder = async () => {
      const role = roles ? roles['fileMgmt'] : {}

      if (props.location) {
        const folderId = props.location.split('/')[3]
        if (folderId) {
          const folderData = (
            await database.collection('folders').doc(folderId).get()
          ).data()

          if (folderData) {
            if (folderData.userId === props.userId) {
              setFolderCreateRole(role[roleType.OWNER])
              setCreateTranscript(role[roleType.OWNER])
            } else if (
              folderData.sharedUserRoles &&
              folderData.sharedUserRoles[props.userId] === roleType.SHAREDEDIT
            ) {
              setFolderCreateRole(role[roleType.SHAREDEDIT])
              setCreateTranscript(role[roleType.SHAREDEDIT])
            } else {
              setFolderCreateRole(role[roleType.SHAREDREAD])
              setCreateTranscript(role[roleType.SHAREDREAD])
            }
          }
        }
      }
    }

    checkCreateFolder()
  }, [])

  useEffect(() => {
    if ((checkedRows || checked) && checkedRowsData) {
      // For Move Role check in files
      for (const file of checkedRowsData) {
        if (file.roles && file.roles?.includes(roleType.MOVE)) {
          setMoveEnable(true)
        } else {
          setMoveEnable(false)
          break
        }
      }

      // For Edit Role check in files
      for (const file of checkedRowsData) {
        if (file.roles && file.roles?.includes(roleType.EDIT)) {
          setEditFile(true)
        } else {
          setEditFile(false)
          break
        }
      }

      // For Delete Role check in files
      for (const file of checkedRowsData) {
        if (file.roles && file.roles?.includes(roleType.DELETE)) {
          setDeleteEnable(true)
        } else {
          setDeleteEnable(false)
          break
        }
      }

      // For Delete Role check in files
      for (const file of checkedRowsData) {
        if (file.roles && file.roles?.includes(roleType.SHARE)) {
          setShareEnable(true)
        } else {
          setShareEnable(false)
          break
        }
      }
    }
  }, [checked, checkedRows, checkedRowsData])

  return (
    <ActionItems
      {...props}
      folderCreateRole={folderCreateRole}
      share={shareEnable}
      move={moveEnable}
      edit={editFile}
      deleteCheck={deleteEnable}
    />
  )
}

// const mapStateToProps = (state: ) => {
//   let rolesValue

//   if (state.firestore.data.roles) rolesValue = state.firestore.data.roles[Object.keys(state.firestore.data.roles)[0]]

//   return {
//     rolesValue,
//   }
// }

// const enhance:  = compose(withFirestore)

export default ActionArrange
