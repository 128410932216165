import React from 'react'
import NotifyCard from '../components/NotifyCard'

import directoriesListingGif from '../icons/directories-listing.gif'
import { useTranslation } from 'react-i18next'

const DirectoriesListing = () => {
  const { t } = useTranslation()
  return (
    <div className="flex justify-center align-center items-center rounded-md">
      <NotifyCard
        gif={directoriesListingGif}
        classes="h-[12em] w-[20em] lg:h-[25em] lg:w-[60em]"
        title={t && t('directoriesListing.notifyTitle')}
        subtitle={t && t('directoriesListing.notifySubtitle')}
      />
    </div>
  )
}

export default DirectoriesListing
