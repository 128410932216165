import React from 'react'

import { formatBytes, formatDuration } from './utils'
import { IPreviewProps, IFileWithMeta } from './Dropzone'
//@ts-ignore
import cancelImg from './assets/cancel.svg'
//@ts-ignore
import removeImg from './assets/remove.svg'
//@ts-ignore
import restartImg from './assets/restart.svg'
import '../../css/uploader.css'

const iconByFn = {
  cancel: { backgroundImage: `url(${cancelImg})` },
  remove: { backgroundImage: `url(${removeImg})` },
  restart: { backgroundImage: `url(${restartImg})` },
}

interface IProps {
  renderDrop: (file: IFileWithMeta) => JSX.Element
}

const Preview: React.FC<IPreviewProps & IProps> = ({
  className,
  imageClassName,
  style,
  imageStyle,
  fileWithMeta: { cancel, remove, restart },
  fileWithMeta,
  meta: {
    name = '',
    percent = 0,
    size = 0,
    previewUrl,
    status,
    duration,
    validationError,
  },
  isUpload,
  canCancel,
  canRemove,
  canRestart,
  extra: { minSizeBytes },
  renderDrop,
}) => {
  let title = `${name || '?'}, ${formatBytes(size)}`

  if (duration) title = `${title}, ${formatDuration(duration)}`

  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">{title}</span>
        {status === 'error_file_size' && (
          <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>
        )}
        {status === 'error_validation' && (
          <span>{String(validationError)}</span>
        )}
        {canRemove && (
          <span
            className="dzu-previewButton"
            style={iconByFn.remove}
            onClick={remove}
          />
        )}
      </div>
    )
  }

  let errorType
  if (status === 'error_upload_params' || status === 'exception_upload') {
    errorType = `failed`
  } else if (status === 'error_upload') {
    errorType = 'file not supported'
  }
  if (typeof size === 'number' && size === 0) {
    errorType = `file is empty`
  }
  if (status === 'aborted') errorType = `cancelled`

  return (
    <div className={className} style={style}>
      {previewUrl && (
        <img
          className={imageClassName}
          style={imageStyle}
          src={previewUrl}
          alt={title}
          title={title}
        />
      )}
      {!previewUrl && (
        <span className="dzu-previewFileName">
          {title}{' '}
          {errorType && (
            <span style={{ color: 'red' }}> (upload error: {errorType})</span>
          )}
        </span>
      )}

      <div className="dzu-previewStatusContainer">
        {renderDrop(fileWithMeta)}
        {isUpload && (
          <progress
            max={100}
            value={
              status === 'done' || status === 'headers_received'
                ? 100
                : percent.toString()
            }
            className="uploader-preview-progress"
          />
        )}

        {status === 'uploading' && canCancel && (
          <span
            className="dzu-previewButton"
            data-cy="data-project-upload-cancel"
            style={iconByFn.cancel}
            onClick={cancel}
          />
        )}
        {status !== 'preparing' &&
          status !== 'getting_upload_params' &&
          status !== 'uploading' &&
          canRemove && (
            <span
              className="dzu-previewButton"
              style={iconByFn.remove}
              onClick={remove}
            />
          )}
        {[
          'error_upload_params',
          'exception_upload',
          'error_upload',
          'aborted',
          'ready',
        ].includes(status) &&
          canRestart && (
            <span
              className="dzu-previewButton"
              style={iconByFn.restart}
              onClick={restart}
            />
          )}
      </div>
    </div>
  )
}

export default Preview
