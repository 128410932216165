import * as yup from 'yup'

// Define the validation schema
const schema = yup.object().shape({
  domain: yup.string().url().required(),
  username: yup
    .string()
    .matches(/^[a-zA-Z0-9_]{3,}$/)
    .required(),
  token: yup
    .string()
    .matches(/^[a-zA-Z0-9]{16,}$/)
    .required(),
})

export const validateParameters = (
  domain: string,
  username: string,
  token: string,
): boolean => {
  try {
    const isValid = schema.isValidSync({ domain, username, token })
    if (isValid) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Invalid URL', error)
    return false
  }
}
