import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

interface IProps {
  children: React.ReactChildren | React.ReactElement | any
}

const Portal: React.FC<IProps> = ({ children }) => {
  const portalRootRef = useRef<HTMLElement | null>(
    document.getElementById('portal'),
  )
  const elRef = useRef<HTMLDivElement>(document.createElement('div'))

  useEffect(() => {
    const portalRoot = portalRootRef.current
    const el = elRef.current

    if (portalRoot) {
      portalRoot.appendChild(el)
    }

    return () => {
      if (portalRoot) {
        portalRoot.removeChild(el)
      }
    }
  }, [])

  return ReactDOM.createPortal(children, elRef.current)
}

export default Portal
