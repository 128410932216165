import React from 'react'
import ReactDOM from 'react-dom'

export const Button = React.forwardRef(
  ({ className, active, ...props }: any, ref) => (
    <>
      <span
        {...props}
        ref={ref}
        className={`${className} ${
          active?.match ? 'text-black' : 'text-gray-700/[0.4]'
        } ${
          active?.headingSpotted
            ? 'pointer-events-none'
            : 'pointer-events-auto	cursor-pointer'
        }`}
      />
    </>
  ),
)

export const EditorValue = React.forwardRef(
  ({ className, value, ...props }: any, ref) => {
    const textLines = value.document.nodes
      .map((node: any) => node.text)
      .toArray()
      .join('\n')
    return (
      <div
        ref={ref}
        {...props}
        className={`${className} mt-[30px] mb-[-20px] ml-0`}
      >
        <div
          className={
            'text-sm px-5 py-2 text-gray-700 border-t-2 border-gray-200 bg-gray-100'
          }
        >
          Slates value as text
        </div>
        <div
          className={
            'text-gray-700 text-sm font-mono whitespace-pre-wrap p-2 sm:p-5'
          }
        >
          {textLines}
        </div>
      </div>
    )
  },
)

export const Icon = React.forwardRef(({ className, ...props }: any, ref) => (
  <span
    {...props}
    ref={ref}
    className={`material-icons ${className} text-base align-text-bottom w-4 h-5`}
  />
))

export const Instruction = React.forwardRef(
  ({ className, ...props }: any, ref) => (
    <div
      {...props}
      ref={ref}
      className={`whitespace-pre-wrap ${className} mx-0 -mb-10 px-10 py-20 text-basebg-gray-100`}
    />
  ),
)

export const Menu = React.forwardRef(({ className, ...props }: any, ref) => (
  <div className="flex inline-block border-b-2 border-wellBlueGray-50 px-4 py-2">
    <div {...props} ref={ref} className={className} />
  </div>
))

export const Portal = ({ children }: any) => {
  return ReactDOM.createPortal(children, document.body)
}

export const Toolbar = React.forwardRef(({ className, ...props }: any, ref) => (
  <Menu
    {...props}
    ref={ref}
    className={`${className} relative pt-[1px] my-0 border-b-1`}
  />
))
