import React, { useCallback, useEffect, useRef, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebaseui/dist/firebaseui.css'
import '../css/SignUp.css'
import Typography from './Typography/Typography'
import { Input } from '@material-tailwind/react'
import LanguageSelector from './Signup/Select'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import i18n from '../i18n'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { customEvent } from '../utils/customHooks'
import { useTranslation } from 'react-i18next'
import Sidebar from './Signup/Sidebar'
import Toast from './Toast/Toast'
import SplashScreen from './SplashScreen'
import Button from './Button/TailwindButton'
import { getCustomTokenFromServer } from '../utils/getCustomTokenFromServer'

const trackingCode: string | undefined = process.env.REACT_APP_GA4_PROPERTY_ID
type Language = 'en' | 'fr' | 'es'
if (trackingCode) {
  ReactGA.initialize([
    {
      trackingId: trackingCode,
    },
  ])
}
const Index: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const passwordInputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()

  const SignUpFormSchema = Yup.object().shape({
    email: Yup.string().email().required('email is required'),
    password: Yup.string()
      .required()
      .min(8, 'Your password has to have at least 8 character'),
  })
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.uid) {
        setLoading(true)
      }
    })
  }, [])
  const handleLanguageSelect = async (value: string) => {
    const ln = value || ''
    // setDisplayLanguage(ln as Language)
    i18n.changeLanguage(ln as Language)
    localStorage.setItem('wellshared-display-lng', ln as Language)
  }

  const handleSignIn = useCallback(
    async (
      values: { email: string; password: string },
      formikHelpers: FormikHelpers<{ email: string; password: string }>,
    ) => {
      const { email, password } = values
      if (email && password) {
        setLoading(true)
        customEvent('log_in_requested', {
          category: 'auth',
          authAttribute: 'email_auth',
        })
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(async (userCredential) => {
            const user = userCredential.user
            if (user) {
              customEvent('log_in', {
                category: 'auth',
                authAttribute: 'email_auth',
                user_userId: user.uid,
              })
            }
            const token = await getCustomTokenFromServer()
            if (token?.length) {
              // console.log('Token', token)
              window.postMessage({ token: token }, '*')
            }
            setLoading(true)
          })
          .catch((error) => {
            const errorMessage = error.message
            console.log(errorMessage)
            formikHelpers.resetForm({ values: { ...values, password: '' } })
            setLoading(false)
            Toast({
              title: t && t('auth.toast.title'),
              text: t && t('auth.toast.incorrectPasswordText'),
              variant: 'error',
            })
          })
      }
    },
    [],
  )
  const handleSignGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    customEvent('log_in_requested', {
      category: 'auth',
      authAttribute: 'google_auth',
    })
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const user = result.user
        if (user) {
          customEvent('log_in', {
            category: 'auth',
            authAttribute: 'google_auth',
            user_userId: user.uid,
          })
        }
      })
      .catch((error) => {
        console.log(error.message)
        // ...
      })
  }

  return (
    <>
      {loading && <SplashScreen />}
      <div className="flex ">
        <div className="hidden h-screen bg-gradient-to-t from-[#F2DCFC] to-[#F3F8FF] md:flex lg:w-1/3 md:w-2/5 flex-col justify-between">
          <Sidebar />
        </div>
        <div className="w-full pt-20 lg:w-3/4 md:w-3/5 p-6 h-auto md:pt-4 lg:pt-4 xl:pt-4 flex justify-center items-center relative ">
          <div className="absolute right-10 top-8">
            <LanguageSelector onSelect={handleLanguageSelect} />
          </div>
          <div className="md:w-1/2 mx-auto w-full sm:w-96 lg:w-2/5 xl:w-2/5 md:h-auto ">
            <Typography className="p-2 text-center text-3xl font-semibold text-lstnBlueGray-900">
              {t && t('auth.logInAccount')}
            </Typography>
            <div className="flex flex-col items-center gap-2 2xl:gap-6">
              <Button
                color="white"
                className="!border !border-blue-gray-50 bg-white flex items-center text-sm font-bold text-lstnBlueGray-900 gap-3 w-full justify-center my-2"
                onClick={handleSignGoogle}
              >
                <img
                  src="https://docs.material-tailwind.com/icons/google.svg"
                  alt="metamask"
                  className="h-4 w-4"
                />
                {t && t('auth.signInwithGoogle')}
              </Button>
              {t('auth.or')}
            </div>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={handleSignIn}
              validationSchema={SignUpFormSchema}
              validateOnMount={true}
            >
              {({
                values,
                errors,
                // isValid,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form>
                  <div className="mt-2 mb-2 w-full max-w-screen-lg">
                    <div className="mb-1 flex flex-col gap-4">
                      <div>
                        <Typography
                          variant="h6"
                          className="mb-2 text-sm font-normal text-lstnBlueGray-900"
                        >
                          {t && t('auth.yourEmail')}
                        </Typography>
                        <Input
                          size="lg"
                          placeholder="jondoe@example.com"
                          className=" !border-blue-gray-200 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
                          labelProps={{
                            className: 'hidden',
                          }}
                          data-cy="email-login"
                          autoCapitalize="none"
                          type="email"
                          id="email"
                          // className="custom-input"
                          onChange={handleChange('email')}
                          onBlur={handleBlur('email')}
                          value={values.email}
                          crossOrigin={undefined}
                          onKeyPress={(
                            event: React.KeyboardEvent<HTMLInputElement>,
                          ) => {
                            if (event.key === 'Enter') {
                              event.preventDefault()
                              if (
                                passwordInputRef.current != null &&
                                passwordInputRef.current.children[0] != null
                              ) {
                                ;(
                                  passwordInputRef.current
                                    .children[0] as HTMLInputElement
                                ).focus()
                              }
                            }
                          }}
                        />
                        {touched.email && errors.email && (
                          <Typography
                            color="gray"
                            className="mt-2 flex items-center gap-1 text-xs font-normal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="-mt-px h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {errors.email}
                          </Typography>
                        )}
                      </div>
                      <div>
                        <Typography
                          variant="h6"
                          className="mb-2 text-sm font-normal text-lstnBlueGray-900"
                        >
                          {t && t('auth.password')}
                        </Typography>
                        <Input
                          size="lg"
                          placeholder={t && t('auth.passwordPlaceholder')}
                          className=" !border-blue-gray-200 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
                          labelProps={{
                            className: 'hidden',
                          }}
                          autoCapitalize="none"
                          data-cy="password-login"
                          type="password"
                          id="password"
                          ref={passwordInputRef}
                          onChange={handleChange('password')}
                          onBlur={handleBlur('password')}
                          value={values.password}
                          crossOrigin={undefined}
                          onKeyPress={(
                            event: React.KeyboardEvent<HTMLInputElement>,
                          ) => {
                            if (event.key === 'Enter') {
                              event.preventDefault()
                              handleSubmit()
                            }
                          }}
                        />
                        {touched.password && errors.password && (
                          <Typography
                            color="gray"
                            className="mt-2 flex items-center gap-1 text-xs font-normal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="-mt-px h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {errors.password}
                          </Typography>
                        )}
                      </div>
                    </div>
                    <Button
                      data-cy="login-button"
                      className="mt-10 bg-green text-sm font-bold uppercase"
                      fullWidth
                      type="submit"
                    >
                      {t && t('auth.logIn')}
                    </Button>
                    <div className="text-center mt-6">
                      <Link
                        to="/reset-password"
                        className="font-bold text-base underline text-lstnBlueGray-900 hover:text-blue-gray-900"
                      >
                        {t && t('auth.forgot')}
                      </Link>
                    </div>

                    <Typography className="mt-8 text-center text-base text-lstnBlueGray-700 font-normal">
                      {t && t('auth.dontAccount')}{' '}
                      <Link
                        to="/sign-up"
                        className="font-medium text-gray-900 hover:text-blue-gray-900"
                      >
                        {t && t('auth.dontAccountSignUp')}
                      </Link>
                    </Typography>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
