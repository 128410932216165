import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'

export interface IContentIdea {
  id: string
  createdAt: Date
  accountId: string
  projectId: string
  archived: boolean
  noContentIdeaRequested: number
  ideas: {
    id: string
    title: string
    description: string
    archived: boolean
    contentGenerated?: boolean
  }[]
}

interface IContentIdeaState {
  contentIdeas: IContentIdea[]
  subscribe: boolean
  loading: boolean
  error: string
}
const initialState = {
  contentIdeas: [],
  subscribe: false,
  loading: false,
  error: '',
} as IContentIdeaState

export const fetchContentIdeas = createAsyncThunk(
  'contentIdeas/fetchContentIdeas',
  async (projectId: string) => {
    try {
      if (!projectId) {
        return
      }
      const contentIdeasQuery = database
        .collection('contentIdeas')
        .where('projectId', '==', projectId)
        .orderBy('createdAt', 'desc')

      const snapshot = await contentIdeasQuery.get()
      const contentIdeas: IContentIdea[] = snapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id }) as IContentIdea,
      )
      return contentIdeas
    } catch (error) {
      console.error('Error fetching content ideas:', error)
      throw new Error('Error fetching content ideas')
    }
  },
)

export const hideContentIdea = createAsyncThunk(
  'contentIdeas/hideContentIdea',
  async (data: {
    contentIdeaId: string
    ideaId: string
    archivedStatus: boolean
  }) => {
    try {
      const { contentIdeaId, ideaId, archivedStatus } = data

      const ideaDocRef = database.collection('contentIdeas').doc(contentIdeaId)
      const ideaDoc = await ideaDocRef.get()
      if (!ideaDoc.exists) {
        throw new Error('Content Idea does not exist')
      }

      const ideaData = ideaDoc.data() as IContentIdea
      const ideas = ideaData.ideas.map((idea) => {
        if (idea.id === ideaId) {
          idea.archived = archivedStatus
        }
        return idea
      })

      await ideaDocRef.update({ ideas })
      return ideaData
    } catch (error) {
      console.log('Error hiding content idea:', error)
      throw error
    }
  },
)

export const contentIdeaGenerated = createAsyncThunk(
  'contentIdeas/contentIdeaGenerated',
  async (data: {
    contentIdeaId: string
    ideaId: string
    contentGenerated: boolean
  }) => {
    try {
      const { contentIdeaId, ideaId, contentGenerated } = data

      const ideaDocRef = database.collection('contentIdeas').doc(contentIdeaId)
      const ideaDoc = await ideaDocRef.get()
      if (!ideaDoc.exists) {
        throw new Error('Content Idea does not exist')
      }

      const ideaData = ideaDoc.data() as IContentIdea
      const ideas = ideaData.ideas.map((idea) => {
        if (idea.id === ideaId) {
          idea.contentGenerated = contentGenerated
        }
        return idea
      })

      await ideaDocRef.update({ ideas })
      return ideaData
    } catch (error) {
      console.log('Error hiding content idea:', error)
      throw error
    }
  },
)

export const subscribeToContentIdeas = (projectId: string) => {
  return (dispatch: any) => {
    const contentIdeasQuery = database
      .collection('contentIdeas')
      .where('projectId', '==', projectId)
      .orderBy('createdAt', 'desc')

    const unsubscribe = contentIdeasQuery.onSnapshot((snapshot) => {
      const contentIdeas: IContentIdea[] = snapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id }) as IContentIdea,
      )
      dispatch(updateContentIdeas(contentIdeas as any))
    })
    return unsubscribe
  }
}

const updateContentIdeas = createAction('contentIdeas/updateContentIdeas')

export const contentIdeaSlice = createSlice({
  name: 'contentIdeas',
  initialState,
  reducers: {
    updateContentIdeas: (state, action: PayloadAction<IContentIdea[]>) => {
      state.contentIdeas = action.payload
      state.subscribe = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContentIdeas.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    builder.addCase(fetchContentIdeas.fulfilled, (state, action) => {
      state.loading = false
      state.contentIdeas = action.payload !== undefined ? action.payload : []
    })
    builder.addCase(fetchContentIdeas.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || 'Error fetching content ideas'
    })
  },
})

export default contentIdeaSlice.reducer
