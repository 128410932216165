import React, { useCallback } from 'react'
import { Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useTranslation } from 'react-i18next'
import { RootState, useAppSelector } from '../../store/store'
import UserProfileAvatar from '../UserProfileAvatar'
import { IconButton } from '@material-ui/core'
import ImagePlaceholderSkeleton from '../ImagePlaceholderSkeleton'
import SettingsIcon from '@material-ui/icons/Settings'
import { useNavigate } from 'react-router-dom'

export function MenuDefault(): JSX.Element {
  const { userData, fetchStatusProgress } = useAppSelector(
    (store: RootState) => store.user,
  )
  const { currentAccount } = useAppSelector((store) => store.account)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const settings = useCallback(() => {
    navigate('/settings')
  }, [navigate])
  const accountSettings = useCallback(() => {
    navigate('/account-settings')
  }, [navigate])
  const userAccount = currentAccount.users
    ? currentAccount.users[String(userData.id)]
    : null
  return (
    <Menu>
      <MenuHandler className="cursor-pointer" data-cy="user-menu">
        <IconButton size="small" disabled={!userData}>
          {!userData ? (
            // <CircularProgress style={{ color: '#04c61d' }} />
            <div style={{ width: '35px', height: '35px' }}>
              <ImagePlaceholderSkeleton />
            </div>
          ) : (
            <UserProfileAvatar
              userName={
                userData.name
                  ? userData.name
                  : fetchStatusProgress
                    ? 'Profile Picture'
                    : ''
              }
              avatarUrl={String(userData.profilePicture)}
              width="35px"
              height="35px"
            />
          )}
        </IconButton>
      </MenuHandler>
      <MenuList placeholder={undefined}>
        <MenuItem
          placeholder={undefined}
          onClick={settings}
          data-cy="project-settings"
        >
          <AccountCircle /> {t('menu.itemOne')}
        </MenuItem>
        {(userAccount?.role === 'owner' || userAccount?.role === 'admin') && (
          <MenuItem placeholder={undefined} onClick={accountSettings}>
            <SettingsIcon /> {t('menu.itemTwo')}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
