import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  DialogTitle,
  DialogContent,
  Dialog,
} from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import '../css/VideoInfoDialog.css'
import { lstnBasictheme } from '../theme/lstn-theme-basic'

interface IProps {
  openDialog: boolean
  onClose: (avoidReceive: boolean) => void
}

type measureType = 'noise' | 'mic' | 'talk' | 'accent'

export default function VideoInfoDialog({ openDialog, onClose }: IProps) {
  const [noiseFree, setNoiseFree] = useState(2)
  const [closeToMic, setCloseToMic] = useState(2)
  const [overTalk, setOverTalk] = useState(2)
  const [accent, setAccent] = useState(2)
  const [checkActive, setCheckActive] = useState(false)
  const { t } = useTranslation()

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckActive(e.target.checked)
  }

  const handleToggle = (measure: measureType, value: number) => () => {
    switch (measure) {
      case 'noise':
        setNoiseFree(value)
        break

      case 'mic':
        setCloseToMic(value)
        break

      case 'talk':
        setOverTalk(value)
        break

      case 'accent':
        setAccent(value)
        break

      default:
        return
    }
  }

  const handleClose = () => {
    onClose(checkActive)
  }

  const handleCloseDialog = (reason: string) => {
    if (reason !== 'backdropClick') {
      onClose(checkActive)
    }
  }

  return (
    <MuiThemeProvider theme={lstnBasictheme}>
      <Dialog
        disableEscapeKeyDown={true}
        open={openDialog}
        onClose={(event, reason) => handleCloseDialog(reason)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className="dialog-title" id="form-dialog-title">
          <div> {t('videoInfo.popupTitle')}</div>
          <div className="dialog-title-bottom">
            {t('videoInfo.popupSubTitle')}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="dialog-content">
            <div className="dialog-content-top">
              {t('videoInfo.checkBox1Title')}
            </div>
            <div className="btn-group">
              <button
                className={
                  noiseFree === 0
                    ? 'no-btn-active'
                    : noiseFree === 2
                      ? 'initial-state-btn'
                      : 'no-btn-inactive'
                }
                onClick={handleToggle('noise', 0)}
              >
                No
              </button>
              <button
                className={
                  noiseFree === 1
                    ? 'yes-btn-active'
                    : noiseFree === 2
                      ? 'initial-state-btn'
                      : 'yes-btn-inactive'
                }
                onClick={handleToggle('noise', 1)}
              >
                Yes
              </button>
            </div>
          </div>
          {noiseFree === 0 ? (
            <div className="dialog-content-subtitles">
              {t('videoInfo.checkBox1SubTitle')}
            </div>
          ) : (
            ''
          )}
          <div className="dialog-content">
            <div className="dialog-content-top">
              {t('videoInfo.checkBox2Title')}
            </div>
            <div className="btn-group">
              <button
                className={
                  closeToMic === 0
                    ? 'no-btn-active'
                    : closeToMic === 2
                      ? 'initial-state-btn'
                      : 'no-btn-inactive'
                }
                onClick={handleToggle('mic', 0)}
              >
                No
              </button>
              <button
                className={
                  closeToMic === 1
                    ? 'yes-btn-active'
                    : closeToMic === 2
                      ? 'initial-state-btn'
                      : 'yes-btn-inactive'
                }
                onClick={handleToggle('mic', 1)}
              >
                Yes
              </button>
            </div>
          </div>
          {!closeToMic ? (
            <div className="dialog-content-subtitles">
              {t('videoInfo.checkBox2SubTitle')}
            </div>
          ) : (
            ''
          )}
          <div className="dialog-content">
            <div className="dialog-content-top">
              {t('videoInfo.checkBox3Title')}
            </div>
            <div className="btn-group">
              <button
                className={
                  overTalk === 0
                    ? 'no-btn-active'
                    : overTalk === 2
                      ? 'initial-state-btn'
                      : 'no-btn-inactive'
                }
                onClick={handleToggle('talk', 0)}
              >
                No
              </button>
              <button
                className={
                  overTalk === 1
                    ? 'yes-btn-active'
                    : overTalk === 2
                      ? 'initial-state-btn'
                      : 'yes-btn-inactive'
                }
                onClick={handleToggle('talk', 1)}
              >
                Yes
              </button>
            </div>
          </div>
          {!overTalk ? (
            <div className="dialog-content-subtitles">
              {t('videoInfo.checkBox3SubTitle')}
            </div>
          ) : (
            ''
          )}
          <div className="dialog-content">
            <div className="dialog-content-top">
              {t('videoInfo.checkBox4Title')}
            </div>
            <div className="btn-group">
              <button
                className={
                  accent === 0
                    ? 'no-btn-active'
                    : accent === 2
                      ? 'initial-state-btn'
                      : 'no-btn-inactive'
                }
                onClick={handleToggle('accent', 0)}
              >
                No
              </button>
              <button
                className={
                  accent === 1
                    ? 'yes-btn-active'
                    : accent === 2
                      ? 'initial-state-btn'
                      : 'yes-btn-inactive'
                }
                onClick={handleToggle('accent', 1)}
              >
                {' '}
                Yes{' '}
              </button>
            </div>
          </div>
          {!accent ? (
            <div className="dialog-content-subtitles">
              {t('videoInfo.checkBox4SubTitle')}
            </div>
          ) : (
            ''
          )}
        </DialogContent>
        <div className="proceed-btn-container">
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            fullWidth
          >
            {noiseFree === 0 ||
            closeToMic === 0 ||
            overTalk === 0 ||
            accent === 0
              ? t('videoInfo.proceedAnyway')
              : t('videoInfo.proceed')}
          </Button>
        </div>

        <div className="dialog-checkbox-container">
          <Checkbox
            color="primary"
            defaultChecked={checkActive}
            onChange={handleCheckBox}
          />
          {t('videoInfo.checkBox')}
        </div>
      </Dialog>
    </MuiThemeProvider>
  )
}
