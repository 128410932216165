import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
// import { connect } from "react-redux"
// import { StaticContext } from 'react-routimpoimport { Navigate,useLocation } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
// import { Dispatch } from 'redux'
import firebase from 'firebase/compat/app'
// import { fetchUserStatus } from "../store/actions/userStatus"
// import { IRootState } from "../store/reducers/rootReducer"
// import { IUserStatusState } from "../store/reducers/userStatus"
import SplashScreen from '../components/SplashScreen'
import { functions } from '../firebaseApp'
import { planData } from '../utils/plans'
import getProvider from '../utils/provider'
import { fetchUserById, helperUserData } from '../store/features/userSlice'
import { useAppDispatch, useAppSelector } from '../store/store'
import * as QueryString from 'query-string'
import { customEvent, getCountryCode } from '../utils/customHooks'
// import { fetchAccount } from '../store/features/userSlice'
import { fetchAccounts, fetchAllAccounts } from '../store/features/accountSlice'
import {
  fetchAllAccountsProjects,
  fetchAllProjects,
  fetchRoles,
  setCurrentProject,
} from '../store/features/projectSlice'
import { IUserStatus } from '../interfaces/IUser'
import UpgradeModal from '../components/UpgradeModal'
import { setShowUpgradeModal } from '../store/features/userSlice'

interface IProps {
  user?: firebase.User
  userStatus?: IUserStatus | undefined
  currentUser?: firebase.User | null
  children?: React.ReactNode
}
// tslint:disable: ordered-imports
const _PrivateRoute = ({ currentUser, children }: IProps): React.ReactNode => {
  const {
    showUpgradeModal,
    user,
    userStatus,
    userData,
    organizations,
    fetchStatusFailure,
    fetchStatusSuccess,
  } = useAppSelector((store) => store.user)

  const { loading, currentProject, projects } = useAppSelector(
    (store) => store.project,
  )
  const [loadings, setLoading] = useState<boolean>(true)

  // Get the organization ID
  const accountId =
    userData && userData.accounts && Object.keys(userData.accounts)[0]
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const countryCode = getCountryCode()
  const queries = QueryString.parse(location?.search)
  const newUser = localStorage.getItem('ws-init-user')

  //@ts-ignore
  const updatedUser = {
    ...user,
    country_code: countryCode,
    preferredLanguage: queries.lng || 'en',
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const upgradeParam = urlParams.get('nav')
    if (upgradeParam === 'upgrade') {
      dispatch(setShowUpgradeModal(true))
    }
  }, [])

  const handleUpgrade = (): void => {
    dispatch(setShowUpgradeModal(false))
  }

  useEffect(() => {
    if (projects.length > 0 && Object.keys(currentProject).length === 0) {
      projects.forEach((project) => {
        if (project.status === 'active') {
          dispatch(setCurrentProject(project))
        }
      })
    }
  }, [projects])

  useEffect(() => {
    if (loading === 'idle') {
      return
    } else if (
      Object.keys(currentProject).length === 0 &&
      projects.length === 0 &&
      loading === 'succeeded'
    ) {
      navigate('/create-account')
    } else if (projects.length > 0 && location.pathname === '/create-account') {
      navigate('/social-replies')
    }
  }, [currentProject, loading])

  useEffect(() => {
    const createUser = async (): Promise<void> => {
      if (
        user &&
        user.uid &&
        Object.keys(userData).length === 0 &&
        newUser === 'true'
      ) {
        console.log('createUserWithSubscription')
        await createUserWithSubscription()
      }
    }
    createUser()
  }, [user, newUser])

  useEffect(() => {
    if (firebase) {
      if (user) {
        setLoading(false)
      }
      setTimeout(() => {
        if (!firebase.auth().currentUser?.uid) {
          window.postMessage({ logout: true }, '*')
          console.log('firebase auth chceking')
          navigate('/')
        }
      }, 2000)
    }
  }, [navigate, user])

  useEffect(() => {
    if (Object.keys(organizations).length === 0 && accountId) {
      const accIds = Object.keys(userData.accounts)
      fetchAccounts({ dispatch, id: accountId })
      dispatch(fetchAllAccounts())
      fetchAllAccountsProjects(accIds, dispatch)
      fetchAllProjects(accountId, dispatch)
      fetchRoles(dispatch)
    }
  }, [dispatch, accountId, userData, organizations])

  useEffect(() => {
    if (
      (userStatus == undefined ||
        (userStatus && Object.keys(organizations).length === 0)) &&
      user &&
      user.uid &&
      newUser !== 'true' &&
      !fetchStatusFailure &&
      !fetchStatusSuccess
    ) {
      // fetchStatus(user.uid)
      dispatch(fetchUserById(user.uid))
      helperUserData({ dispatch })
    }

    if (userStatus == undefined && fetchStatusSuccess && user) {
      debounceFetchStatus(user.uid)
    }
  }, [userStatus, fetchStatusSuccess, user, organizations])

  const createUserWithSubscription = async () => {
    if (user) {
      setLoading(true)
      // let gaClientId
      const providerLabel =
        localStorage.getItem('ws-auth-provider') ||
        (user.providerData[0] !== null
          ? getProvider(user.providerData[0].providerId)
          : '')
      ReactGA.gtag('set', 'user_properties', {
        userId: user.uid,
      })

      customEvent('sign_up', {
        category: 'auth',
        authAttribute: providerLabel,
        user_userId: user.uid,
        accountId: accountId,
      })

      const selectedPlan =
        localStorage.getItem('lstn-plan') !== null
          ? // @ts-ignore
            planData[localStorage.getItem('lstn-plan')]
          : ''
      const createdAt =
        currentUser && currentUser.metadata
          ? currentUser.metadata.creationTime
          : ''
      const inviteId = localStorage.getItem('inviteId')
      const validityCode = localStorage.getItem('inviteValidityCode')
      const subscription = functions.httpsCallable('addSubscription')
      const preferredLanguage =
        localStorage.getItem('wellshared-display-lng') || 'en'
      try {
        const authUser = firebase.auth().currentUser
        await subscription({
          ...updatedUser,
          displayName: authUser?.displayName,
          selectedPlan: selectedPlan ? selectedPlan : '',
          dailyNotificationEmail: false,
          weeklyNotificationEmail: false,
          transcriptionLanguage: 'it',
          preferredLanguage,
          createdAt,
          inviteId,
          validityCode: validityCode ? parseInt(validityCode, 10) : null,
        })
        localStorage.removeItem('ws-init-user')
        localStorage.removeItem('lstn-plan')
        if (inviteId && validityCode) {
          localStorage.removeItem('inviteId')
          localStorage.removeItem('inviteValidityCode')
        }
        console.log('&&&& called addSubscription')
        // fetchStatus(user.uid)
        dispatch(fetchUserById(user.uid))
        console.log('&&&&')
        setLoading(false)
      } catch (err) {
        console.error('faced error while calling addSubscription', err)
        setLoading(false)
      }
    }
  }

  const debounceFetchStatus = debounce(fetchUserById, 1500)

  if (showUpgradeModal) {
    return (
      <UpgradeModal
        UpgradeOpen={showUpgradeModal}
        handleUpgrade={handleUpgrade}
      />
    )
  }

  if (loadings) {
    return <SplashScreen />
  }

  if (user && user.uid) {
    // if (Object.keys(organizations).length === 0) {
    //   return <SplashScreen />
    // }
    return children
  }
  return <SplashScreen />
}

export const PrivateRoute = _PrivateRoute
