import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from '@material-tailwind/react'
import { WordpressIcon } from '../../SvgIcons'
import { storage } from '../../../firebaseApp'
import {
  WORDPRESS_PLUGIN_FILE,
  WORDPRESS_PLUGIN_VIDEO,
} from '../../../utils/constant'
import { useTranslation } from 'react-i18next'

interface WordpressDialogProps {
  open: boolean
  handleClose: () => void
}

const WordpressDialog: React.FC<WordpressDialogProps> = ({
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [pluginUrl, setPluginUrl] = useState<string>('')
  const [pluginVideoUrl, setPluginVideoUrl] = useState<string>('')
  useEffect(() => {
    const getDownloadUrl = async (
      filePath: string,
      type: string,
    ): Promise<void> => {
      try {
        const storageRef = storage.ref(filePath)
        const url = await storageRef.getDownloadURL()
        if (type === 'zip') setPluginUrl(url)
        if (type === 'mp4') setPluginVideoUrl(url)
      } catch (error) {
        console.error('Error getting download URL:', error)
        throw error
      }
    }
    getDownloadUrl(WORDPRESS_PLUGIN_FILE, 'zip')
    getDownloadUrl(WORDPRESS_PLUGIN_VIDEO, 'mp4')
  }, [])

  return (
    <Dialog
      open={open}
      handler={handleClose}
      size={'md'}
      placeholder={undefined}
    >
      <DialogHeader placeholder={'Header'} className="mt-4 mx-2">
        <div className="mr-3">
          <WordpressIcon width="1.5em" height="1.5em" />
        </div>
        {/* Connect Wordpress.org */}
        {t && t('extensionCard.wordpress.dialog.title')}
      </DialogHeader>
      <Typography
        className="text-wellBlueGray-500 text-base font-normal mx-8"
        placeholder={undefined}
      >
        {/* Download the WellShared Plugin and install it in your wordpress.org
        website. Choose this option if you have a self-hosted Wordpress site. */}
        {t && t('extensionCard.wordpress.dialog.description')}
      </Typography>
      <DialogBody placeholder={undefined}>
        <Typography
          className="text-wellBlueGray-900 text-base font-semibold mx-4"
          placeholder={undefined}
        >
          {/* Step 1. Install the WellShared Plugin */}
          {t && t('extensionCard.wordpress.dialog.step1')}
        </Typography>
        <a href={pluginUrl} download="WellShared-wordpress-plugin.zip">
          <Button
            placeholder={'download'}
            variant="outlined"
            size="sm"
            // onClick={() => console.log('Download Plugin')}
            className="text-lstnGreen-500 border-lstnGreen-500 mx-4 my-3"
          >
            {/* Download Plugin */}
            {t && t('extensionCard.wordpress.dialog.download')}
          </Button>
        </a>
        <Typography
          className="text-wellBlueGray-900 text-base font-semibold mx-4"
          placeholder={undefined}
        >
          {/* Step 2. Activate the WellShared Plugin (Watch The Video) */}
          {t && t('extensionCard.wordpress.dialog.step2')}
        </Typography>
        <video
          src={pluginVideoUrl}
          controls={true}
          width={700}
          height={300}
          className="mt-4 mx-4"
        />
      </DialogBody>
    </Dialog>
  )
}

export default WordpressDialog
