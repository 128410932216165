import { database } from '../firebaseApp'
import { IupdateUserData } from '../interfaces'

export const updateUserData = async (
  id: string,
  data: IupdateUserData,
): Promise<void> => {
  try {
    const userRef = database.collection(`users`).doc(id)
    await userRef.update({ ...data })
  } catch (error) {
    console.error('error', error)
  }
}
