import React, { useCallback } from 'react'
// import ReactGA from "react-ga"
// import { connect } from "react-redux"
// import { FirebaseReducer } from "react-redux-firebase"
// import { withFirebase } from "react-redux-firebase"
import { useNavigate } from 'react-router-dom'
import { TransProps, useTranslation } from 'react-i18next'
// import { compose } from "redux"
// import { IRootState } from "../store/reducers/rootReducer"
// import { IUserStatusState } from "../store/reducers/userStatus"
// import SettingsIcon from '@material-ui/icons/Settings'
import { useAppSelector } from '../store/store'
import { IUserStatus } from '../interfaces/IUser'
import firebase from 'firebase/compat/app'
// import { history } from "../Routing/history"
interface IStateProps {
  firebase: typeof firebase
  logout: () => void
  fetchStatus: (id: string) => void
  userStatus: IUserStatus | undefined
  isEnterprise?: boolean
}
//@ts-ignore
const _Auth: React.FC<IStateProps & TransProps> = ({ isEnterprise }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const settings = useCallback(() => {
  //   history.push('/settings')
  // }, [history])
  const { userStatus, user } = useAppSelector((store) => store.user)

  const upgrade = useCallback(() => {
    navigate('/payment-plan')

    // ReactGA.event({
    //   action: "upgrade button pressed",
    //   category: "payment",
    // })
  }, [navigate])

  // Manage Billing bUtton Logic
  const getButton = useCallback(() => {
    if (userStatus && userStatus.status === 'in_trial' && !isEnterprise) {
      return (
        <button className="org-btn lstn-btn" onClick={upgrade}>
          {t && t('auth.daysRemaining', { days: userStatus.daysRemaining })}
        </button>
      )
    } else return null
  }, [userStatus, isEnterprise, t])

  const displayName =
    Object.keys(user).length !== 0 && user.uid ? user.displayName : ''

  return (
    <div className="user">
      {(() => {
        if (Object.keys(user).length !== 0 && user.uid) {
          return (
            <div
              className={
                userStatus && userStatus.status === 'in_trial'
                  ? 'header-container-trial'
                  : 'header-container'
              }
            >
              <div className="header-display-name">{displayName}</div>
              {getButton()}
              {/* <button data-cy="project-settings" onClick={settings}>
                <SettingsIcon /> Account Settings
              </button> */}
            </div>
          )
        } else {
          return <a href="/login">{t && t('auth.logIn')}</a>
        }
      })()}
    </div>
  )

  // private logout = () => {
  //   firebase
  //     .auth()
  //     .signOut()
  //     .then(() => {
  //       this.props.history.push("/")
  //     })
  //     .catch(error => {
  //       // An error happened.
  //     })

  //   /* Clearing gist state */
  //   if (localStorage) {
  //     localStorage.removeItem("chatDetails")
  //     localStorage.removeItem("gist-state")
  //   }

  //   if (document.cookie) {
  //     document.cookie = "gist_id_cg3hsghp" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
  //     document.cookie = "gist_identified_cg3hsghp" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
  //   }

  //   // this.props.history.push("/")
  //   this.props.firebase.logout()

  //   ReactGA.event({
  //     action: "log out button pressed",
  //     category: "authentication",
  //   })
  // }

  // Manage Billing Button Logic

  // private portal = () => {
  //   this.props.history.push("/user-portal")

  //   ReactGA.event({
  //     action: "portal button pressed",
  //     category: "payment",
  //   })
  // }
}

/* tslint-disable */
// const Auth = compose<React.ElementType>(
//   withFirebase,
//   connect(
//     // Map redux state to component props
//     ({ firebase: { auth, profile }, userStatus }: IRootState) => ({
//       auth,
//       profile,
//       userStatus,
//     }),
//   ),
//   withRouter,
//   withTranslation(),
// )(_Auth)

export default _Auth
