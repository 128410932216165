export function slateToMarkdown(value: any) {
  const markdownLines: string[] = []

  value.forEach((node: any) => {
    if (node.type === 'paragraph') {
      const paragraph = node.children.map(formatText).join('')
      markdownLines.push(paragraph)
    } else if (node.type === 'block-quote') {
      const quote = `> ${node.children[0].text}`
      markdownLines.push(quote)
    } else if (node.type === 'heading-one') {
      const paragraph = node.children.map(formatText).join('')

      const heading1 = `# ${paragraph}`
      markdownLines.push(heading1)
    } else if (node.type === 'heading-two') {
      const paragraph = node.children.map(formatText).join('')
      const heading2 = `## ${paragraph}`
      markdownLines.push(heading2)
    } else if (node.type === 'heading-three') {
      const paragraph = node.children.map(formatText).join('')
      const heading3 = `### ${paragraph}`
      markdownLines.push(heading3)
    } else if (node.type === 'heading-four') {
      const paragraph = node.children.map(formatText).join('')
      const heading4 = `#### ${paragraph}`
      markdownLines.push(heading4)
    } else if (
      ['numbered-list', 'bulleted-list', 'align-left'].includes(node.type)
    ) {
      let list = ''
      node.children.forEach((listItem: any, i: number) => {
        let tex = ''
        if (listItem?.children) {
          listItem.children.forEach((ch: any) => {
            tex = tex + formatText(ch)
          })
        } else {
          tex = tex + formatText(listItem)
        }
        node.type === 'numbered-list'
          ? (list =
              list +
              `${i + 1}.${tex}${
                tex?.length > 1 && node.children.length - 1 !== i ? '\n' : ''
              }`)
          : node.type === 'bulleted-list'
            ? (list =
                list +
                `* ${tex}${
                  tex?.length > 1 && node.children.length - 1 !== i ? '\n' : ''
                }`)
            : (list =
                list +
                `${tex}${
                  tex?.length > 1 && node.children.length - 1 !== i ? '\n' : ''
                }`)
      })

      markdownLines.push(list)
    } else if (node.type === 'align-left') {
      const content = node.children.map(formatText).join('\n')
      markdownLines.push(`> ${content}`)
    } else {
      console.warn(`Unsupported Slate node type: ${node.type}`)
    }
  })

  return markdownLines.join('\n\n')
}

function formatText(child: any) {
  if (child.bold && child.italic && child.code) {
    return '**_```' + child.text + '```_**'
  } else if (child.bold && child.code) {
    return '**```' + child.text + '```**'
  } else if (child.bold && child.italic && child.underline) {
    return `<u>**_${child.text}_**</u>`
  } else if (child.bold && child.underline) {
    return `<u>**${child.text}**</u>`
  } else if (child.bold && child.italic) {
    return `**_${child.text}_**`
  } else if (child.bold) {
    return `**${child.text}**`
  } else if (child.italic && child.underline) {
    return '<u>_' + `${child.text}` + '_</u>'
  } else if (child.italic && child.code) {
    return '_```' + `${child.text}` + '```_'
  } else if (child.italic) {
    return '_' + `${child.text}` + '_'
  } else if (child.code) {
    const escapedCode = child.text.replace(/\\/g, '\\\\').replace(/`/g, '\\`')
    return '```' + escapedCode + '```'
  } else if (child.underline) {
    return '<u>' + child.text + '</u>'
  } else if (child.type === 'link') {
    const linkText = child.children.map(formatText).join('')
    return `[${linkText}](${child.href})`
  } else {
    return child.text
  }
}
