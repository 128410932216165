import firebase from 'firebase/compat/app'

export async function getCustomTokenFromServer(): Promise<string> {
  try {
    const token = (await firebase
      .auth()
      .currentUser?.getIdToken(true)) as string

    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_API}/createCustomToken`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )

    if (response.status === 200) {
      const res = await response.json()
      return res.data.customToken
    } else {
      return ''
    }
  } catch (err) {
    console.log('Error in getCustomTokenFromServer', err)
    return ''
  }
}
