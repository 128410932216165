import { useSelected, useFocused, useSlate } from 'slate-react'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUnlink, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { IconButton } from '@material-tailwind/react'

import './link.css'
import { Transforms } from 'slate'
import React from 'react'

import redirect from '../../../icons/redirect.svg'
import deleteContent from '../../../icons/delete-content.svg'

const Link = ({ attributes, element, children }: any) => {
  const editor = useSlate()
  const selected = useSelected()
  const focused = useFocused()

  const removeLink = (editor: any, opts = {}) => {
    Transforms.unwrapNodes(editor, {
      ...opts,
      match: (n) => n.type === 'link',
    })
  }

  return (
    <div className="element-link">
      <a {...attributes} href={element.href}>
        {children}
      </a>
      {selected && focused && (
        <div className="popup" contentEditable={false}>
          <a href={element.href} rel="noreferrer" target="_blank">
            <IconButton className="mr-1" placeholder={undefined}>
              <img src={redirect} alt="copyContent" className="w-5 h-5" />
            </IconButton>
            {element.href}
          </a>
          <button onClick={() => removeLink(editor)}>
            <IconButton className="mr-1" placeholder={undefined}>
              <img src={deleteContent} alt="copyContent" className="w-5 h-5" />
            </IconButton>
          </button>
        </div>
      )}
    </div>
  )
}

export default Link
