import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IParagraph, ITranscript } from '../../interfaces'
import { database } from '../../firebaseApp'

const initialState: ITranscript = {
  loading: false,
}

// Create a Redux slice
const paragraphsSlice = createSlice({
  name: 'paragraphs',
  initialState,
  reducers: {
    readParagraph: (
      state,
      action: PayloadAction<{ paragraphs: IParagraph[]; transcriptId: string }>,
    ) => {
      return {
        ...state,
        paragraphs: action.payload.paragraphs,
        paragraphsForTranscript: action.payload.transcriptId,
      }
    },
    selectTranscript: (
      state,
      action: PayloadAction<{ transcript: IParagraph[]; Id: string }>,
    ) => {
      return {
        ...state,
        id: action.payload.Id,
        ...action.payload.transcript,
        paragraphs: [],
      }
    },
    setloading: (state, action) => {
      state.loading = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //@ts-ignore
    resetParagraph: () => {
      // Reset the state to its initial value (empty)
      return {}
    },
  },
})

// Export the action creator
export const { setloading, resetParagraph, readParagraph, selectTranscript } =
  paragraphsSlice.actions

// Export the reducer
export default paragraphsSlice.reducer

//------------- helper editor

export const UpdateSpeakerName = async (speaker: string, Id: string) => {
  const TranscriptQuery = database.collection('transcripts').doc(Id)
  const transcriptDoc = await TranscriptQuery.get()
  const existingData = transcriptDoc.data()
  if (existingData?.speakerNames) {
    const updatedSpeakerNames = {
      ...existingData.speakerNames,
      [speaker]: speaker,
    }
    await TranscriptQuery.update({ speakerNames: updatedSpeakerNames })
  } else {
    const newData = {
      ...existingData,
      speakerNames: { [speaker]: speaker },
    }
    await TranscriptQuery.update(newData)
  }
}

export const removeParagraphSpeaker = async (speaker: number, Id: string) => {
  const TranscriptremoveQuery = database.collection('transcripts').doc(Id)
  const transcriptremoveDoc = await TranscriptremoveQuery.get()
  const existing = transcriptremoveDoc.data()
  if (existing?.speakerNames) {
    delete existing.speakerNames[speaker]
    await TranscriptremoveQuery.update({ speakerNames: existing.speakerNames })
  }
}
