import React, { useCallback, useState } from 'react'
import { Card, Input, Button, CardBody } from '@material-tailwind/react'
import Typography from '../components/Typography/Typography'
import Selector from '../components/SelectInput/Select'
import { database } from '../firebaseApp'
import firebase from 'firebase/compat/app'
import { Dialog, DialogBody, DialogFooter } from '@material-tailwind/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// COMMENTED
// import MultipleSelectChip from '../components/SelectInput/MultipleSelect'
import { useAppDispatch, useAppSelector } from '../store/store'
import CircularProgress from '@material-ui/core/CircularProgress'
import { customEvent } from '../utils/customHooks'
import { fetchAccounts, fetchAllAccounts } from '../store/features/accountSlice'
import { getCookieByKey } from '../utils/getCookieByKey'
import { ErrorMessage, Field, Form, Formik } from 'formik'
// import { error } from 'console'
import * as Yup from 'yup'
import { CheckCircle } from '@material-ui/icons'
import ErrorIcon from '@material-ui/icons/Error'
import parsing from '../icons/Analyzing.svg'
import { parsingWebsite } from '../utils/parsingWebsite'
import Toast from '../components/Toast/Toast'
import { generateKeywords } from '../utils/generateKeywords'

interface IProps {
  user?: firebase.UserInfo
}

interface ICreate {
  name: string
  website: string
}

interface IUserInfo {
  data: {
    accountId: string
    name: string
    website: string
    size: string
    clientId?: string
    // industry: string
    // creatorOnboardingUserRole: string
    // projectName: string
    // description: string[]
  }
  WebsiteCrawlData?: any
}

const CreateAccount: React.FC<IProps> = ({
  // match,
  user,
}) => {
  const [companySize, setCompanySize] = useState<string>('')
  const [role, setRole] = useState<string>('')
  // const [industry, setIndustry] = useState<string>('')
  // const [useCase, setUseCase] = useState<string[]>([])
  // const [industry, setIndustry] = useState<string>('')
  // const [useCase, setUseCase] = useState<string[]>([])
  const [open, setOpen] = React.useState(false)
  const { userData } = useAppSelector((store) => store.user)
  const [loading, setLoading] = useState(false)
  const [crawl, setCrawl] = useState(false)
  // const [userInfo, setUserInfo] = useState<string | string[]>('' || [])
  const [urls, setUrls] = useState<string[]>([])
  const [parsedUrls, setParsedUrls] = useState<string[]>([])
  const [failedParseStatus, setFailedParseStatus] = useState(false)
  const [visibleUrls, setVisibleUrls] = useState(5)
  const [visibleParsedUrls, setVisibleParsedUrls] = useState(5)

  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const navigate = useNavigate()
  // Accessing the inviterId parameter from the URL
  const { inviteId } = useParams()
  // Get the organization ID
  const accountId =
    userData && userData.accounts && Object.keys(userData.accounts)[0]

  const roleOptions = [
    {
      value: 'Marketer',
      label: t && t('createAccount.roleOptions.marketer'),
    },
    {
      value: 'Sales',
      label: t && t('createAccount.roleOptions.sales'),
    },
    {
      value: 'Product',
      label: t && t('createAccount.roleOptions.product'),
    },
    {
      value: 'Executive',
      label: t && t('createAccount.roleOptions.executive'),
    },
    { value: 'Other', label: t && t('createAccount.roleOptions.other') },
  ]

  const handleViewMoreUrls = (): void => {
    setVisibleUrls((prev) => prev + 5)
  }

  const handleViewMoreParsedUrls = (): void => {
    setVisibleParsedUrls((prev) => prev + 5)
  }

  const getSizeInBytes = (obj: any): number => {
    // const jsonStr = JSON.stringify(obj)
    // return Buffer.byteLength(jsonStr, 'utf8')
    const jsonStr = JSON.stringify(obj)
    const encoder = new TextEncoder()
    return encoder.encode(jsonStr).length
  }

  // COMMENTED
  // const industryOptions = [
  //   {
  //     value: 'Software products',
  //     label: t && t('createAccount.industryOptions.softwareProducts'),
  //   },
  //   {
  //     value: 'Physical products',
  //     label: t && t('createAccount.industryOptions.physicalProducts'),
  //   },
  //   {
  //     value: 'Advertising & Marketing',
  //     label: t && t('createAccount.industryOptions.marketing'),
  //   },
  //   {
  //     value: 'Services & Consultancy',
  //     label: t && t('createAccount.industryOptions.servicesConsultancy'),
  //   },
  //   {
  //     value: 'Education',
  //     label: t && t('createAccount.industryOptions.education'),
  //   },
  //   { value: 'Other', label: t && t('createAccount.roleOptions.other') },
  // ]
  // const useCaseOptions = [
  //   {
  //     value: 'Social media replies',
  //     label: t && t('createAccount.useCaseOptions.socialReplies'),
  //   },
  //   {
  //     value: 'Blog / Content creation',
  //     label: t && t('createAccount.useCaseOptions.blogContentCreation'),
  //   },
  //   {
  //     value: 'Directories posting',
  //     label: t && t('createAccount.useCaseOptions.directoriesPosting'),
  //   },
  //   { value: 'Other', label: t && t('createAccount.roleOptions.other') },
  // ]

  const companySizeOptions = [
    { value: 'Myself only', label: t && t('createAccount.myself-only') },
    { value: 'Up to 10', label: 'Up to 10' },
    { value: '11 to 50', label: '11 to 50' },
    { value: '51 to 200', label: '51 to 200' },
    { value: '201 to 500', label: '201 to 500' },
    { value: '501 to 2000', label: '501 to 2000' },
    { value: '2000+', label: '2000+' },
  ]
  const handleCompanyChange = (value: string | undefined): void => {
    if (value) {
      setCompanySize(value)
    }
  }

  // COMMENTED
  // const handleIndustryChange = (value: string | undefined): void => {
  //   if (value) {
  //     setIndustry(value)
  //   }
  // }
  // const handleUseCase = (value: string[]): void => {
  //   if (value) {
  //     setUseCase(value)
  //   }
  // }
  // const handleChange = (event: { target: { value: any } }) => {
  //   const value = event.target.value
  //   setProjectName(value)

  //   // Validate the input here
  //   if (value.length < 3) {
  //     setError('Project name must be at least 3 characters long.')
  //   } else {
  //     setError('')
  //   }
  // }

  const handleRoleChange = (value: string | undefined): void => {
    if (value) {
      setRole(value)
    }
  }

  const initialValues: ICreate = {
    name: '',
    website: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('createAccount.companyNameRequired')),
    website: Yup.string().matches(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+(\.[a-zA-Z]{2,}){1,3}(\/[a-zA-Z0-9#%_\-/]*)?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+(&[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+)*)?$/,
      t('createAccount.validWebsite'),
    ),
  })

  const updateAccounts = async (userInfo: IUserInfo): Promise<void> => {
    const token = await firebase.auth().currentUser?.getIdToken()
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_API}/accounts/updateWithWebsiteCrawl`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userInfo),
      },
    )
    if (response.status === 200) {
      if (user) {
        customEvent('account_created', {
          category: 'accounts',
          accountId: accountId,
          user_userId: user.uid,
          actionSource: 'from-new-signup',
        })
        customEvent('account_onboarding_info_provided', {
          category: 'accounts',
          user_userId: user.uid,
          accountId: accountId,
          actionSource: 'from-new-signup',
        })
      }
      fetchAccounts({ dispatch, id: accountId })
      dispatch(fetchAllAccounts())
      setLoading(false)
    } else {
      setLoading(false)
      console.log(await response.json())
    }
  }

  const handleSubmit = async (values: ICreate): Promise<void> => {
    setLoading(true)
    try {
      const ga4ClientId = getCookieByKey('_ga')
      let clientId
      if (ga4ClientId) {
        const parts = ga4ClientId?.split('.')
        clientId = parts?.slice(2).join('.')
      }

      const payload: IUserInfo = {
        data: {
          accountId: accountId,
          name: values.name,
          website: values?.website || '',
          size: companySize || '',
          clientId,
        },
        WebsiteCrawlData: {},
      }
      setLoading(false)
      if (values.website) {
        setUrls([payload.data.website])
        let uniqueUrls
        setCrawl(true)
        if (payload) {
          const response = await parsingWebsite(payload.data.website)
          if (
            response?.error === 'An error occurred during the crawl process'
          ) {
            payload.WebsiteCrawlData = await response.error
            setFailedParseStatus(true)
            await updateAccounts(payload)
            navigate('/social-replies')
            return
          } else {
            uniqueUrls = await response.uniqueLinks
            // const normalizedUrls = linksArray.map((url: string) =>
            //   url.endsWith('/') ? url.slice(0, -1) : url,
            // )

            // uniqueUrls = [...new Set(filteredUrls)]
            payload.WebsiteCrawlData.userWebsiteData = response
            const MaxSizeOfParseData = 700000
            setUrls(uniqueUrls as string[])
            const numberOfUrls = uniqueUrls.length > 4 ? 5 : uniqueUrls.length
            for (let i = 0; i < numberOfUrls; i++) {
              const url = uniqueUrls[i]
              const sizeOfData = getSizeInBytes(payload)
              if (sizeOfData > MaxSizeOfParseData) {
                setUrls([])
                break
              }
              if (url !== normalizeUrl(payload.data.website)) {
                const response = await parsingWebsite(url as string)
                if (!payload.WebsiteCrawlData.parseUrlData) {
                  payload.WebsiteCrawlData.parseUrlData = {}
                }
                if (
                  response?.error ===
                  'An error occurred during the crawl process'
                ) {
                  payload.WebsiteCrawlData.parseUrlData[url as string] =
                    response.error
                }
                payload.WebsiteCrawlData.parseUrlData[url as string] = response
              }
              setParsedUrls((prevUrls) => [...prevUrls, url] as string[])
              setUrls((prevUrls) => prevUrls.filter((u) => u !== url))
            }
          }
          Toast({
            title: 'URLs parsed successfully!!!',
            text: 'Please hold on for a moment, now we generating keywords for you based on your website data',
            variant: 'success',
          })
          const remainingUrls = uniqueUrls?.slice(5) as string[]
          if (!uniqueUrls?.length) {
            setParsedUrls([payload?.data?.website])
          }

          setUrls([])
          if (remainingUrls.length > 0) {
            payload.WebsiteCrawlData.remainingUrls = remainingUrls
          }
          await updateAccounts(payload)
          const keywordsResponse = await generateKeywords(accountId)
          if (keywordsResponse === 'User website data not found') {
            Toast({
              title: 'Your website Url is not found!!!',
              text: "Please make sure you've entered the correct website URL.",
              variant: 'error',
            })
          } else if (
            keywordsResponse === 'Social media keywords suggested successfully'
          ) {
            Toast({
              title: 'Keywords generated successfully!!!',
              text: "Please check your social media keywords in the 'Social Replies'  keywords section.",
              variant: 'success',
            })
          } else {
            Toast({
              title: 'Keywords generation failed!!!',
              text: 'Please try again later.',
              variant: 'error',
            })
          }
        }

        navigate('/social-replies')
      } else {
        await updateAccounts(payload)
      }
      navigate('/social-replies')
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleSubmitmembers = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    // Convert FormData to a plain object
    const formObject: Record<string, string> = {}
    formData.forEach((value, key) => {
      formObject[key] = value.toString()
    })
    try {
      const organizationRef = database.collection('organizations').doc(inviteId)
      const organizationFetch = await organizationRef.get()
      const organizationData = organizationFetch.data()

      if (organizationData && organizationData.users) {
        if (user) {
          const newUser = {
            [`users.${user.uid}`]: {
              status: 'active',
              role: role,
            },
          }
          await organizationRef
            .update(newUser)
            .then(() => {
              console.log('New user added successfully')
              // history.push('/social-replies')
            })
            .catch((error) => {
              console.error('Error adding new user: ', error)
            })
          Object.keys(organizationData.users).forEach((userId) => {
            const organizationUser = organizationData.users[userId]
            // Check if the role is "owner"
            if (organizationUser.role === 'owner') {
              // console.log('User with role "owner" found. User ID:', userId);
              const userDataQuery = database.collection('users').doc(userId)
              if (userData && userData.organizations) {
                const newOrganization = {
                  [`organizations.${user.uid}`]: {
                    status: 'active',
                    role: role,
                  },
                }
                userDataQuery
                  .update(newOrganization)
                  .then(() => {
                    console.log('New organization added successfully')
                    navigate('/social-replies')
                  })
                  .catch((error) => {
                    console.error('Error adding new organization: ', error)
                  })
              }
            }
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleOpen = (): void => setOpen(!open)
  const handleSkip = (): void => {
    if (user) {
      customEvent('account_onboarding_info_skipped', {
        category: 'accounts',
        user_userId: user.uid,
        accountId: accountId,
      })
    }
    navigate('/social-replies')
  }

  const renderStatusIcon = useCallback((status: string) => {
    switch (status) {
      case 'DONE':
        return <CheckCircle className="text-lstnGreen-500 iconSize20" />
      case 'FAILED':
        return <ErrorIcon className="text-red-500 iconSize20" />
      default:
        return (
          <div className="w-5">
            <img src={parsing} className="w-5 object-cover" alt="img" />
          </div>
        )
    }
  }, [])

  const renderStatus = useCallback((status: string) => {
    switch (status) {
      case 'DONE':
        return 'Parsed URLs'
      case 'FAILED':
        return 'Failed to parse'
      case 'PARSING':
        return 'Parsing URL'
      default:
        return '--'
    }
  }, [])

  const normalizeUrl = (url: string): string => {
    // Add "https://" if missing
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`
    }
    url = url.replace(/^https?:\/\/(www\.)?/i, 'https://')
    return url
  }

  return (
    <div
      className={`h-screen flex justify-center ${
        crawl && visibleUrls + visibleParsedUrls > 21 ? 'mt-20' : 'items-center'
      }`}
    >
      <Card
        color="transparent"
        shadow={false}
        placeholder={undefined}
        className={`${crawl ? null : 'w-104'}`}
      >
        <div className="w-full px-6">
          <Typography
            variant="h4"
            color="blue-gray"
            className="text-2xl font-semibold text-center text-blue-gray-900"
          >
            {/* Before We Dive in, Help Us in <br /> Understanding You Better */}
            {crawl ? 'Analyzing your website' : t && t('createAccount.title')}
          </Typography>
          {crawl ? (
            <Typography
              variant="h6"
              color="blue-gray"
              className="text-base font-medium text-center text-blue-gray-900 mt-2"
            >
              please wait, this will help us understand your product better
            </Typography>
          ) : null}
          {!inviteId && !crawl ? (
            <Formik<ICreate>
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="mt-4 mb-2 w-full">
                  <div className="mb-1 flex flex-col gap-3">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="text-sm font-medium text-blue-gray-900"
                    >
                      {t('createAccount.companyName')}
                    </Typography>
                    <Field
                      as={Input}
                      size="md"
                      type="text"
                      placeholder={t('createAccount.companyNamePlaceholder')}
                      name="name"
                      className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      crossOrigin={undefined}
                      required
                      data-cy="data-account-company-name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm font-normal"
                    />

                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="text-sm font-medium text-blue-gray-900"
                    >
                      {t('createAccount.companyWebsiteURL')}
                    </Typography>
                    <Field
                      as={Input}
                      size="md"
                      type="text"
                      placeholder={t(
                        'createAccount.companyWebsiteURLPlaceholder',
                      )}
                      name="website"
                      className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      crossOrigin={undefined}
                      data-cy="data-account-company-website-url"
                    />
                    <ErrorMessage
                      name="website"
                      component="div"
                      className="text-red-500 text-sm font-normal"
                    />

                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="text-sm font-medium text-blue-gray-900"
                    >
                      {t('createAccount.companySize')}
                    </Typography>

                    <div data-cy="data-account-company-size">
                      <Selector
                        selectClassName="border border-blue-gray-100 rounded-lg"
                        className="border-none relative text-xs  font-bold w-auto text-blue-gray-500  rounded-lg "
                        options={companySizeOptions}
                        selectedValue={companySize}
                        onChange={handleCompanyChange}
                        children={undefined}
                        name="companySize"
                        placeholder={
                          t && t('createAccount.companySizePlaceholder')
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="companySize"
                      component="div"
                      className="text-red-500 text-sm font-normal"
                    />
                  </div>
                  <Button
                    type="submit"
                    className="mt-6 text-sm flex items-center justify-center font-bold bg-well_primary-500 uppercase shadow-sm"
                    size="md"
                    fullWidth
                    placeholder={undefined}
                    disabled={isSubmitting}
                    data-cy="data-account-continue"
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={18}
                        color="inherit"
                        className="mr-2"
                      />
                    ) : null}
                    {isSubmitting
                      ? t('createAccount.creatingAccount')
                      : t('createAccount.continue')}
                  </Button>
                </Form>
              )}
            </Formik>
          ) : crawl ? (
            <Card
              className="min-w-min w-106 p-5 my-8"
              placeholder={'WebsiteCrawl'}
            >
              <CardBody placeholder={'body'}>
                <Typography
                  color="blue-gray"
                  className="text-base font-semibold text-blue-gray-900 mb-2"
                >
                  {renderStatus('PARSING')}
                </Typography>
                {urls && (
                  <div>
                    {urls.slice(0, visibleUrls).map((url) => (
                      <div key={url} className="flex items-center gap-2">
                        <div>{renderStatusIcon('PARSING')}</div>
                        <Typography
                          color="blue-gray"
                          className="text-base font-normal text-blue-gray-900 mb-1"
                        >
                          {normalizeUrl(url)}
                        </Typography>
                      </div>
                    ))}
                    {urls.length > visibleUrls && (
                      <button
                        onClick={handleViewMoreUrls}
                        className="text-base text-blue-gray-900 font-normal hover:underline mt-2"
                      >
                        ... and 5 more pages
                      </button>
                    )}
                  </div>
                )}
                <Typography
                  color="blue-gray"
                  className="text-base font-semibold text-blue-gray-900 mb-2 mt-6"
                >
                  {renderStatus('DONE')}
                </Typography>
                {parsedUrls && (
                  <div>
                    {parsedUrls.slice(0, visibleParsedUrls).map((url) => (
                      <div key={url} className="flex items-center gap-2">
                        <div>
                          {failedParseStatus
                            ? renderStatusIcon('FAILED')
                            : renderStatusIcon('DONE')}
                        </div>
                        <Typography
                          color="blue-gray"
                          className="text-base font-normal text-blue-gray-900 mb-1"
                        >
                          {normalizeUrl(url)}
                        </Typography>
                      </div>
                    ))}
                    {parsedUrls.length > visibleParsedUrls && (
                      <button
                        onClick={handleViewMoreParsedUrls}
                        className="text-base text-blue-gray-900 font-normal hover:underline mt-2"
                      >
                        ... and 5 more pages
                      </button>
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
          ) : (
            <form className="mt-4 mb-2 w-full" onSubmit={handleSubmitmembers}>
              <div className="mb-1 flex flex-col gap-3">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="text-sm font-medium text-blue-gray-900"
                >
                  {t && t('createAccount.role')}
                </Typography>
                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg"
                  className="border-none relative text-xs  font-bold w-auto text-blue-gray-500  rounded-lg "
                  options={roleOptions}
                  selectedValue={role}
                  onChange={handleRoleChange}
                  children={undefined}
                  name="companySize"
                />
                {/* <div className={role === 'Other' ? 'block mt-2' : 'hidden'}>
                  <Input
                    size="md"
                    type="text"
                    placeholder="Other..."
                    name="otherRole"
                    className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                    labelProps={{
                      className: 'hidden',
                    }}
                    crossOrigin={undefined}
                  />
                </div> */}
              </div>

              <Button
                type="submit"
                className="mt-6 text-sm font-bold bg-well_primary-500 uppercase shadow-sm"
                size="lg"
                fullWidth
                placeholder={undefined}
                loading={loading}
                data-cy="data-account-continue"
              >
                {t && t('createAccount.continue')}
              </Button>
              <div className="text-center mt-6" data-cy="data-account-skip">
                <Button
                  onClick={handleOpen}
                  variant="text"
                  className="text-xs font-bold hover:bg-transparent uppercase"
                  placeholder={undefined}
                  disabled={loading}
                >
                  {t && t('createAccount.skip')}
                </Button>
                <Dialog
                  open={open}
                  size="xs"
                  handler={handleOpen}
                  placeholder={undefined}
                >
                  <DialogBody placeholder={undefined}>
                    {t && t('createAccount.dialogContent')}
                  </DialogBody>
                  <DialogFooter placeholder={undefined}>
                    <Button
                      variant="text"
                      onClick={handleOpen}
                      className="mr-1 text-xs font-bold"
                      placeholder={undefined}
                      data-cy="data-account-skip-cancel"
                    >
                      <span>{t && t('startTourDialog.cancel')}</span>
                    </Button>
                    <Button
                      variant="text"
                      className="bg-well_primary-500 text-xs font-bold text-white"
                      onClick={handleSkip}
                      placeholder={undefined}
                      data-cy="data-account-skip-confirm"
                    >
                      <span>{t && t('createAccount.confirm')}</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              </div>
            </form>
          )}
        </div>
      </Card>
    </div>
  )
}

export default CreateAccount
