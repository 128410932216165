import { createSlice } from '@reduxjs/toolkit'
import { IFolders, ITranscript, ITranscriptRow } from '../../../interfaces'
import {
  checkDuplicates,
  filterTranscriptData,
  parseToFoldersList,
  parseToTranscriptList,
} from '../../../utils/parseData'
import {
  fetchAllTranscript,
  fetchTranscriptFolder,
  fetchallFolders,
  fetchallSharedFolders,
  fetchallSharedTranscripts,
} from './helper'

const transcriptsSlice = createSlice({
  name: 'transcripts',
  initialState: {
    allTranscripts: [] as ITranscript[],
    allSharedTranscripts: [] as ITranscript[],
    allFolders: [] as IFolders[],
    allSharedFolders: [] as IFolders[],
    folderData: {} as IFolders | undefined,
    folderDataCheck: {} as IFolders | undefined,
    transcriptList: [] as ITranscript[],
    transcriptListShareUserId: [] as (ITranscript | IFolders)[],
    transcriptListShareUserIdAlone: [] as ITranscript[],
    transcripts: [] as ITranscript[],
    transcriptRows: [] as ITranscriptRow[],
    isEmpty: false,
    getFolders: [] as ITranscriptRow[],
    foldersShareUserId: [] as ITranscriptRow[],
    roles: {} as {
      [key: string]: {
        [key: string]: Array<string>
      }
    },
    status: 'idle',
    error: null as string | null | undefined,
    allTranscriptStatus: 'idle',
    allSharedTranscriptStatus: 'idle',
    allFolderStatus: 'idle',
    allSharedFolderStatus: 'idle',
    nestedBackup: {
      allTranscripts: [] as ITranscript[],
      allSharedTranscripts: [] as ITranscript[],
      allFolders: [] as IFolders[],
      allSharedFolders: [] as IFolders[],
    },
  },
  reducers: {
    resetTranscripts: (state) => {
      state.allTranscripts = []
      state.allSharedTranscripts = []
      state.allFolders = []
      state.allSharedFolders = []
      state.folderData = undefined
      state.transcriptList = []
      state.transcriptListShareUserId = []
      state.transcripts = []
      state.transcriptRows = []
      state.getFolders = []
      state.foldersShareUserId = []
      state.roles = {}
      state.error = null
      state.status = 'idle'
      state.isEmpty = false
      state.folderDataCheck = undefined
    },
    setNestedAllTranscripts: (state, action) => {
      state.nestedBackup.allTranscripts = action.payload
    },
    setNestedAllSharedTranscripts: (state, action) => {
      state.nestedBackup.allSharedTranscripts = action.payload
    },
    setNestedAllFolders: (state, action) => {
      state.nestedBackup.allFolders = action.payload
    },
    setNestedAllSharedFolders: (state, action) => {
      state.nestedBackup.allSharedFolders = action.payload
    },
    setfolderData: (state, action) => {
      state.folderDataCheck = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTranscriptFolder.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchTranscriptFolder.fulfilled, (state, action) => {
        state.transcriptRows = []
        const id = action.payload.id
        const roles = action.payload.roles
        state.folderDataCheck = action.payload.folderData

        if (!state.folderDataCheck) {
          state.isEmpty = true
        } else {
          state.isEmpty = false
        }
        state.allTranscripts = state.nestedBackup.allTranscripts
        state.allSharedTranscripts = state.nestedBackup.allSharedTranscripts
        state.allFolders = state.nestedBackup.allFolders
        state.allSharedFolders = state.nestedBackup.allSharedFolders
        const transcriptList = JSON.parse(action.payload.transcriptList)
        const transcriptListShareUserId = JSON.parse(
          action.payload.transcriptListShareUserId,
        )
        const folders = JSON.parse(action.payload.folders)
        const foldersShareUserId = JSON.parse(action.payload.foldersShareUserId)

        if (roles) state.roles = roles

        if (transcriptList) {
          state.transcriptList = filterTranscriptData(transcriptList)
        }

        if (transcriptListShareUserId) {
          state.transcriptListShareUserId = filterTranscriptData(
            transcriptListShareUserId,
          )
        }

        if (folders && folders.length > 0) {
          state.getFolders = parseToFoldersList(folders, state.roles, id)
        }

        if (foldersShareUserId && foldersShareUserId.length > 0) {
          state.foldersShareUserId = parseToFoldersList(
            foldersShareUserId,
            state.roles,
            id,
          )
        }

        state.transcripts = [
          ...state.transcriptList,
          ...state.transcriptListShareUserId,
        ]

        if (state.transcripts.length > 0) {
          state.transcriptRows = parseToTranscriptList(
            state.transcripts,
            state.roles,
            id,
          )
        }

        state.transcriptRows = [
          ...checkDuplicates([
            ...state.getFolders,
            ...state.foldersShareUserId,
          ]),
          ...state.transcriptRows,
        ]

        state.status = 'succeeded'
      })
      .addCase(fetchTranscriptFolder.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })

      .addCase(fetchAllTranscript.pending, (state) => {
        state.allTranscriptStatus = 'loading'
      })
      .addCase(fetchAllTranscript.fulfilled, (state) => {
        state.allTranscriptStatus = 'succeeded'
      })
      .addCase(fetchAllTranscript.rejected, (state) => {
        state.allTranscriptStatus = 'failed'
      })

      .addCase(fetchallSharedTranscripts.pending, (state) => {
        state.allSharedTranscriptStatus = 'loading'
      })
      .addCase(fetchallSharedTranscripts.fulfilled, (state) => {
        state.allSharedTranscriptStatus = 'succeeded'
      })
      .addCase(fetchallSharedTranscripts.rejected, (state) => {
        state.allSharedTranscriptStatus = 'failed'
      })

      .addCase(fetchallFolders.pending, (state) => {
        state.allFolderStatus = 'loading'
      })
      .addCase(fetchallFolders.fulfilled, (state) => {
        state.allFolderStatus = 'succeeded'
      })
      .addCase(fetchallFolders.rejected, (state) => {
        state.allFolderStatus = 'failed'
      })

      .addCase(fetchallSharedFolders.pending, (state) => {
        state.allSharedFolderStatus = 'loading'
      })
      .addCase(fetchallSharedFolders.fulfilled, (state) => {
        state.allSharedFolderStatus = 'succeeded'
      })
      .addCase(fetchallSharedFolders.rejected, (state) => {
        state.allSharedFolderStatus = 'failed'
      })
  },
})

export const {
  setfolderData,
  setNestedAllFolders,
  setNestedAllSharedFolders,
  setNestedAllSharedTranscripts,
  setNestedAllTranscripts,
  resetTranscripts,
} = transcriptsSlice.actions

export default transcriptsSlice.reducer

//--------------------------------

const transcriptSlice = createSlice({
  name: 'transcript',
  initialState: {
    transcript: {},
    currentTime: 0,
    errors: {
      byQuery: {},
    },
    transcriptFetched: false,
  },
  reducers: {
    setTranscript: (state, action) => {
      state.transcript = action.payload
      state.transcriptFetched = true
      state.errors.byQuery = {}
    },
    resetState: (state) => {
      state.transcript = {}
      state.currentTime = 0
    },
    setError: (state, action) => {
      const { queryKey, errorMessage } = action.payload
      state.transcript = {}
      state.transcriptFetched = false
      state.errors.byQuery = { [queryKey]: errorMessage }
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload
    },
  },
})
export const { setCurrentTime, setTranscript, setError, resetState } =
  transcriptSlice.actions
export const transcript = transcriptSlice.reducer
