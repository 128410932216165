import React, { useCallback, useState } from 'react'

import { Typography, Button } from '@material-tailwind/react'
import Toast from '../Toast/Toast'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../store/store'
import { updateUserData } from '../../utils/updateUserData'
import firebase from 'firebase/compat/app'

const NotifyCard: React.FC<{
  gif: string
  title: string
  subtitle: string
  classes: string
}> = ({ gif, title, subtitle, classes }) => {
  const [isImageLoading, setIsImageLoading] = useState(true)

  const { t } = useTranslation()

  const { user, userData } = useAppSelector((store) => store.user)
  const _handleNotify = useCallback(async () => {
    if (userData?.id) {
      const currentSettings = userData.notificationSettings || {}
      if (location.pathname.includes('/content-marketing')) {
        const updatedSettings = {
          ...currentSettings,
          notifyContentMarketing: true,
        }

        await updateUserData(user.uid, {
          notificationSettings: updatedSettings,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })

        Toast({
          title: t && t('contentMarketing.name'),
          text: t && t('contentMarketing.notify'),
          variant: 'success',
        })
      } else {
        const updatedSettings = {
          ...currentSettings,
          notifyDirectoryListing: true,
        }

        await updateUserData(user.uid, {
          notificationSettings: updatedSettings,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        Toast({
          title: t && t('directoriesListing.name'),
          text: t && t('contentMarketing.notify'),
          variant: 'success',
        })
      }
    }
  }, [location, Toast, userData, user])

  const handleImageLoadEnd = () => {
    setIsImageLoading(false)
  }

  const _checkNotificationStatus = useCallback(() => {
    const path = location.pathname
    const notifications = userData?.notificationSettings
    if (
      path.includes('/content-marketing') &&
      notifications?.notifyContentMarketing
    ) {
      return true
    } else if (
      path.includes('/directories-listing') &&
      notifications?.notifyDirectoryListing
    ) {
      return true
    }
    return false
  }, [userData])

  return (
    <div className={`flex flex-col lg:flex-row ${classes}`}>
      {isImageLoading && (
        <div
          className={`animate-pulse grid place-items-center rounded-lg bg-gray-300 w-full lg:w-[32em]`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-12 w-12 text-gray-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
            />
          </svg>
        </div>
      )}
      <img
        src={gif}
        onLoad={handleImageLoadEnd}
        className={`${isImageLoading ? 'hidden' : 'block'}`}
      />

      <div className="w-full lg:w-[75%]">
        <Typography
          placeholder={undefined}
          className="text-4xl font-medium mb-4 mt-8 lg:mt-0 lg:ml-8 lg:w-[9em]"
        >
          {title}
        </Typography>
        <Typography
          placeholder={undefined}
          className="text-base text-wellBlueGray-500 font-normal mb-4 mt-8 lg:ml-8"
        >
          {subtitle}
        </Typography>
        <Typography
          placeholder={undefined}
          className="text-well_primary-500 text-base font-medium shadow-none p-0 mt-4 lg:ml-8"
        >
          {t && t('contentMarketing.notifyComingSoon')}
        </Typography>
        {_checkNotificationStatus() ? (
          <Typography
            placeholder={undefined}
            className="text-base text-wellBlueGray-900 font-medium mb-4 mt-8 mt-8 lg:ml-8"
          >
            {t && t('contentMarketing.notifyText')}
          </Typography>
        ) : (
          <Button
            variant="outlined"
            size="sm"
            onClick={_handleNotify}
            className="text-lstnGreen-500 border-lstnGreen-500 mt-8 ml-8"
            placeholder={'upgrade'}
          >
            {t && t('contentMarketing.notifyButton')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default NotifyCard
