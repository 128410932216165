import firebase from 'firebase/compat/app'
import { customEvent } from './customHooks'

interface IGenerateContentIdeas {
  accountId: string
  projectId: string
}

export async function generateContentIdeas({
  accountId,
  projectId,
}: IGenerateContentIdeas): Promise<any> {
  try {
    const token = (await firebase
      .auth()
      .currentUser?.getIdToken(true)) as string

    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_API}/createContentIdeas`,
      // 'http://127.0.0.1:5001/well-shared-development/us-central1/api/createContentIdeas',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ accountId, projectId }),
      },
    )

    if (response.status === 200) {
      const res = await response.json()
      const userId = firebase.auth().currentUser?.uid
      customEvent('content_ideas_created', {
        accountId: accountId,
        projectId: projectId,
        user_userId: userId,
      })
      return res.message
    } else {
      const res = await response.json()
      return res.message
    }
  } catch (err) {
    console.log('Error in generating Ideas', err)
    return 'Error in generating Ideas.'
  }
}
