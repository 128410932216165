import React, { useEffect, useState } from 'react'
import '../css/Settings.css'
import { useTranslation } from 'react-i18next'
// import firebase from 'firebase/compat/app'
import SplashScreen from '../components/SplashScreen'
import { useAppSelector } from '../store/store'
import 'firebase/compat/auth'
import Typography from '../components/Typography/Typography'
import { Input, Textarea, ThemeProvider } from '@material-tailwind/react'
import {
  updateProjectDescription,
  updateProjectLanguage,
  updateProjectName,
  updateProjectWebsite,
} from '../store/features/projectSlice'
import Selector from '../components/SelectInput/Select'
import { customEvent } from '../utils/customHooks'
import contentLanguages from '../utils/contentLanguages'
// import { updateUserData } from '../utils/updateUserData'
// interface IStateProps {
//   user: firebase.UserInfo
// }

// const ProjectSettings = ({ user }: IStateProps): JSX.Element => {
const ProjectSettings = (): JSX.Element => {
  const { t } = useTranslation()
  const { userData } = useAppSelector((store) => store.user)
  const [transLanguage, setTransLanguage] = useState<string>('it')
  const { currentProject } = useAppSelector((store) => store.project)
  const { currentAccount } = useAppSelector((store) => store.account)

  const [projectName, setProjectName] = useState('')
  const [description, setDescription] = useState('')
  const [website, setWebsite] = useState('')
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setProjectName(currentProject.name)
    setDescription(currentProject.description || '')
    setWebsite(currentProject.website || '')
    setTransLanguage(currentProject?.setting?.contentGenerationLanguage || 'en')
  }, [currentProject])

  const handleProjectName = async (
    event: React.ChangeEvent<{ value: string }>,
  ): Promise<void> => {
    const projectName: string = event.target.value
    setProjectName(projectName)
    if (currentProject.id && projectName) {
      await updateProjectName(currentProject.id, projectName)
      customEvent('project_title_changed', {
        user_userId: userData?.id,
        accountId: currentAccount.id,
        projectId: currentProject?.id,
      })
    }
  }

  useEffect(() => {
    // Regular expression to validate URL/domain
    const urlRegex =
      /^(https?:\/\/)?([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)([/:?=&#]{1}[\da-z.-]+)*[/?]?$/gim
    const isValidUrl = urlRegex.test(website)
    setIsValid(isValidUrl)
  }, [website])

  const handleProjectWebsite = async (
    event: React.ChangeEvent<{ value: string }>,
  ): Promise<void> => {
    const projectWebsite: string = event.target.value
    if (currentProject.id) {
      setWebsite(projectWebsite)
      await updateProjectWebsite(currentProject.id, projectWebsite)
      customEvent('project_website_changed', {
        user_userId: userData?.id,
        accountId: currentAccount.id,
        projectId: currentProject?.id,
      })
    }
  }

  const handleProjectDescription = async (
    event: React.ChangeEvent<{ value: string }>,
  ): Promise<void> => {
    const projectDescription: string = event.target.value
    if (currentProject.id) {
      setDescription(projectDescription)
      await updateProjectDescription(currentProject.id, projectDescription)
      customEvent('project_description_changed', {
        user_userId: userData?.id,
        accountId: currentAccount.id,
        projectId: currentProject?.id,
      })
    }
  }

  const handleTransLanguageChange = (value: string | undefined): void => {
    const ln = value || ''
    const beforeLang = transLanguage
    setTransLanguage(ln as string)
    localStorage.setItem('wellshared-trans-lng', ln as string)
    if (currentProject?.id) {
      updateProjectLanguage(currentProject?.id, ln)
      customEvent('project_default_lang_changed', {
        user_userId: userData?.id,
        accountId: currentAccount.id,
        projectId: currentProject?.id,
        actionSource: beforeLang,
        actionDestination: ln,
      })
    }
  }

  return (
    <ThemeProvider>
      {userData && userData.email ? (
        <div className="flex flex-col mt-6 md:container md:mx-auto mx-auto py-4 px-8 sm:overflow-x-scroll md:overflow-hidden">
          <div className="w-[400px] md:w-9/12">
            <Typography className="text-2xl font-medium text-lstnGray-900 my-5">
              {t('projectSettings.title')}
            </Typography>
          </div>
          <div className="flex flex-col min-w-[400px] md:w-[880px] h-auto mt-10 pb-8">
            <Typography className="text-xl font-medium text-lstnGray-900 mt-5">
              {t('projectSettings.overview')}
            </Typography>
            <div className="max-w-sm md:max-w-lg mt-5">
              <Typography className="text-base font-medium text-lstnGray-900">
                {t('projectSettings.projectName')}
              </Typography>
              <Typography className="text-sm font-normal text-lstnGray-900 my-3">
                {t('projectSettings.projectNameInfo')}
              </Typography>
              <Input
                id="project-name"
                className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                value={projectName}
                onChange={handleProjectName}
                placeholder={t('projectSettings.projectNamePlaceholder')}
                variant="outlined"
                crossOrigin={undefined}
              />
              {projectName?.length === 0 && (
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-2 flex items-center gap-1 font-normal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="-mt-px h-4 w-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {t('projectSettings.validName')}
                </Typography>
              )}
            </div>
            <div className="max-w-sm md:max-w-lg mt-5">
              <Typography className="text-base font-medium text-lstnGray-900">
                {t('projectSettings.projectWebsite')}
              </Typography>
              <Typography className="text-sm font-normal text-lstnGray-900 my-3">
                {t('projectSettings.projectWebsiteInfo')}
              </Typography>
              <Input
                id="project-website"
                className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                value={website}
                onChange={handleProjectWebsite}
                placeholder="www.example.com"
                variant="outlined"
                crossOrigin={undefined}
              />
              {website && !isValid && (
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-2 flex items-center gap-1 font-normal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="-mt-px h-4 w-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {t('projectSettings.validUrl')}
                </Typography>
              )}
            </div>
            <div className="max-w-sm md:max-w-lg mt-5">
              <Typography className="text-base font-medium text-lstnGray-900">
                {t('projectSettings.projectDescription')}
              </Typography>
              <Typography className="text-sm font-normal text-lstnGray-900 my-3">
                {t('projectSettings.projectDescriptionInfo')}
              </Typography>
              <Textarea
                id="project-website"
                className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                value={description}
                onChange={handleProjectDescription}
                placeholder={t('projectSettings.projectDescriptionPlaceholder')}
                variant="outlined"
              />
            </div>
            <div
              className="max-w-sm md:max-w-lg mt-5"
              data-cy="project-settings-content-generation-language"
            >
              <Typography className="text-base font-medium text-lstnGray-900">
                {t('projectSettings.primaryLanguage')}
              </Typography>

              <Typography className="text-sm font-normal text-lstnGray-900 my-3">
                {t('projectSettings.primaryLanguageInfo')}
              </Typography>

              <Selector
                selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold"
                className="w-fit"
                options={contentLanguages}
                selectedValue={transLanguage}
                onChange={handleTransLanguageChange}
                children={undefined}
              />
            </div>
          </div>
        </div>
      ) : (
        <SplashScreen />
      )}
    </ThemeProvider>
  )
}
export default ProjectSettings
