import React, { useState, useCallback, useEffect } from 'react'

import {
  Input,
  Button,
  IconButton,
  Card,
  Checkbox,
  // Textarea,
  // Dialog,
  // DialogBody,
  // DialogFooter,
  // Select,
  // Option,
  // DialogHeader,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  DialogHeader,
  Dialog,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import firebase from 'firebase/compat/app'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import CustomTooltip from '../../Tooltip/Tooltip'
import SearchIcon from '../../../icons/search.svg'
import { Selector } from '../../SocialReplies/Select'
import Typography from '../..//Typography/Typography'
import TailwingButton from '../../Button/TailwindButton'

import blogger from '../../../icons/blogger.svg'
import facebook from '../../../icons/facebook.svg'
import twitter from '../../../icons/twitter.svg'
import linkedin from '../../../icons/linkedin.svg'
import editPencil from '../../../icons/editPencil.svg'
import processing from '../../../icons/Analyzing.svg'
import finalized from '../../../icons/finalized.svg'
import published from '../../../icons/publish.svg'
import pencilEdit from '../../../icons/pencil-edit.svg'
import verticalDotIcon from '../../../icons/vertical-dots.svg'
import failed from '../../../icons/Failed.svg'

import { FilterBarsIcon } from '../../SvgIcons'
import { filterOptions, isSameDay } from '../../../utils/dateFiltersConstant'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { IContent } from '../../../interfaces'
import { customEvent } from '../../../utils/customHooks'
import {
  fetchContent,
  setAllContentLoading,
} from '../../../store/features/contentSlice'
import Toast from '../../Toast/Toast'
import { database } from '../../../firebaseApp'

interface SocialButtonsState {
  [key: string]: boolean
}

const ContentCreated: React.FC<{}> = () => {
  const [search, setSearch] = useState('')
  const [allContentDocs, setAllContentDocs] = useState<IContent[]>([])
  const [checkedItems, setCheckedItems] = useState<IContent[]>([])
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [deleteContentId, setDeleteContentId] = useState('')
  const [openDeletionConfirmation, setOpenDeletionConfirmation] =
    useState<boolean>(false)

  //   const [platformFilter, setPlatformFilter] = useState('blog')
  const [filter, setFilter] = useState('All Time')
  const [socialButtonState, setSocialButtonState] =
    useState<SocialButtonsState>({
      blog: true,
      facebook: true,
      twitter: true,
      linkedin: true,
    })

  const { allContent, allContentLoading } = useAppSelector(
    (store) => store.content,
  )
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const { user } = useAppSelector((store) => store.user)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentProject?.id) {
      const data = {
        projectId: currentProject.id,
      }
      dispatch(fetchContent(data))
    }
  }, [currentProject?.id])

  useEffect(() => {
    if (allContent?.length) {
      setAllContentDocs(allContent)
    }
  }, [allContent])

  const REPLIES_COLUMNS = [
    {
      title: 'Platform',
      field: 'Platform',
      width: '8%',
    },
    {
      title: 'Title',
      field: 'Title',
      width: '53%',
    },
    {
      title: t && t('transcripts.status'),
      field: 'status',
      width: '15%',
    },
    {
      title: 'Date',
      field: 'Date',
      width: '15%',
    },
    {
      title: 'Actions',
      field: 'Actions',
      width: '12%',
    },
  ]

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target?.value?.toLowerCase()
    setSearch(event.target?.value)
    if (value?.length) {
      const filteredMention = allContent?.filter((item) => {
        return (
          item.title?.toLocaleLowerCase()?.includes(value) ||
          item.description?.toLocaleLowerCase()?.includes(value) ||
          item.platform?.toLocaleLowerCase()?.includes(value)
        )
      })
      setAllContentDocs(filteredMention)
    } else {
      setAllContentDocs(allContent)
    }
  }

  const handleSelectAll = useCallback((): void => {
    const allSelected = allContent.length === checkedItems.length

    if (allSelected) {
      setCheckedItems([])
    } else {
      setCheckedItems(allContent)
    }
    setSelectAllChecked(!allSelected)
  }, [allContent, checkedItems, setCheckedItems, setSelectAllChecked])

  //   const handlePlatformChange = (value: string | undefined): void => {
  //     if (value) {
  //       console.log('^^^ value', value)
  //       setPlatformFilter(value)
  //     }
  //   }

  const handleFilterChange = (value: string | undefined): void => {
    if (value) {
      setFilter(value)

      const oneDayInMs = 24 * 60 * 60 * 1000

      const today = new Date()
      const yesterday = new Date(today.getTime() - oneDayInMs)
      const lastWeek = new Date(today.getTime() - 7 * oneDayInMs)
      const last30Days = new Date(today.getTime() - 30 * oneDayInMs)
      const last90Days = new Date(today.getTime() - 90 * oneDayInMs)

      switch (value) {
        case 'All Time':
          setAllContentDocs(allContent)
          break
        case 'Today':
          setAllContentDocs(
            allContent.filter((item) =>
              isSameDay(today, item.createdAt.seconds * 1000),
            ),
          )
          break
        case 'Yesterday':
          setAllContentDocs(
            allContent.filter((item) =>
              isSameDay(yesterday, item.createdAt.seconds * 1000),
            ),
          )
          break
        case 'Last 7 days':
          setAllContentDocs(
            allContent.filter(
              (item) => item.createdAt.seconds * 1000 >= lastWeek.getTime(),
            ),
          )
          break
        case 'Last 30 days':
          setAllContentDocs(
            allContent.filter(
              (item) => item.createdAt.seconds * 1000 >= last30Days.getTime(),
            ),
          )
          break
        case 'Last 90 days':
          setAllContentDocs(
            allContent.filter(
              (item) => item.createdAt.seconds * 1000 >= last90Days.getTime(),
            ),
          )
          break
        default:
          setAllContentDocs(allContent)
          break
      }
    }
  }

  const handleTooltipContent = (platform: string): string => {
    const isActive = socialButtonState[platform]
    const socialPlatform =
      platform.charAt(0).toLocaleUpperCase() + platform.slice(1)
    return `${isActive ? 'Hide ' : 'Show '} ${socialPlatform} content`
  }

  const handlePlatformFilter = (platform: string): void => {
    console.log('platformmmm', platform)
    console.log('social buttons', socialButtonState)
    setSocialButtonState((prevState) => ({
      ...prevState,
      [platform]: prevState[platform] ? false : true,
    }))
    const isActive = !socialButtonState[platform]

    console.log('&&& isActive', isActive)

    let filteredDocs: IContent[] = []

    if (!isActive) {
      filteredDocs = allContent.filter((item) => item.platform !== platform)
    } else {
      filteredDocs = allContent.filter((item) => item.platform === platform)
    }

    setAllContentDocs(filteredDocs)
  }

  const handleCreateContent = useCallback(() => {
    const element: HTMLElement | null = document.querySelector(
      'li[data-value="keywords"]',
    )
    element?.click()
  }, [])

  const changeCheckStatusData = async (item: {
    title: string
  }): Promise<void> => {
    const isChecked = checkedItems.some(
      (checkedItem: any) => checkedItem.title === item.title,
    )
    // console.log('^^^ iChecked', isChecked)
    if (isChecked) {
      setCheckedItems((prev: any) =>
        prev.filter((v: any) => v.title !== item.title),
      )
      setSelectAllChecked(false)
    } else {
      setCheckedItems((prev: any) => [...prev, item])
    }
  }

  const handleEdit = useCallback(
    (id: string) => () => {
      navigate(`/content-marketing/${id}`)
    },
    [navigate],
  )

  const handleDeleteButtonClicked = useCallback(
    (contentId: string) => () => {
      setOpenDeletionConfirmation(true)
      setDeleteContentId(contentId)
    },
    [setOpenDeletionConfirmation, setDeleteContentId],
  )

  const handleDeletion = useCallback(async () => {
    customEvent('content_deleted', {
      category: 'content',
      contentId: deleteContentId,
      user_userId: user.uid,
      accountId: currentAccount.id,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })

    if (deleteContentId) {
      const contentRef = database.collection('content').doc(deleteContentId)
      await contentRef.update({ archive: true })

      Toast({
        title: t && t('transcriptDetails.pleaseWait'),
        text: t && t('transcriptDetails.delete'),
        variant: 'success',
      })
      dispatch(setAllContentLoading('idle'))

      const data = {
        projectId: currentProject.id,
      }
      dispatch(fetchContent(data))
    }
  }, [deleteContentId, setAllContentLoading, dispatch])

  const handleDeletionClose = useCallback(() => {
    setOpenDeletionConfirmation(false)
  }, [setOpenDeletionConfirmation])

  const handleDeletionConfirm = useCallback(
    (status?: boolean) => () => {
      setOpenDeletionConfirmation(false)
      if (status) {
        handleDeletion()
      }
    },
    [handleDeletion],
  )

  return (
    <>
      <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
        <div className="flex gap-2"></div>
        <div className="mt-6 flex justify-between">
          <div className="flex gap-2 grow">
            <div className="relative w-80 rounded-xl">
              <img
                src={SearchIcon}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
              />
              <Input
                type="text"
                size="md"
                placeholder="Search"
                value={search}
                className="pl-10 !border-lstnBlueGray-100 focus:!border-gray-900 rounded-xl text-blue-gray-400 placeholder:opacity-100"
                onChange={onSearch}
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
              />
            </div>
            <Button
              placeholder={undefined}
              className="bg-transparent outline-none shadow-none hover:shadow-none px-3"
            >
              <FilterBarsIcon />
            </Button>
            <Selector
              options={filterOptions}
              selectedValue={filter}
              onChange={handleFilterChange}
              className="relative text-sm font-medium w-24 border-none h-[1.5rem] filterSelector text-blue-gray-500  hover:text-lstnBlueGray-900 uppercase bg-transparent rounded-lg"
              children={undefined}
            />
          </div>
          <div className="flex items-center gap-2">
            <CustomTooltip
              content={handleTooltipContent('Blog')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  placeholder={undefined}
                  className={`rounded-full w-8 h-8 bg-[#FF4500] ${
                    !socialButtonState.blog
                      ? 'focus:opacity-[0.3] focus:shadow-md active:opacity-[0.3] opacity-40 shadow-none'
                      : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                  }`}
                  onClick={() => handlePlatformFilter('blog')}
                >
                  <img src={blogger} alt="" />
                </IconButton>
              </span>
            </CustomTooltip>
            <CustomTooltip
              content={handleTooltipContent('facebook')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  // disabled={socialButtonState !== 'facebook'}
                  placeholder={undefined}
                  className={`rounded-full w-8 h-8 bg-[#35518D] ${
                    !socialButtonState.facebook
                      ? 'focus:opacity-[0.3] focus:shadow-md active:opacity-[0.3] opacity-40 shadow-none'
                      : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                  }`}
                  onClick={() => handlePlatformFilter('facebook')}
                >
                  <img src={facebook} alt="" />
                </IconButton>
              </span>
            </CustomTooltip>

            <CustomTooltip
              content={handleTooltipContent('twitter')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  placeholder={undefined}
                  className={`rounded-full w-8 h-8 bg-black ${
                    !socialButtonState.twitter
                      ? 'focus:opacity-[0.3] focus:shadow-md active:opacity-[0.3] opacity-40 shadow-none'
                      : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                  }`}
                  onClick={() => handlePlatformFilter('twitter')}
                >
                  <img src={twitter} alt="" />
                </IconButton>
              </span>
            </CustomTooltip>

            <CustomTooltip
              content={handleTooltipContent('linkedin')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  placeholder={undefined}
                  className={`rounded-full w-8 h-8 bg-[#0A66C2] ${
                    !socialButtonState.linkedin
                      ? 'focus:opacity-[0.3] focus:shadow-md active:opacity-[0.3] opacity-40 shadow-none'
                      : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                  }`}
                  onClick={() => handlePlatformFilter('linkedin')}
                >
                  <img src={linkedin} alt="" />
                </IconButton>
              </span>
            </CustomTooltip>
          </div>
        </div>
        <div>
          <Card
            className="h-full w-full mt-6 overflow-hidden border border-blue-gray-50"
            placeholder={undefined}
          >
            <table className="text-left">
              <thead>
                <tr>
                  <th
                    className={`border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10`}
                    style={{
                      width: '3%',
                    }}
                  >
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={selectAllChecked}
                      crossOrigin={undefined}
                    />
                  </th>
                  {REPLIES_COLUMNS.map((val, index) => (
                    <th
                      key={index}
                      className="border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10 text-sm text-blue-gray-900 font-semibold leading-none"
                      style={{
                        width: val.width, // Set max-width to avoid columns extending beyond container
                      }}
                    >
                      <Typography
                        variant="small"
                        className="font-medium text-lstnBlueGray-900 text-base"
                      >
                        {val.title}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {allContentLoading === 'pending' ||
                allContentLoading === 'idle' ? (
                  <tr>
                    <td
                      colSpan={REPLIES_COLUMNS.length + 1}
                      className="text-lstnBlueGray-500 text-center w-full h-[350px]"
                    >
                      <div className="max-w-full animate-pulse text-center items-center flex flex-col">
                        <Typography
                          as="div"
                          variant="h1"
                          className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : !allContent.length && allContentLoading === 'succeeded' ? (
                  <tr>
                    <td
                      colSpan={REPLIES_COLUMNS.length + 1}
                      className="text-lstnBlueGray-500 text-center h-[350px]"
                    >
                      <div className="w-96 mx-auto">
                        <div className="flex justify-center mb-4">
                          <img src="/emptyglass.svg" alt="img" />
                        </div>
                        <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                          No content found
                        </Typography>
                        <Typography
                          variant="small"
                          className="text-base font-normal"
                        >
                          {t('socialReplies.table.content')}
                        </Typography>
                        <Typography
                          className="text-sm mt-3 font-medium text-lstnBlue-500 cursor-pointer text-well_primary-500 flex items-center justify-center"
                          onClick={handleCreateContent}
                        >
                          <span className="inline-block lstn-btn ml-0 w-3 h-3 p-0 rounded-full text-center relative mr-1 bg-well_primary-500">
                            <svg width="12" height="12" aria-hidden="true">
                              <use xlinkHref="#icon-pluss" />
                            </svg>
                          </span>
                          Add content
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : allContent.length &&
                  !allContentDocs.length &&
                  allContentLoading === 'succeeded' ? (
                  <tr>
                    <td
                      colSpan={REPLIES_COLUMNS.length + 1}
                      className="text-lstnBlueGray-500 text-center h-[350px]"
                    >
                      <div className="w-96 mx-auto">
                        <div className="flex justify-center mb-4">
                          <img src="/emptyFilter.svg" alt="img" />
                        </div>
                        <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                          No content found
                        </Typography>
                        <Typography
                          variant="small"
                          className="text-base font-normal"
                        >
                          {t('socialReplies.table.filterMessage')}
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : (
                  allContentDocs.map((value: any, index) => {
                    const isChecked = checkedItems.some(
                      (checkedItem: any) => checkedItem.title === value.title,
                    )
                    return (
                      <tr className="border-b border-gray-300" key={index}>
                        <td>
                          <Checkbox
                            onChange={() => changeCheckStatusData(value)}
                            checked={isChecked}
                            crossOrigin={undefined}
                          />
                        </td>

                        <td>
                          <div className="py-2 mr-3">
                            {value.platform === 'blog' ? (
                              <img
                                className="w-8 h-8 ml-4"
                                src={blogger}
                                alt=""
                              />
                            ) : value.platform === 'facebook' ? (
                              <div className="w-8 h-8 ml-4 rounded-full bg-[#35518D] focus:opacity-[0.3] focus:shadow-md flex justify-center items-center">
                                <img
                                  className="w-6 h-6"
                                  src={facebook}
                                  alt=""
                                />
                              </div>
                            ) : value.platform === 'twitter' ? (
                              <div className="w-8 h-8 ml-4 rounded-full bg-black focus:opacity-[0.3] focus:shadow-md flex justify-center items-center">
                                <img className="w-6 h-6" src={twitter} alt="" />
                              </div>
                            ) : value.platform === 'linkedin' ? (
                              <div className="w-8 h-8 ml-4 rounded-full bg-[#0A66C2] focus:opacity-[0.3] focus:shadow-md flex justify-center items-center">
                                <img
                                  className="w-4 h-4"
                                  src={linkedin}
                                  alt=""
                                />
                              </div>
                            ) : (
                              'N/A'
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="py-2 mr-3">
                            {value.title && (
                              <Typography className="text-base text-lstnBlueGray-900 font-medium line-clamp-3">
                                {value.title}
                              </Typography>
                            )}
                            {value.description && (
                              <Typography className="text-base text-lstnBlueGray-900 font-normal line-clamp-3">
                                {value.description}
                              </Typography>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="flex gap-2 items-center">
                            <Typography className="text-base text-lstnBlueGray-900 font-normal flex">
                              {value.status === 'draft' ? (
                                <img src={editPencil} alt="" className="mr-1" />
                              ) : value.status === 'finalized' ? (
                                <img src={finalized} alt="" className="mr-1" />
                              ) : value.status === 'published' ? (
                                <img src={published} alt="" className="mr-1" />
                              ) : value.status === 'failed' ||
                                value.status === 'Insufficient credits' ? (
                                <img src={failed} alt="" className="mr-1" />
                              ) : (
                                <img
                                  src={processing}
                                  alt=""
                                  className="mr-1"
                                  height={20}
                                  width={20}
                                />
                              )}
                              {value.status.charAt(0).toUpperCase() +
                                value.status.slice(1)}
                            </Typography>
                          </div>
                        </td>
                        <td>
                          <Typography className="text-base text-lstnBlueGray-900 font-normal">
                            {new firebase.firestore.Timestamp(
                              value.createdAt.seconds,
                              value.createdAt.nanoseconds,
                            )
                              .toDate()
                              .toLocaleString('en-US', {
                                month: 'short',
                                day: '2-digit',
                                year: 'numeric',
                              })}
                          </Typography>
                        </td>
                        <td>
                          <div className="flex items-center gap-4">
                            <Button
                              onClick={handleEdit(value.id)}
                              placeholder={undefined}
                              variant="text"
                              className="min-w-0 h-auto shadow-none hover:shadow-none p-0"
                            >
                              <img
                                src={pencilEdit}
                                className="h-5 w-5 text-gray-400"
                              />
                            </Button>

                            <Menu allowHover>
                              <MenuHandler>
                                <Button
                                  variant="text"
                                  className="min-w-0 h-auto shadow-none hover:shadow-none p-0"
                                  placeholder={undefined}
                                >
                                  <img src={verticalDotIcon} />
                                </Button>
                              </MenuHandler>
                              <MenuList placeholder={undefined}>
                                <MenuItem
                                  placeholder={undefined}
                                  onClick={handleDeleteButtonClicked(value.id)}
                                >
                                  Delete Content
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
      <Dialog
        size="xs"
        open={openDeletionConfirmation}
        placeholder={undefined}
        handler={handleDeletionClose}
      >
        <DialogHeader placeholder={undefined}>Delete Content</DialogHeader>
        <DialogBody placeholder={undefined}>
          Are you sure you want to permanently delete?
        </DialogBody>
        <DialogFooter placeholder={undefined}>
          <TailwingButton
            variant="text"
            // color="blue-gray-900"
            onClick={handleDeletionClose}
            data-cy="data-project-delete-cancel"
            className="mr-1 uppercase"
          >
            <span>{t('renameDialog.cancel')}</span>
          </TailwingButton>
          <TailwingButton
            variant="text"
            color="red"
            onClick={handleDeletionConfirm(true)}
            data-cy="data-project-delete-submit"
            className="uppercase"
          >
            <span>{t('transcripts.delete')}</span>
          </TailwingButton>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default ContentCreated
