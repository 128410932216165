import React, { useCallback } from 'react'
import { Button, Card, CardBody, CardHeader } from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import { customEvent } from '../../utils/customHooks'
import { useAppSelector } from '../../store/store'

interface IProps {
  title: string
  description?: string
  totalMentions?: string | number
  lastWeek?: string
}

export function SocialCard({
  title,
  totalMentions,
  lastWeek,
  description,
}: IProps): React.JSX.Element {
  const { currentProject } = useAppSelector((store) => store.project)
  const { user } = useAppSelector((store) => store.user)

  const handleKeywordsTab = (): void => {
    const element: HTMLElement | null = document.querySelector(
      'li[data-value="keywords"]',
    )
    element?.click()
  }

  const _handleExt = useCallback(() => {
    window.open(process.env.REACT_APP_EXTENSION_URL, '_blank')

    customEvent('link_opened', {
      category: 'misc',
      url: process.env.REACT_APP_EXTENSION_URL,
      projectId: currentProject?.id,
      accountId: currentProject?.accountId,
      user_userId: user.uid,
      actionSource: 'social-replies-card',
    })
  }, [])

  return (
    <Card
      placeholder={undefined}
      className="w-96 border border-lstnBlueGray-50"
    >
      <CardHeader
        placeholder={undefined}
        floated={false}
        className="shadow-none text-center bg-[#F9FAFB] rounded-none mt-0 mx-0 p-4"
      >
        <Typography
          color="blue-gray"
          className="mb-2 text-base font-bold uppercase text-left"
        >
          {title}
        </Typography>
      </CardHeader>
      <CardBody placeholder={undefined} className="pt-2 pb-4 px-3">
        <Typography className="text-base font-normal text-blue-gray-600 ml-[3px]">
          {!description?.length
            ? `Total Mentions: ${totalMentions}`
            : description}
        </Typography>
        {lastWeek ? (
          <Typography className="text-base font-normal text-blue-gray-600 ml-[3px]">
            {lastWeek}
          </Typography>
        ) : title === 'CHROME EXTENSION' ? (
          <Button
            onClick={_handleExt}
            placeholder={undefined}
            className="bg-transparent outline-none hover:shadow-none text-well_primary-500 text-base font-medium shadow-none p-0 capitalize ml-[3px]"
          >
            Get Started
          </Button>
        ) : title === 'PRO HINT' ? (
          <Button
            placeholder={undefined}
            onClick={handleKeywordsTab}
            className="bg-transparent outline-none hover:shadow-none text-well_primary-500 text-base font-medium shadow-none p-0 capitalize ml-[3px]"
          >
            Get Started
          </Button>
        ) : (
          <Button
            placeholder={undefined}
            className="bg-transparent outline-none hover:shadow-none text-blue-gray-500 text-base font-medium shadow-none p-0 capitalize ml-[3px]"
          >
            Coming soon
          </Button>
        )}
      </CardBody>
    </Card>
  )
}
