import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card } from '@material-tailwind/react'
import Typography from '../components/Typography/Typography'
import { useTranslation } from 'react-i18next'
import extensionIcon from '../icons/extension-logo.svg'
import { customEvent } from '../utils/customHooks'
import { useAppSelector } from '../store/store'
import { WordpressIcon } from '../components/SvgIcons'
import { updateAccountToAddIntegrations } from '../store/features/accountSlice'
import WordpressDialog from '../components/Dialog/wordpress-org'
import { validateParameters } from '../utils/validateParameters'
import Toast from '../components/Toast/Toast'

const Integration: React.FC = () => {
  const { t } = useTranslation()

  const { currentProject } = useAppSelector((store) => store.project)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { user } = useAppSelector((store) => store.user)
  const [open, setOpen] = useState(false)
  const [wordpressPlugin, setWordpressPlugin] = useState(false)
  const handleOpen = (): void => {
    if (!open) {
      customEvent('integration_info_opened', {
        category: 'integrations',
        accountId: currentAccount.id,
        projectId: currentProject.id,
        user_userId: user.uid,
        actionMetadata: 'wordpress-org',
      })
    }
    setOpen(!open)
  }

  useEffect(() => {
    const url = window.location.href
    const parsedUrl = new URL(url)
    const params = new URLSearchParams(parsedUrl.search)

    const type = params.get('type')
    const domain = params.get('domain')
    const username = params.get('username')
    const token = params.get('token')
    if (currentProject.accountId && type && domain && username && token) {
      const result = validateParameters(domain, username, token)
      if (result) {
        const userIntegrationsData = {
          type: type,
          domain: domain,
          credentials: {
            username: username,
            token: token,
          },
        }
        updateAccountToAddIntegrations(
          currentProject.accountId,
          userIntegrationsData,
        )
        customEvent('integration_setup', {
          category: 'integrations',
          accountId: currentAccount.id,
          projectId: currentProject.id,
          user_userId: user.uid,
          actionMetadata: 'wordpress-org',
        })
        // Remove the query parameters from the URL
        if (window.history.replaceState) {
          const cleanUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname
          window.history.replaceState({ path: cleanUrl }, '', cleanUrl)
        }
      } else {
        console.error('Invalid username or token.')
        Toast({
          title: 'Invalid username or token.',
          text: 'Please try again with a valid parameters.',
          variant: 'error',
        })
      }
    }
  }, [currentProject.accountId])

  useEffect(() => {
    if (currentAccount.integrations) {
      currentAccount.integrations.forEach((integration: { type: string }) => {
        if (integration.type === 'wordpress-org') {
          setWordpressPlugin(true)
        }
      })
    }
  }, [currentAccount.integrations])

  const _handleExt = useCallback(() => {
    window.open(process.env.REACT_APP_EXTENSION_URL, '_blank')

    customEvent('link_opened', {
      category: 'misc',
      url: process.env.REACT_APP_EXTENSION_URL,
      projectId: currentProject?.id,
      accountId: currentProject?.accountId,
      user_userId: user.uid,
      actionSource: 'integrations-page',
    })
  }, [])

  return (
    <>
      <div className="flex flex-col mt-6 md:container md:mx-auto py-4 px-8 sm:overflow-x-scroll md:overflow-hidden">
        <div className="w-[400px] md:w-9/12">
          <Typography className="text-2xl font-medium text-lstnGray-900 my-5">
            {t && t('sidebar.integrations')}
          </Typography>
        </div>
        <div className="flex flex-col xl:flex-row gap-6 sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-3 pb-8">
          <Card
            className="mt-6 w-full lg:w-[480px] p-6 border border-wellBlueGray-50 shadow hover:shadow-md"
            placeholder={undefined}
          >
            <div className="flex gap-4 lg:gap-0">
              <div className="w-[100px] h-[100px] p-4 border-wellBlueGray-50 border rounded-lg lg:mr-6 grow justify-center items-center">
                <img src={extensionIcon} className="pt-2 pl-1" />
              </div>
              <div className="w-full lg:w-[310px] mt-4 lg:mt-0">
                <Typography className="mb-2 text-2xl text-wellBlueGray-900 font-medium">
                  {t && t('extensionCard.browser.name')}
                </Typography>
                <Typography className="text-base font-normal text-wellBlueGray-500">
                  {t && t('extensionCard.browser.description')}
                </Typography>
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={_handleExt}
                  className="text-lstnGreen-500 border-lstnGreen-500 mt-3"
                  placeholder={'upgrade'}
                >
                  {t && t('extensionCard.browser.getStarted')}
                </Button>
              </div>
            </div>
          </Card>
          <Card
            className="mt-6 w-full lg:w-[480px] p-6 border border-wellBlueGray-50 shadow hover:shadow-md"
            placeholder={undefined}
          >
            <div className="flex gap-4 lg:gap-0">
              <div className="w-[100px] h-[100px] p-4 border-wellBlueGray-50 border rounded-lg lg:mr-6 grow justify-center items-center">
                <WordpressIcon width="5em" height="5em" />
              </div>
              <div className="w-full lg:w-[310px] mt-4 lg:mt-0">
                <Typography className="mb-2 text-2xl text-wellBlueGray-900 font-medium">
                  {/* Wordpress.org Plugin */}
                  {t && t('extensionCard.wordpress.name')}
                </Typography>
                <Typography className="text-base font-normal text-wellBlueGray-500">
                  {/* Login to your Wordpress.org account and install the plugin to
                  get started. */}
                  {t && t('extensionCard.wordpress.description')}
                </Typography>
                {wordpressPlugin ? (
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={handleOpen}
                    className="text-lstnGreen-500 border-lstnGreen-500 mt-3 xl:mt-8"
                    placeholder={'upgrade'}
                  >
                    {t && t('extensionCard.wordpress.installed')}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={handleOpen}
                    className="text-lstnGreen-500 border-lstnGreen-500 mt-3 xl:mt-8"
                    placeholder={'upgrade'}
                  >
                    {t && t('extensionCard.wordpress.getStarted')}
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
      <WordpressDialog open={open} handleClose={handleOpen} />
    </>
  )
}

export default Integration
