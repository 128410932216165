import firebase from 'firebase/compat/app'

export const generateKeywords = async (
  accountId?: string,
  projectId?: string,
): Promise<string> => {
  try {
    const token = (await firebase
      .auth()
      .currentUser?.getIdToken(true)) as string
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_API}/suggestSocialMediaKeywords`,
      // 'http://127.0.0.1:5001/well-shared-development/us-central1/api/suggestSocialMediaKeywords',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ accountId, projectId }),
      },
    )
    if (response.status === 200) {
      const res = await response.json()
      return res.message
    } else {
      const res = await response.json()
      return res.message
    }
  } catch (err) {
    console.log('Error in generating keywords', err)
    return ''
  }
}
