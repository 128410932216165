import React, { useCallback, useEffect, useState } from 'react'
import { RefreshIcon, CopyIcon, QuestionMarkIcon } from '../../SvgIcons'
import {
  Input,
  Textarea,
  Typography,
  Button,
  Chip,
  Spinner,
} from '@material-tailwind/react'

import { IconButton } from '@material-ui/core'

import CustomTooltip from '../../Tooltip/Tooltip'

import { useAppSelector, useAppDispatch } from '../../../store/store'
import { database } from '../../../firebaseApp'
import { IContent } from '../../../interfaces'
import { customEvent } from '../../../utils/customHooks'
import Toast from '../../Toast/Toast'
import firebase from 'firebase/compat/app'
import { setSelectedContent } from '../../../store/features/contentSlice'
// import refresh from '../../../icons/refresh-content.svg'

import infoIcon from '../../../icons/info.svg'
import { markdownWordConverter } from '../../../utils/markdownWordCounter'

const KEYWORDS = ['Ai Transcription', 'Blockchain', 'Crypto', 'Health Care']

interface ISEOContentProps {
  activeVersion: string
}

const SEO: React.FC<ISEOContentProps> = ({ activeVersion }) => {
  const [keywords, setKeywords] = useState(KEYWORDS)
  const [inputKeyword, setInputKeyword] = useState('')
  const [keywordLoading, setKeywordLoading] = useState(false)
  const [isRegeneratingSEO, setIsRegeneratingSEO] = useState<string>('')

  const { selectedContent } = useAppSelector((state) => state.content)
  const { currentProject } = useAppSelector((state) => state.project)
  const { currentAccount } = useAppSelector((state) => state.account)
  const { user } = useAppSelector((state) => state.user)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedContent?.keywords?.length) {
      setKeywords(selectedContent?.keywords)
    }
  }, [selectedContent?.keywords])

  const updateArticle = useCallback(
    async (data: IContent) => {
      try {
        const { id } = data
        const userRef = database.collection(`content`).doc(id)
        await userRef.update({ ...data })
      } catch (error) {
        console.error('error', error)
      }
    },
    [database],
  )

  const handleRemove = useCallback(
    (item: string) => () => {
      const filteredKeywords = keywords.filter((keywd) => keywd !== item)
      setKeywords(filteredKeywords)
      const articleData = { ...selectedContent }

      articleData.keywords = filteredKeywords

      if (articleData?.id) {
        updateArticle(articleData)
        customEvent('content_keyword_removed', {
          category: 'content',
          contentId: articleData.id,
          user_userId: user.uid,
          projectId: currentProject.id,
          accountId: currentAccount.id,
        })
      }
    },
    [setKeywords, keywords, selectedContent, currentAccount, currentProject],
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setInputKeyword(event.target.value)
    },
    [setInputKeyword],
  )

  const addKeyword = useCallback((): void => {
    setKeywordLoading(true)
    setKeywords([...keywords, inputKeyword])
    const articleData = { ...selectedContent }

    articleData.keywords = [...keywords, inputKeyword]

    if (articleData?.id) {
      updateArticle(articleData)
      customEvent('content_keyword_added', {
        category: 'content',
        contentId: articleData.id,
        user_userId: user.uid,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
        accountId: currentAccount?.id,
      })
    }

    setInputKeyword('')
    setKeywordLoading(false)
  }, [
    setKeywordLoading,
    inputKeyword,
    keywords,
    setInputKeyword,
    currentAccount,
    currentProject,
    user,
  ])

  const handleUpdateSlug = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const articleData = { ...selectedContent }

      articleData.slug = event.target.value
      if (articleData?.id) {
        updateArticle(articleData)
        customEvent('content_seo_slug_edited', {
          category: 'content',
          contentId: selectedContent?.id,
          user_userId: user.uid,
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
          actionSource: 'from-sidepanel-info',
        })
      }
    },
    [selectedContent, user, currentProject, currentAccount],
  )

  const handleUpdateSummary = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const articleData = { ...selectedContent }

      articleData.summary = event.target.value
      if (articleData?.id) {
        updateArticle(articleData)

        customEvent('content_seo_meta_desc_edited', {
          category: 'content',
          contentId: selectedContent?.id,
          user_userId: user.uid,
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
          actionSource: 'from-sidepanel-info',
        })
      }
    },
    [selectedContent, user, currentProject, currentAccount],
  )

  const handleCopyKeywords = useCallback(async () => {
    let keywordsToBeCopied = ''
    keywords.forEach(
      (kw: string) => (keywordsToBeCopied = kw + ', ' + keywordsToBeCopied),
    )

    if (navigator.clipboard) {
      const element = document.createElement('div')
      element.innerHTML = keywordsToBeCopied
      const text = element.innerText.trim()
      await navigator.clipboard.writeText(text)
    } else {
      const textField = document.createElement('textarea')
      textField.innerText = keywordsToBeCopied
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
    }

    Toast({
      title: 'Keywords Copied',
      text: 'Article keywords copied',
      variant: 'success',
    })
  }, [keywords])

  const handleRegenerateSEO = useCallback(
    (type: string) => async () => {
      setIsRegeneratingSEO(type)
      try {
        const token = await firebase.auth().currentUser?.getIdToken()
        Toast({
          title: `Regenerating ${
            type === 'readabilityScore'
              ? 'Readability Score'
              : type === 'summary'
                ? 'Summary'
                : 'Keywords'
          } `,
          text: `Please wait your ${
            type === 'readabilityScore'
              ? 'readability score'
              : type === 'summary'
                ? type
                : type
          } is regenerating`,
          variant: 'success',
          options: { autoClose: 1000 },
        })
        // const data = {

        // }

        const response = await fetch(
          `${process.env.REACT_APP_FIREBASE_API}/regenerateSEO`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              title: selectedContent.title,
              articleId: selectedContent.id,
              description: selectedContent.description,
              projectId: currentProject?.id,
              type,
            }),
          },
        )
        if (response.status === 200) {
          const responseData = await response.json()
          const data: IContent = { ...selectedContent }
          console.log('&&& responseData', responseData.result)
          if (type === 'readabilityScore') {
            data.readabilityScore = JSON.parse(responseData.result)

            customEvent('editor_readability_score_regenerated', {
              category: 'content',
              contentId: selectedContent?.id,
              user_userId: user.uid,
            })
          } else if (type === 'summary') {
            data.summary = JSON.parse(responseData.result)

            customEvent('content_seo_meta_desc_regenerated', {
              category: 'content',
              contentId: selectedContent?.id,
              user_userId: user.uid,
              accountId: currentAccount.id,
              projectId:
                currentProject && currentProject.id ? currentProject.id : '',
              actionSource: 'from-sidepanel-info',
              actionMetadata: 'user-action',
            })
          } else if (type === 'keywords') {
            data.keywords = responseData.result

            customEvent('content_seo_keywords_regenerated', {
              category: 'content',
              contentId: selectedContent?.id,
              user_userId: user.uid,
              accountId: currentAccount.id,
              projectId:
                currentProject && currentProject.id ? currentProject.id : '',
              actionSource: 'from-sidepanel-info',
              actionMetadata: 'user-action',
            })
          }

          console.log('&& ressss', data)

          dispatch(setSelectedContent(data))

          setIsRegeneratingSEO('')

          Toast({
            title: `${
              type === 'readabilityScore'
                ? 'Readability score'
                : type === 'summary'
                  ? 'Summary'
                  : 'Keywords'
            } Regenerated`,
            text: `${
              type === 'readabilityScore'
                ? 'Readability Score'
                : type === 'summary'
                  ? 'Summary'
                  : 'Keywords'
            } has been successfully regenerated`,
            variant: 'success',
            options: { autoClose: 3000 },
          })
        }
      } catch (err) {
        setIsRegeneratingSEO('')
        console.log('_error saving content', err)
        Toast({
          title: 'SEO regenereating error',
          text: 'Error occured while SEO regenereating',
          variant: 'error',
          options: { autoClose: 3000 },
        })
      }
    },
    [
      selectedContent,
      setIsRegeneratingSEO,
      setSelectedContent,
      currentAccount,
      currentProject,
      user,
    ],
  )
  return (
    <div className="px-4 py-3 w-full min-h-screen">
      <h2 className="text-base font-sans font-medium text-blue-gray-900 mb-1">
        Word count
      </h2>

      <Typography
        placeholder={undefined}
        className="text-sm font-normal text-blue-gray-500 pt-0 mt-0 mb-3"
      >
        {markdownWordConverter(
          JSON.parse(selectedContent?.contentGenerated[activeVersion]?.content),
        )}
      </Typography>
      <div className="mt-2">
        <div className="flex align-center">
          <h2 className="text-base font-sans font-medium text-blue-gray-900 mb-1">
            Readability
          </h2>

          {isRegeneratingSEO === 'readabilityScore' ? (
            <Spinner className="color-lstnGreen-500 w-4 h-4 ml-2 mt-1" />
          ) : (
            <div className="mt-[1px] ml-2 w-2 h-2">
              <IconButton
                size="small"
                onClick={handleRegenerateSEO('readabilityScore')}
              >
                <RefreshIcon strokeColor="#263238" />
              </IconButton>
            </div>
          )}
        </div>
        <div className="flex align-center">
          <Typography
            placeholder={undefined}
            className="text-sm font-normal text-blue-gray-500 pt-0 mt-0 mb-3"
          >
            {selectedContent?.readabilityScore}
          </Typography>
          <CustomTooltip
            content={'Readability of content'}
            className="mt-3 bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
          >
            <img src={infoIcon} className="h-3 w-3 mt-[4px] ml-2" />
          </CustomTooltip>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex align-center mb-1">
          <h2 className="text-base font-sans font-medium text-blue-gray-900">
            Slug
          </h2>
          <div className="mt-[4px] ml-2 w-4 h-4">
            <QuestionMarkIcon />
          </div>
        </div>
        <Input
          onChange={handleUpdateSlug}
          placeholder="barack-obama-deepfakes-speech."
          className=" !border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100"
          crossOrigin={undefined}
          labelProps={{
            className: 'hidden m-0 p-0',
          }}
          value={selectedContent?.slug}
        />
      </div>

      <div className="mt-4">
        <div className="flex align-center">
          <h2 className="text-base font-sans font-medium text-blue-gray-900">
            Meta Description
          </h2>
          {isRegeneratingSEO === 'summary' ? (
            <Spinner className="color-lstnGreen-500 w-4 h-4 ml-2 mt-1" />
          ) : (
            <div className="mt-[1px] ml-2 w-2 h-2">
              <IconButton size="small" onClick={handleRegenerateSEO('summary')}>
                <RefreshIcon strokeColor="#263238" />
              </IconButton>
            </div>
          )}
        </div>
        <Textarea
          onChange={handleUpdateSummary}
          value={selectedContent?.summary}
          className="!border-blue-gray-100 focus:!border-gray-300  placeholder:text-blue-gray-300 placeholder:opacity-100"
          labelProps={{
            className: 'hidden',
          }}
          placeholder="President Obama talks about the impact of technology on the way in which we perceive media."
        />
      </div>

      <div className="mt-4">
        <div className="flex align-center">
          <h2 className="text-base font-sans font-medium text-blue-gray-900">
            {'Tags (secondary keywords)'}
          </h2>

          <div className="mt-[1px] mx-2 w-2 h-2">
            <IconButton size="small" onClick={handleCopyKeywords}>
              <CopyIcon />
            </IconButton>
          </div>
          {isRegeneratingSEO === 'keywords' ? (
            <Spinner className="color-lstnGreen-500 w-4 h-4 ml-2 mt-1" />
          ) : (
            <div className="mt-[1px] ml-2 w-2 h-2">
              <IconButton
                size="small"
                onClick={handleRegenerateSEO('keywords')}
              >
                <RefreshIcon strokeColor="#263238" />
              </IconButton>
            </div>
          )}
        </div>
        <div
          className="relative flex w-full max-w-[18rem]"
          style={{ marginLeft: '0px', margin: '1rem 0px' }}
        >
          <Input
            placeholder="Type keyword"
            type="text"
            value={inputKeyword}
            className="!border-blue-gray-100 focus:!border-gray-300  placeholder:text-blue-gray-300 placeholder:opacity-100"
            onChange={onChange}
            labelProps={{
              className: 'hidden',
            }}
            disabled={keywordLoading}
            crossOrigin={undefined}
          />
          <Button
            onClick={addKeyword}
            placeholder={undefined}
            loading={keywordLoading}
            variant="filled"
            disabled={!inputKeyword.length}
            size="sm"
            color="gray"
            className="!absolute right-1 top-1 rounded bg-well_primary-50 text-well_primary-500"
          >
            ADD
          </Button>
        </div>
        <div className="my-2 flex flex-wrap gap-2">
          {keywords?.map((item, index) => {
            return (
              <Chip
                key={index}
                open={true}
                animate={{
                  mount: { y: 0 },
                  unmount: { y: 50 },
                }}
                size="sm"
                className="seo-chip border border-well_primary-500 bg-white text-well_primary-500 w-fit capitalize text-xs font-bold px-2 py-1"
                value={item}
                onClose={handleRemove(item)}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SEO
