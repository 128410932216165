import * as React from 'react'
import { useState, useRef, useEffect, useCallback } from 'react'
import {
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router'
import Lottie from 'lottie-react'

import firebase from 'firebase/compat/app'
import '../css/TranscriptDetails.css'
import { useAppDispatch, useAppSelector } from '../store/store'
import { fetchContentDoc } from '../store/features/transcript/helper'
import { customEvent } from '../utils/customHooks'
import { database } from '../firebaseApp'
import { useTranslation } from 'react-i18next'
import Typography from '../components/Typography/Typography'
import Toast from '../components/Toast/Toast'
import { Card } from '@material-tailwind/react'

import Button from '../components/Button/TailwindButton'
import RenameDialog from '../components/ContentMarketing/RenameDialog'
import SlateEditor from '../components/ContentMarketing/SlateEditor'
import GroupButton from '../components/GroupButton'
import ContentDetailsTour from '../components/onBoarding/ContentDetailsTour'
import NoAccessPage from '../components/NoAccess/NoAccess'
import waiting from '../lottie/loading-ideas-lottie.json'
import regenerate from '../icons/refresh-content.svg'
import failed from '../icons/Failed.svg'

import { IContent } from '../interfaces'

import { setSelectedContent } from '../store/features/contentSlice'
import { setShowUpgradeModal } from '../store/features/userSlice'

const ContentMarketingDetails: React.FC<{ user: firebase.UserInfo }> = (
  props,
) => {
  const textAreaRef = useRef<HTMLDivElement | null>(null)
  const params = useParams()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [openDeletionConfirmation, setOpenDeletionConfirmation] =
    useState<boolean>(false)
  const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false)
  const [isTitleRenaming, setIsTitleRenaming] = useState<boolean>(false)
  const [isRegenerated, setIsRegenerated] = useState<boolean>(false)
  const [isRegeneratingContent, setIsRegeneratingContent] =
    useState<boolean>(false)
  const [isContentLoading, setIsContentLoading] = useState<boolean>(true)
  const [activeVersion, setActiveVersion] = useState('')
  const [generatedContentVersions, setGeneratedContentVersions] = useState<
    string[]
  >([])
  const [openTour, setOpenTour] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { selectedContent } = useAppSelector((store) => store.content)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const { userData, user } = useAppSelector((store) => store.user)
  const { t } = useTranslation()

  const actionSidePanel: boolean = !!currentAccount?.features?.actionsidepanel

  const tableRef = useRef(null)

  const navigate = useNavigate()
  useEffect(() => {
    if (
      userData &&
      userData.onboardingSteps &&
      !userData?.onboardingSteps?.contentEdit
    ) {
      setOpenTour(true)
    }

    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (
      userData &&
      userData?.onboardingSteps &&
      !userData?.onboardingSteps?.contentEdit &&
      !openTour
    ) {
      setOpenTour(true)
    }
  }, [userData])

  useEffect(() => {
    if (selectedContent?.contentGenerated) {
      const versions = Object.keys(selectedContent?.contentGenerated)
      const updatedVersion = versions.sort(function (a, b) {
        const numA = parseInt(a.substring(1))
        const numB = parseInt(b.substring(1))

        return numB - numA
      })
      setGeneratedContentVersions(updatedVersion)
    }
  }, [selectedContent])

  useEffect(() => {
    if (selectedContent?.status === 'writing' || !selectedContent?.status) {
      setIsRegeneratingContent(true)
    } else {
      setIsRegeneratingContent(false)
    }
  }, [selectedContent])

  const contentMarketingBetaTesting =
    currentAccount?.features?.contentMarketingBetaTesting

  const _checkVersions = useCallback(() => {
    const { contentGenerated } = selectedContent
    const versions = Object.keys(contentGenerated)
    versions?.find((vrActive) => {
      if (contentGenerated[vrActive].active) {
        setActiveVersion(vrActive)
        return contentGenerated[vrActive]
      }
    })
  }, [selectedContent, setActiveVersion])

  useEffect(() => {
    if (selectedContent?.id && selectedContent?.contentGenerated) {
      _checkVersions()
    }
  }, [selectedContent])

  useEffect(() => {
    if (params?.id) {
      dispatch(fetchContentDoc(params?.id))
    }
  }, [params.id])

  useEffect(() => {
    if (generatedContentVersions?.length) {
      setIsContentLoading(false)
    }
  }, [generatedContentVersions])

  const handleEditButton = useCallback(() => {
    setOpenRenameDialog(true)
    customEvent('content_rename_modal_opened', {
      category: 'content',
      contentId: selectedContent.id,
      user_userId: props.user ? props.user.uid : '',
      accountId: currentAccount.id,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })
  }, [currentProject, currentAccount, selectedContent])

  const handleEditClose = useCallback(
    async (status: boolean, value?: string) => {
      setIsTitleRenaming(true)

      if (status) {
        customEvent('content_rename_requested', {
          category: 'content',
          contentId: selectedContent?.id,
          user_userId: props.user ? props.user.uid : '',
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        })

        try {
          const payload = {
            data: {
              id: selectedContent.id,
              content: {
                ...selectedContent,
                title: value,
              },
            },
          }
          const token = await firebase.auth().currentUser?.getIdToken()
          const response = await fetch(
            `${process.env.REACT_APP_FIREBASE_API}/updateContent`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(payload),
            },
          )
          if (response.status === 200) {
            Toast({
              title: 'Content Title',
              text: 'Content title updated successfully',
              variant: 'success',
            })

            customEvent('content_renamed', {
              category: 'content',
              contentId: selectedContent?.id,
              user_userId: props.user ? props.user.uid : '',
              accountId: currentAccount.id,
              projectId:
                currentProject && currentProject.id ? currentProject.id : '',
              actionSource: 'from-content-header',
            })
          }
          setIsTitleRenaming(false)
        } catch (error: any) {
          console.error('error updating content title', error)
          ReactGA.gtag('event', 'content_renamed_exception', {
            description: error?.message,
            fatal: false,
          })
          setIsTitleRenaming(false)
        }
      }
      setOpenRenameDialog(false)
      setOpenRenameDialog(false)
    },
    [selectedContent, currentAccount, currentProject, props],
  )

  const handleDeletion = useCallback(async () => {
    try {
      setIsDeleting(true)
      customEvent('content_deleted', {
        category: 'content',
        contentId: params.id,
        user_userId: props.user ? props.user.uid : '',
        accountId: currentAccount.id,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })

      if (selectedContent?.id) {
        const data = { ...selectedContent }
        const contentRef = database.collection('content').doc(data?.id)
        await contentRef.update({ archive: true })

        Toast({
          title: t && t('transcriptDetails.pleaseWait'),
          text: t && t('transcriptDetails.delete'),
          variant: 'success',
        })

        customEvent('content_deleted', {
          category: 'content',
          contentId: selectedContent.id,
          user_userId: props.user ? props.user.uid : '',
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        })

        navigate('/content-marketing')
        setActiveVersion('')
        setGeneratedContentVersions([])
        dispatch(setSelectedContent({}))
        setIsDeleting(false)
      }
    } catch (err) {
      setIsDeleting(false)
      console.log('error deleting article', err)
    }
  }, [
    selectedContent,
    currentAccount,
    currentProject,
    setSelectedContent,
    dispatch,
    setIsDeleting,
  ])

  const handleDeleteButtonClicked = useCallback(() => {
    customEvent('content_delete_confirm_modal_opened', {
      category: 'content',
      contentId: selectedContent.id,
      user_userId: props.user ? props.user.uid : '',
      accountId: currentAccount.id,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })
    setOpenDeletionConfirmation(true)
  }, [
    setOpenDeletionConfirmation,
    selectedContent,
    currentAccount,
    currentProject,
  ])

  const handleDeletionClose = useCallback(() => {
    setOpenDeletionConfirmation(false)
  }, [])

  const handleDeletionConfirm = useCallback(
    (status?: boolean) => () => {
      setOpenDeletionConfirmation(false)
      if (status) {
        customEvent('content_delete_requested', {
          category: 'content',
          contentId: selectedContent.id,
          user_userId: props.user ? props.user.uid : '',
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        })
        handleDeletion()
      }
    },
    [handleDeletion, selectedContent, currentAccount, currentProject],
  )

  const handleDescription = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      const data = { ...selectedContent }
      data.description = ev.target.value
      updateDescription(data)
    },
    [params.id, selectedContent],
  )

  async function updateDescription(data: IContent): Promise<void> {
    try {
      const contentRef = database.collection(`content`).doc(data?.id)
      await contentRef.update({ ...data })

      customEvent('content_description_edited', {
        category: 'content',
        contentId: selectedContent?.id,
        user_userId: props.user ? props.user.uid : '',
        accountId: currentAccount.id,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
        actionSource: 'from-content-header',
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  const closeOnboarding = useCallback(async () => {
    const onboardingSteps = { ...userData.onboardingSteps }
    onboardingSteps.contentEdit = true
    await onBoardingComplete(props.user.uid, { onboardingSteps })
    setOpenTour(false)
  }, [setOpenTour])

  async function onBoardingComplete(id: string, data: any): Promise<void> {
    try {
      const userRef = database.collection(`users`).doc(id)
      await userRef.update({ ...data })
    } catch (error) {
      console.error('error', error)
    }
  }

  const updateSection = useCallback(
    async (content: string, version: string) => {
      try {
        const data = { ...selectedContent }
        const updatedContentGenerated = {
          ...selectedContent.contentGenerated,
          [version]: {
            active: true,
            content,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
        }
        data.contentGenerated = updatedContentGenerated
        const contentRef = database.collection('content').doc(data?.id)
        await contentRef.update({ ...data })
      } catch (error) {
        console.error('error', error)
      }
    },
    [selectedContent],
  )

  async function slateToDocx(
    fileContent: string,
    fileName: string,
  ): Promise<void> {
    const token = await firebase.auth().currentUser?.getIdToken()
    const { title, description } = selectedContent
    fetch(`${process.env.REACT_APP_FIREBASE_API}/export`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: fileContent,
        fileName,
        title,
        description,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error('Failed to convert to DOCX')
        }
        const contentDisposition = response.headers.get('Content-Disposition')
        if (contentDisposition) {
          customEvent('content_exported', {
            category: 'content',
            contentId: selectedContent.id,
            user_userId: props.user ? props.user.uid : '',
            accountId: currentAccount.id,
            projectId:
              currentProject && currentProject.id ? currentProject.id : '',
            fileType: 'docx',
          })

          const filename = contentDisposition
            .split('filename=')[1]
            .trim()
            .replace(/"/g, '')
          return response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()

            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
          })
        } else {
          Toast({
            title: 'Error',
            text: 'Please try again or contact support',
            variant: 'error',
          })
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        Toast({
          title: 'Error',
          text: 'Please try again or contact support',
          variant: 'error',
        })
      })
  }

  const handleContentCopy = useCallback(async (contentText: string) => {
    if (navigator.clipboard) {
      const element = document.createElement('div')
      element.innerHTML = contentText
      const text = element.innerText.trim()
      await navigator.clipboard.writeText(text)
      Toast({
        title: 'Article Content',
        text: 'Article content copied',
        variant: 'success',
      })
    } else {
      const textField = document.createElement('textarea')
      textField.innerText = contentText
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      Toast({
        title: 'Article Content',
        text: 'Article content copied',
        variant: 'success',
      })
    }
  }, [])

  const handleRegenerateContent = useCallback(async () => {
    try {
      setIsRegeneratingContent(true)
      Toast({
        title: 'Please wait',
        text: 'Your content is regenerating',
        variant: 'success',
        options: { autoClose: 3000 },
      })

      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/generateContent`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            title: selectedContent.title,
            articleId: selectedContent.id,
            description: selectedContent.description,
            projectId: currentProject?.id,
          }),
        },
      )
      if (response.status === 200) {
        const version = Object.keys(selectedContent?.contentGenerated)
        const listVersion = version.length

        const createdContent = await response.json()
        const data = { ...selectedContent }
        const dataContent = JSON.parse(
          JSON.stringify(selectedContent?.contentGenerated),
        )

        for (const key in dataContent) {
          if (key) {
            dataContent[key].active = false
          }
        }

        const regeneratedContent = JSON.parse(
          createdContent?.regeneratedContent,
        )

        const updatedContentGenerated = {
          ...dataContent,
          [`v${listVersion + 1}`]: {
            active: true,
            content: JSON.stringify(regeneratedContent?.content),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
        }
        data.contentGenerated = updatedContentGenerated

        dispatch(setSelectedContent(data))
        const contentRef = database.collection('content').doc(data?.id)
        await contentRef.update({ status: 'draft' })

        customEvent('editor_content_regenerated', {
          category: 'content',
          contentId: selectedContent.id,
          user_userId: props.user ? props.user.uid : '',
          actionMetadata: `created v${listVersion + 1}`,
        })

        setIsRegenerated(true)
        setIsRegeneratingContent(false)
        Toast({
          title: 'Content Regenerated',
          text: 'Content has been successfully regenerated',
          variant: 'success',
          options: { autoClose: 3000 },
        })
      }
    } catch (err) {
      console.log('_error regnerating content', err)
      setIsRegeneratingContent(false)
      Toast({
        title: 'Content Regenration',
        text: 'Error occured while regenrating content',
        variant: 'error',
        options: { autoClose: 3000 },
      })
    }
  }, [
    selectedContent,
    params,
    setSelectedContent,
    dispatch,
    setIsRegenerated,
    setIsRegeneratingContent,
    setActiveVersion,
    currentProject,
  ])

  const handleUpgrade = (): void => {
    customEvent('billing_upgrade_modal_opened', {
      category: 'accounts',
      accountId: currentAccount.id,
      user_userId: user.uid,
      projectId: currentProject?.id,
      actionSource: 'projects-limit-reached',
    })
    dispatch(setShowUpgradeModal(true))
  }

  return (
    <main className="transcript-details">
      <div className="flex flex-row-reverse gap-4">
        {actionSidePanel &&
          !isRegeneratingContent &&
          activeVersion &&
          activeVersion?.length && (
            <GroupButton
              handleRegenerateContent={handleRegenerateContent}
              activeVersion={activeVersion}
              setActiveVersion={setActiveVersion}
              contentVersions={generatedContentVersions}
              userId={props.user.uid}
            />
          )}
        <div className="transcript-details-container">
          {contentMarketingBetaTesting ? (
            <>
              <RenameDialog
                open={openRenameDialog}
                initialValue={
                  selectedContent?.title ? selectedContent?.title : ''
                }
                onClose={handleEditClose}
                loading={isTitleRenaming}
              />
              <div className="transcript-details-title">
                <Typography
                  className="text-2xl font-medium text-lstnBlueGray-900"
                  data-cy="data-project-transcript-details-title"
                  onClick={handleEditButton}
                >
                  {selectedContent?.title}
                </Typography>
              </div>
              <section className="transcript-details-description">
                <Textarea
                  data-cy="transcript-details-description-data"
                  className="min-h-[20px] pt-1 text-sm font-normal text-lstnBlueGray-400"
                  labelProps={{ className: 'hidden' }}
                  // rows={1}
                  ref={textAreaRef}
                  onChange={handleDescription}
                  placeholder={t && t('transcriptDetails.description')}
                  value={selectedContent?.description}
                />
              </section>

              {isRegeneratingContent ? (
                <>
                  <div className="w-full animate-pulse flex flex-col justify-center p-4">
                    <div className="w-full flex justify-start ml-[2%]">
                      <Typography
                        as="div"
                        variant="h1"
                        className="mb-4 h-10 w-[90%] rounded-md bg-lstnBlueGray-100"
                      >
                        &nbsp;
                      </Typography>
                    </div>
                  </div>
                  <div className="shadow py-4 px-8 mt-4 border rounded-lg bg-white">
                    <div
                      className="text-lstnBlueGray-500 text-center w-full"
                      ref={tableRef}
                    >
                      <div className="max-w-full animate-pulse text-center items-center flex flex-col">
                        <Lottie
                          animationData={waiting}
                          loop
                          autoPlay
                          style={{ width: '120px', height: '120px' }}
                        />
                        <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                          Creating article content
                        </Typography>
                        <Typography
                          variant="small"
                          className="text-base font-normal"
                        >
                          It may take a few seconds, please wait
                        </Typography>
                      </div>
                    </div>
                    <div className="w-full animate-pulse flex flex-col justify-center p-4">
                      <div className="mt-12">
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-10 w-[40%] rounded-md bg-lstnBlueGray-100"
                        >
                          &nbsp;
                        </Typography>
                      </div>
                      <div className="mt-4">
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                        >
                          &nbsp;
                        </Typography>
                      </div>
                      <div className="mt-8">
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-10 w-[50%] rounded-md bg-lstnBlueGray-100"
                        >
                          &nbsp;
                        </Typography>
                      </div>
                      <div className="mt-4">
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                        >
                          &nbsp;
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              ) : selectedContent.status === 'failed' ? (
                <Card
                  className="flex flex-col items-center justify-center h-80 border"
                  placeholder={undefined}
                >
                  <Typography
                    as="div"
                    variant="paragraph"
                    className="text-lstnBlueGray-900 flex items-center font-normal justify-center"
                  >
                    <img src={failed} alt="" className="mr-1" /> Content
                    generation failed. Please try again.
                  </Typography>
                  <Button
                    onClick={handleRegenerateContent}
                    variant="outlined"
                    size="sm"
                    className="mt-4 text-lstnGreen-500 border-lstnGreen-500 flex"
                  >
                    <img src={regenerate} className="mr-1" />
                    Regenerate Content
                  </Button>
                </Card>
              ) : selectedContent.status === 'Insufficient credits' ? (
                <Card
                  className="flex flex-col items-center justify-center h-80 border"
                  placeholder={undefined}
                >
                  <Typography
                    as="div"
                    variant="paragraph"
                    className="text-lstnBlueGray-900 flex items-center font-normal justify-center"
                  >
                    <img src={failed} alt="" className="mr-1" /> Content
                    generation failed. You have insufficient credits. Please
                    upgrade your credits to generate content.
                  </Typography>
                  <Button
                    onClick={handleUpgrade}
                    variant="outlined"
                    size="sm"
                    className="mt-4 text-lstnGreen-500 border-lstnGreen-500 flex"
                  >
                    Upgrade
                  </Button>
                </Card>
              ) : generatedContentVersions &&
                generatedContentVersions?.length &&
                !isDeleting &&
                selectedContent?.contentGenerated[activeVersion]?.content ? (
                <>
                  <SlateEditor
                    sectionContent={
                      selectedContent?.contentGenerated[activeVersion]
                    }
                    contentVersion={activeVersion}
                    updateSection={updateSection}
                    setIsRegenerated={setIsRegenerated}
                    isRegenerated={isRegenerated}
                    handleDeleteContent={handleDeleteButtonClicked}
                    handleContentCopy={handleContentCopy}
                    isContentLoading={isContentLoading}
                    slateToDocx={slateToDocx}
                  />
                  <ContentDetailsTour
                    openTour={openTour}
                    closeTour={closeOnboarding}
                  />
                </>
              ) : (
                <div className="w-full animate-pulse flex flex-col justify-center p-4">
                  <div className="mt-12">
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-10 w-[40%] rounded-md bg-lstnBlueGray-100"
                    >
                      &nbsp;
                    </Typography>
                  </div>
                  <div className="mt-4">
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                    >
                      &nbsp;
                    </Typography>
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                    >
                      &nbsp;
                    </Typography>
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                    >
                      &nbsp;
                    </Typography>
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                    >
                      &nbsp;
                    </Typography>
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                    >
                      &nbsp;
                    </Typography>
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                    >
                      &nbsp;
                    </Typography>
                  </div>
                  <div className="mt-8">
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-10 w-[50%] rounded-md bg-lstnBlueGray-100"
                    >
                      &nbsp;
                    </Typography>
                  </div>
                  <div className="mt-4">
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                    >
                      &nbsp;
                    </Typography>
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-4 w-full rounded-full bg-lstnBlueGray-50"
                    >
                      &nbsp;
                    </Typography>
                  </div>
                </div>
              )}
            </>
          ) : (
            <NoAccessPage />
          )}
        </div>
      </div>

      <Dialog
        size="xs"
        open={openDeletionConfirmation}
        placeholder={undefined}
        handler={handleDeletionClose}
      >
        <DialogHeader placeholder={undefined}>Delete Content</DialogHeader>
        <DialogBody placeholder={undefined}>
          Are you sure you want to permanently delete?
        </DialogBody>
        <DialogFooter placeholder={undefined}>
          <Button
            variant="text"
            // color="blue-gray-900"
            onClick={handleDeletionClose}
            data-cy="data-project-delete-cancel"
            className="mr-1 uppercase"
          >
            <span>{t('renameDialog.cancel')}</span>
          </Button>
          <Button
            variant="text"
            color="red"
            onClick={handleDeletionConfirm(true)}
            data-cy="data-project-delete-submit"
            className="uppercase"
          >
            <span>{t('transcripts.delete')}</span>
          </Button>
        </DialogFooter>
      </Dialog>
      {/* </MuiThemeProvider> */}
    </main>
  )
}

export default ContentMarketingDetails
