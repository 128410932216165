import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
// import { connect } from "react-redux";
// import { withFirestore } from "react-redux-firebase";
// import { compose } from "recompose";
import { ProgressType, roleType } from '../../enums'
import { ITranscript, IUser } from '../../interfaces'
import TranscriptionEditor from '../TranscriptDetails/TranscriptionEditor'
import { useAppSelector } from '../../store/store'
interface IProps {
  transcript: ITranscript & { paragraphsForTranscript: string }
  transcriptId: string
  firestore: firebase.firestore.Firestore
  updateStatus: (status: ProgressType) => void
  userData?: IUser
  rolesValue?: {}
}

const EditorsRoleArrange = (props: IProps) => {
  const [rolesVal, setRoles] = useState<string[]>([])

  const { roles } = useAppSelector((store) => store.user)

  useEffect(() => {
    if (props.userData && props.transcript.userId === props.userData.id) {
      if (roles) {
        setRoles(roles['transcripts'][roleType.EDITOR])
      }
    } else if (
      props.userData &&
      props.transcript.sharedUserRoles &&
      props.transcript.sharedUserIds?.includes(props.userData.id!) &&
      props.transcript.sharedUserRoles[props.userData.id!] ===
        roleType.SHAREDEDIT
    ) {
      if (roles) {
        setRoles(roles['transcripts'][roleType.EDITOR])
      }
    } else {
      if (roles) {
        setRoles(roles['transcripts'][roleType.VIEWER])
      }
    }
  }, [props.transcript, props.transcript.sharedUserRoles])

  const checkRole = (role: string) => rolesVal.includes(role)
  //@ts-ignore
  return <TranscriptionEditor {...props} checkRole={checkRole} />
}

// const mapStateToProps = (state: ) => {
//   let rolesValue

//   if(state.firestore.data.roles)
//        rolesValue = state.firestore.data.roles[Object.keys(state.firestore.data.roles)[0]]
//     return {
//         rolesValue
//     }
//   }

// const enhance:  = compose(withFirestore)

export default EditorsRoleArrange
