import ReactGA from 'react-ga4'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../../css/SignUp.css'
import Typography from '../Typography/Typography'
import { Input } from '@material-tailwind/react'
import LanguageSelector from './Select'
import { Formik } from 'formik'
import * as Yup from 'yup'
import firebase, { database } from '../../firebaseApp'
import { Link } from 'react-router-dom'
// import { functions } from "../firebaseApp"
import { useNavigate } from 'react-router-dom'
import i18n from '../../i18n'
import Toast from '../Toast/Toast'
import { useTranslation } from 'react-i18next'
import { customEvent } from '../../utils/customHooks'
import Sidebar from './Sidebar'
import Button from '../Button/TailwindButton'
import SplashScreen from '../SplashScreen'

const trackingCode: string | undefined = process.env.REACT_APP_GA4_PROPERTY_ID

if (trackingCode) {
  ReactGA.initialize([
    {
      trackingId: trackingCode,
    },
  ])
}
type Language = 'en' | 'fr' | 'es'

const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const emailInputRef = useRef<HTMLInputElement | null>(null)
  const passwordInputRef = useRef<HTMLInputElement | null>(null)
  const SignUpFormSchema = Yup.object().shape({
    email: Yup.string().email().required('email is required'),
    username: Yup.string().required().min(2, 'username is required'),
    password: Yup.string()
      .required()
      .min(8, 'Your password has to have at least 8 character'),
  })
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.uid) {
        setLoading(true)
      }
    })
  }, [])
  const handleLanguageSelect = async (value: string) => {
    const ln = value || ''
    // setDisplayLanguage(ln )
    i18n.changeLanguage(ln as Language)
    localStorage.setItem('wellshared-display-lng', ln as Language)
  }

  const inviteId = localStorage.getItem('inviteId')

  const handleSign = useCallback(
    async (email: string, password: string, username: string) => {
      if (email && password && username) {
        console.log('username', username)
        // return
        setLoading(true)
        try {
          // const methods = await firebase
          //   .auth()
          //   .fetchSignInMethodsForEmail(email)
          // // If the array of methods is not empty, the email is already registered
          // if (methods && methods.length > 0) {
          //   Toast({
          //     title: t && t('auth.toast.title'),
          //     text: t && t('auth.toast.text'),
          //     variant: 'error',
          //   })
          //   setLoading(false)
          //   return
          // }
          customEvent('sign_up_requested', {
            category: 'auth',
            authAttribute: 'email_auth',
          })
          const cred = await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
          const user = cred.user
          if (user) {
            // Update user profile with the desired username
            await user.updateProfile({
              displayName: username,
            })

            // customEvent('sign_up', {
            //   category: 'auth',
            //   authAttribute: 'email_auth',
            //   user_userId: user.uid,
            // })
          }
          localStorage.setItem('ws-init-user', 'true')
          localStorage.setItem('ws-auth-provider', 'email_auth')
          if (!inviteId) {
            navigate('/create-account')
          }
        } catch (error: any) {
          console.log(error)
          if (error?.code === 'auth/email-already-in-use') {
            Toast({
              title: t && t('auth.toast.title'),
              text: t && t('auth.toast.text'),
              variant: 'error',
            })
          }
          setLoading(false)
        }
      }
    },
    [],
  )
  const handleSignGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    customEvent('sign_up_requested', {
      category: 'auth',
      authAttribute: 'google_auth',
    })
    try {
      const result = await firebase.auth().signInWithPopup(provider)
      const user = result.user
      if (user) {
        customEvent('sign_up', {
          category: 'auth',
          authAttribute: 'google_auth',
          user_userId: user.uid,
        })
        const querySnapshot = await database
          .collection('users')
          .where('email', '==', user.email)
          .get()

        if (!querySnapshot.empty) {
          console.log('User already exists', querySnapshot.docs[0].data().email)
        } else {
          localStorage.setItem('ws-init-user', 'true')
          localStorage.setItem('ws-auth-provider', 'google.com')
          if (!inviteId) {
            navigate('/create-account')
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _handleEvent = useCallback(
    (url: string) => () => {
      customEvent('link_opened', {
        category: 'misc',
        url,
      })
    },
    [],
  )

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <div className="flex ">
          <div className="hidden h-screen bg-gradient-to-t from-[#F2DCFC] to-[#F3F8FF] md:flex lg:w-1/3 md:w-2/5 flex-col justify-between">
            <Sidebar />
          </div>
          <div className="w-full pt-20 lg:w-3/4 md:w-3/5 p-6 h-auto md:pt-4 lg:pt-4 xl:pt-4 flex justify-center items-center relative ">
            <div className="absolute right-10 top-8">
              <LanguageSelector onSelect={handleLanguageSelect} />
            </div>
            <div className="md:w-1/2 mx-auto sm:w-96 lg:w-2/5 xl:w-2/5 md:h-auto ">
              <Typography className="2xl:p-2 text-center text-3xl font-semibold text-lstnBlueGray-900">
                {t && t('auth.signUp')}
              </Typography>
              <Typography className="p-2 2xl:py-4 text-center text-base font-normal text-lstnBlueGray-700">
                {t && t('auth.signUpContent')}
              </Typography>
              <div className="flex flex-col items-center gap-1 2xl:gap-3">
                {/* @ts-ignore */}
                <Button
                  color="white"
                  className="!border !border-blue-gray-50 bg-white flex items-center text-sm font-bold text-lstnBlueGray-900 gap-3 w-full justify-center my-2"
                  onClick={handleSignGoogle}
                >
                  <img
                    src="https://docs.material-tailwind.com/icons/google.svg"
                    alt="metamask"
                    className="h-4 w-4"
                  />
                  {t && t('auth.signUPwithGoogle')}
                </Button>
                {t('auth.or')}
              </div>
              <Formik
                initialValues={{ email: '', password: '', username: '' }}
                onSubmit={(values) =>
                  handleSign(values.email, values.password, values.username)
                }
                validationSchema={SignUpFormSchema}
                validateOnMount={true}
              >
                {({
                  values,
                  errors,
                  // isValid,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div className="2xl:mt-2 mb-2 w-full max-w-screen-lg ">
                    <div className="mb-1 flex flex-col gap-4">
                      <div>
                        <Typography
                          variant="h6"
                          className="mb-2 text-sm font-normal text-lstnBlueGray-900"
                        >
                          {t && t('auth.yourName')}
                        </Typography>
                        <Input
                          size="lg"
                          className=" !border-lstnBlueGray-100 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
                          placeholder={t && t('auth.yourNamePlaceholder')}
                          autoCapitalize="none"
                          labelProps={{
                            className: 'hidden',
                          }}
                          type="username"
                          id="username"
                          onChange={handleChange('username')}
                          onBlur={handleBlur('username')}
                          value={values.username}
                          crossOrigin={undefined}
                          onKeyPress={(
                            event: React.KeyboardEvent<HTMLInputElement>,
                          ) => {
                            if (event.key === 'Enter') {
                              event.preventDefault()
                              if (
                                emailInputRef.current != null &&
                                emailInputRef.current.children[0] != null
                              ) {
                                ;(
                                  emailInputRef.current
                                    .children[0] as HTMLInputElement
                                ).focus()
                              }
                            }
                          }}
                        />
                        {touched.username && errors.username && (
                          <Typography
                            color="gray"
                            className="mt-2 flex items-center gap-1 text-xs font-normal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="-mt-px h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {errors.username}
                          </Typography>
                        )}
                      </div>
                      <div>
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 text-sm font-normal text-lstnBlueGray-900"
                        >
                          {t && t('auth.yourEmail')}
                        </Typography>
                        <Input
                          size="lg"
                          placeholder="jondoe@example.com"
                          className=" !border-lstnBlueGray-100 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
                          autoCapitalize="none"
                          labelProps={{
                            className: 'hidden',
                          }}
                          type="email"
                          id="email"
                          ref={emailInputRef}
                          onChange={handleChange('email')}
                          onBlur={handleBlur('email')}
                          value={values.email}
                          crossOrigin={undefined}
                          onKeyPress={(
                            event: React.KeyboardEvent<HTMLInputElement>,
                          ) => {
                            if (event.key === 'Enter') {
                              event.preventDefault()
                              if (
                                passwordInputRef.current != null &&
                                passwordInputRef.current.children[0] != null
                              ) {
                                ;(
                                  passwordInputRef.current
                                    .children[0] as HTMLInputElement
                                ).focus()
                              }
                            }
                          }}
                        />
                        {touched.email && errors.email && (
                          <Typography
                            color="gray"
                            className="mt-2 flex items-center gap-1 text-xs font-normal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="-mt-px h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {errors.email}
                          </Typography>
                        )}
                      </div>
                      <div>
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 text-sm font-normal text-lstnBlueGray-900"
                        >
                          {t && t('auth.password')}
                        </Typography>
                        <Input
                          size="lg"
                          placeholder={t && t('auth.passwordPlaceholder')}
                          className=" !border-lstnBlueGray-100 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
                          autoCapitalize="none"
                          labelProps={{
                            className: 'hidden',
                          }}
                          type="password"
                          id="password"
                          ref={passwordInputRef}
                          onChange={handleChange('password')}
                          onBlur={handleBlur('password')}
                          value={values.password}
                          crossOrigin={undefined}
                          onKeyPress={(
                            event: React.KeyboardEvent<HTMLInputElement>,
                          ) => {
                            if (event.key === 'Enter') {
                              event.preventDefault()
                              handleSubmit()
                            }
                          }}
                        />
                        {touched.password && errors.password && (
                          <Typography
                            color="gray"
                            className="mt-2 flex items-center gap-1 text-xs font-normal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="-mt-px h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {errors.password}
                          </Typography>
                        )}
                      </div>
                    </div>
                    <Button
                      size="lg"
                      className="mt-10 bg-green text-sm font-bold"
                      fullWidth
                      onClick={() => handleSubmit()}
                    >
                      {t && t('auth.signUp')}
                    </Button>
                    <Typography
                      variant="paragraph"
                      className="text-center mt-6 text-base font-normal text-lstnBlueGray-700"
                    >
                      {t && t('auth.linkContent')}
                      <a
                        onClick={_handleEvent('https://wellshared.ai/terms/')}
                        href="https://wellshared.ai/terms/"
                        target="_blank"
                        className="underline cursor-pointer hover:text-blue-gray-900"
                        rel="noreferrer"
                      >
                        {t && t('auth.termsAndConditions')}
                      </a>{' '}
                      {t && t('auth.and')}{' '}
                      <a
                        onClick={_handleEvent(
                          'https://wellshared.ai/privacy-policy/',
                        )}
                        href="https://wellshared.ai/privacy-policy/"
                        target="_blank"
                        className="underline cursor-pointer hover:text-blue-gray-900"
                        rel="noreferrer"
                      >
                        {t && t('auth.privacyPolicy')}
                      </a>
                      .
                    </Typography>

                    <Typography
                      color="gray"
                      className="mt-6 text-center font-normal"
                    >
                      {t && t('auth.alreadyAccount')}{' '}
                      <Link
                        to="/login"
                        className="font-medium text-gray-900 hover:text-blue-gray-900"
                      >
                        {t('auth.signIn')}
                      </Link>
                    </Typography>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SignUp
