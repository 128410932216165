import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Textarea,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'

interface IProps {
  open: boolean
  handleOpen: () => void
  dialogType: string
  handleCreate?: (name: string, description?: string) => void
  handleEdit?: (id: string, name: string, description?: string) => void
  projectId?: string
  name?: string
  description?: string
}

export default function CreateProjectDialog({
  open,
  handleOpen,
  dialogType,
  handleCreate,
  handleEdit,
  projectId,
  name,
  description,
}: IProps): React.JSX.Element {
  const { t } = useTranslation()
  const [title, setTitle] = useState<string>('')
  const [desc, setDesc] = useState<string>('')

  useEffect(() => {
    if (name) {
      setTitle(name)
    }
    if (description) {
      setDesc(description)
    }
  }, [description, name])

  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value)
  }

  const handleDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setDesc(event.target.value)
  }

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      placeholder={undefined}
      size="md"
      className="p-4"
    >
      <DialogHeader
        id="alert-dialog-slide-title"
        className="py-2 px-4 flex flex-col items-start"
        placeholder={undefined}
      >
        {dialogType === 'create' ? (
          <Typography className="text-2xl font-semibold text-blue-gray-900">
            {t('createProject.create')}
          </Typography>
        ) : dialogType === 'edit' ? (
          <Typography className="text-2xl font-semibold text-blue-gray-900">
            {t('createProject.editBtn')}
          </Typography>
        ) : null}
        <Typography className="text-lg font-normal text-blue-gray-500">
          {t('createProject.subHeading')}
        </Typography>
      </DialogHeader>
      <DialogBody placeholder={undefined}>
        <Typography
          variant="h6"
          className="mb-2 text-sm font-medium text-lstnBlueGray-400"
        >
          {t && t('createProject.title')}
        </Typography>
        <Input
          autoFocus
          size="lg"
          value={title}
          placeholder={t('createProject.titlePlaceholder')}
          className=" !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100"
          labelProps={{
            className: 'hidden',
          }}
          style={{ borderRadius: '8px' }}
          onChange={handleTitle}
          crossOrigin={undefined}
        />
        <Typography
          variant="h6"
          className="my-2 text-sm font-medium text-lstnBlueGray-400"
        >
          {t && t('createProject.description')}
        </Typography>
        <Textarea
          autoFocus
          value={desc}
          placeholder={t('createProject.descriptionPlaceholder')}
          className=" !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100"
          labelProps={{
            className: 'hidden',
          }}
          style={{ borderRadius: '8px' }}
          onChange={handleDescription}
        />
      </DialogBody>
      <DialogFooter placeholder={undefined} className="gap-0.5">
        <Button
          onClick={handleOpen}
          variant="text"
          className="text-xs font-bold uppercase hover:bg-transparent"
          placeholder={undefined}
        >
          {t('createProject.cancel')}
        </Button>
        {dialogType === 'create' ? (
          <Button
            onClick={() => handleCreate && handleCreate(title, desc)}
            className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
            placeholder={undefined}
            variant="filled"
            disabled={!title}
          >
            {t('createProject.createBtn')}
          </Button>
        ) : dialogType === 'edit' ? (
          <Button
            onClick={() => handleEdit && handleEdit(projectId!, title, desc)}
            className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
            placeholder={undefined}
            variant="filled"
            disabled={!title || (title === name && desc === description)}
          >
            {t('createProject.editBtn')}
          </Button>
        ) : null}
      </DialogFooter>
    </Dialog>
  )
}
