import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  setError,
  setNestedAllFolders,
  setNestedAllSharedFolders,
  setNestedAllSharedTranscripts,
  setNestedAllTranscripts,
  setTranscript,
} from './transcriptsSlice'
import { database } from '../../../firebaseApp'
import firebase from 'firebase/compat/app'
import { AppDispatch } from '../../store'
import { IFolders, ITranscript } from '../../../interfaces'
import { setSelectedContent } from '../contentSlice'

interface IFetchParams {
  id: string
  folderData: IFolders | null
  role: {
    [key: string]: {
      [key: string]: Array<string>
    }
  }
  folders: IFolders[]
  folderShared: IFolders[]
  transcript: ITranscript[]
  transcriptShared: ITranscript[]
  currentAccountID: string
  currentProjectID: string
}

export const fetchallFolders = createAsyncThunk(
  'fetchallFolders',
  async (data: IFolders[], { dispatch }) => {
    try {
      dispatch(setNestedAllFolders(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchallSharedFolders = createAsyncThunk(
  'fetchallSharedFolders',
  async (data: IFolders[], { dispatch }) => {
    try {
      dispatch(setNestedAllSharedFolders(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchAllTranscript = createAsyncThunk(
  'fetchAllTranscripts',
  async (data: ITranscript[], { dispatch }) => {
    try {
      dispatch(setNestedAllTranscripts(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const fetchallSharedTranscripts = createAsyncThunk(
  'fetchallSharedTranscripts',
  async (data: ITranscript[], { dispatch }) => {
    try {
      dispatch(setNestedAllSharedTranscripts(data))
    } catch (error) {
      console.log(error)
    }
  },
)

// @ts-ignore
export const fetchTranscriptSubs = async ({ dispatch }) => {
  try {
    const allFoldersQuery = database
      .collection('folders')
      .where('userId', '==', firebase.auth().currentUser?.uid)
    const allSharedFoldersQuery = database
      .collection('folders')
      .where(
        'sharedUserIds',
        'array-contains',
        firebase.auth().currentUser?.uid,
      )
    const allTranscriptsQuery = database
      .collection('transcripts')
      .where('userId', '==', firebase.auth().currentUser?.uid)
      .orderBy('createdAt', 'desc')
    const allSharedTranscriptsQuery = database
      .collection('transcripts')
      .where(
        'sharedUserIds',
        'array-contains',
        firebase.auth().currentUser?.uid,
      )

    const transcriptsUnsubscribe = allTranscriptsQuery.onSnapshot(
      (transcriptsSnapshot) => {
        const allTranscripts = transcriptsSnapshot.docs.map((doc) => doc.data())
        dispatch(fetchAllTranscript(allTranscripts))
      },
    )

    const sharedTranscriptsUnsubscribe = allSharedTranscriptsQuery.onSnapshot(
      (sharedTranscriptsSnapshot) => {
        const allSharedTranscripts = sharedTranscriptsSnapshot.docs.map((doc) =>
          doc.data(),
        )
        dispatch(fetchallSharedTranscripts(allSharedTranscripts))
      },
    )
    const foldersUnsubscribe = allFoldersQuery.onSnapshot((foldersSnapshot) => {
      const allFolders = foldersSnapshot.docs.map((doc) => doc.data())
      dispatch(fetchallFolders(allFolders as IFolders[]))
    })

    const sharedFoldersUnsubscribe = allSharedFoldersQuery.onSnapshot(
      (sharedFoldersSnapshot) => {
        const allSharedFolders = sharedFoldersSnapshot.docs.map((doc) =>
          doc.data(),
        )
        dispatch(fetchallSharedFolders(allSharedFolders as IFolders[]))
      },
    )

    return () => {
      foldersUnsubscribe()
      sharedFoldersUnsubscribe()
      transcriptsUnsubscribe()
      sharedTranscriptsUnsubscribe()
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const fetchTranscriptFolder = createAsyncThunk(
  'fetchTranscriptFolder',
  async ({
    id,
    folderData,
    role,
    folders,
    folderShared,
    transcript,
    transcriptShared,
    currentAccountID,
    currentProjectID,
  }: IFetchParams): Promise<any> => {
    try {
      const filterFolders = await folders.filter(
        (folder: IFolders) =>
          folder.parentFolderId == (folderData ? folderData.id : '') &&
          folder.projectId == (currentProjectID ? currentProjectID : '') &&
          folder.accountId == currentAccountID,
      )
      const filterfolderShared = await folderShared.filter(
        (folder: IFolders) =>
          folder.parentFolderId == (folderData ? folderData.id : '') &&
          folder.projectId == (currentProjectID ? currentProjectID : ''),
        // &&
        // folder.accountId == currentAccountID,
      )
      const filtertranscript = await transcript.filter(
        (transcript: ITranscript) =>
          transcript.folderId == (folderData ? folderData.id : '') &&
          transcript.projectId == (currentProjectID ? currentProjectID : '') &&
          transcript.accountId == currentAccountID,
      )
      const filterTranscriptShared = await transcriptShared.filter(
        (transcript: ITranscript) =>
          transcript.folderId == (folderData ? folderData.id : '') &&
          transcript.projectId == (currentProjectID ? currentProjectID : ''),
        // &&
        // transcript.accountId == currentAccountID,
      )

      return {
        id: id,
        folderData: folderData ? folderData : undefined,
        roles: role,
        folders: JSON.stringify(filterFolders),
        foldersShareUserId: JSON.stringify(filterfolderShared),
        transcriptList: JSON.stringify(filtertranscript),
        transcriptListShareUserId: JSON.stringify(filterTranscriptShared),
      }
    } catch (error) {
      console.error(error)
    }
  },
)

export const fetchTransctipt =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      const TransctiptQuery = database.collection('transcripts').doc(id)
      TransctiptQuery.onSnapshot((snapshot) => {
        const transcript = snapshot.data()
        dispatch(setTranscript(transcript))
      })
    } catch (error) {
      console.log(error)
      dispatch(setError(error))
    }
  }

export const fetchContentDoc =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      const contentQuery = database.collection('content').doc(id)
      contentQuery.onSnapshot((snapshot) => {
        const content = snapshot.data()
        dispatch(setSelectedContent({ ...content, id: snapshot.id }))
      })
    } catch (error) {
      console.log(error)
      dispatch(setError(error))
    }
  }
