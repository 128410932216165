import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
// import { AppDispatch } from '../store'
import { IContent } from '../../interfaces'

export const fetchContent = createAsyncThunk(
  'mentions/fetchContent',
  async (data: { projectId: string }, thunkAPI) => {
    console.log('Data fetch content', data.projectId)
    const { projectId } = data
    const { dispatch } = thunkAPI

    try {
      if (projectId && projectId.length > 0) {
        // const allContent: IContent[] = [];

        const allContentQuery = database
          .collection('content')
          .where('projectId', '==', projectId)
          .orderBy('createdAt', 'desc')

        allContentQuery.onSnapshot(
          (querySnapshot) => {
            if (!querySnapshot.empty) {
              const updatedContent: IContent[] = []
              querySnapshot.forEach((doc) => {
                const contentData = doc.data() as IContent
                if (!contentData?.archive) {
                  updatedContent.push({ ...contentData, id: doc.id })
                }
              })
              dispatch(setAllContent(updatedContent))
            } else {
              console.log('No documents found for the provided projectId')
              dispatch(setAllContent([]))
            }
          },
          (error) => {
            console.error('Error getting documents: ', error)
          },
        )
      } else if (projectId.length === 0) {
        dispatch(setAllContent([]))
      }
    } catch (error) {
      console.error('Error fetching content:', error)
      throw error
    }
  },
)

export const addContent = createAsyncThunk('addContent', async (data: any) => {
  try {
    const docRef = await database.collection('content').add(data)
    const contentId = docRef.id
    return contentId
  } catch (error) {
    console.log(error)
  }
})

interface IContentState {
  allContent: IContent[]
  selectedContent: IContent
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
  allContentLoading: 'idle' | 'pending' | 'succeeded' | 'failed'
  allContentError: string | null | undefined
}

const initialState: IContentState = {
  allContent: [],
  selectedContent: {
    id: '',
    platform: '',
    title: '',
    description: '',
    updatedAt: undefined,
    createdAt: undefined,
    projectId: '',
    contentGenerated: {},
    status: '',
    readabilityScore: '',
    keywords: [],
    slug: '',
    summary: '',
  },
  loading: 'idle',
  error: null,
  allContentLoading: 'idle',
  allContentError: null,
}

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setAllContent: (state, action) => {
      state.allContent = action.payload
    },
    setSelectedContent: (state, action) => {
      state.selectedContent = action.payload
    },
    setAllContentLoading: (state, action) => {
      state.allContentError = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContent.pending, (state) => {
      console.log('pending')
      state.allContentLoading = 'pending'
    })
    builder.addCase(fetchContent.fulfilled, (state) => {
      console.log('succeeded')
      state.allContentLoading = 'succeeded'
    })
    builder.addCase(fetchContent.rejected, (state, action) => {
      console.log('failed')
      state.error = 'failed'
      state.error = action.error.message
      state.allContentLoading = 'failed'
    })
  },
})

export const { setAllContent, setAllContentLoading, setSelectedContent } =
  contentSlice.actions
export default contentSlice.reducer
