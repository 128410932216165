import React from 'react'
import Tour, { ReactourStep } from 'reactour'
import { useTranslation } from 'react-i18next'
import '../../css/OnBoarding.css'

interface IProps {
  openTour: boolean
  closeTour: () => void
}

export default function TourGuide({ closeTour, openTour }: IProps) {
  const { t } = useTranslation()

  const steps: ReactourStep[] = [
    {
      selector: '[data-tut="Paragraph-tour"]',
      content: () => {
        return (
          <div>
            <div className="header-text-tour">
              {t('transcriptDetailsTour.step1')}
            </div>
            <div className="tour-step">
              {t('transcriptDetailsTour.bodyStep1')}
            </div>
          </div>
        )
      },
      position: 'top',
      style: {
        display: 'grid',
      },
    },
    {
      selector: '[data-tut="Export-tour"]',
      content: () => {
        return (
          <div>
            <div className="header-text-tour">
              {t('transcriptDetailsTour.step4')}
            </div>
            <div className="tour-step">
              {t('transcriptDetailsTour.bodyStep4')}
            </div>
          </div>
        )
      },
      style: {
        display: 'grid',
      },
    },
  ]

  return (
    <Tour
      steps={steps}
      isOpen={openTour}
      onRequestClose={closeTour}
      // nextButton={<button className="next-btn-tour">Next</button>}
      // prevButton={<button className="back-btn-tour">Back</button>}
      lastStepNextButton={
        <button className="next-btn-tour">
          {t('transcriptDetailsTour.done')}
        </button>
      }
      className="tour-box"
      accentColor={'rgb(37,218,60)'}
      badgeContent={(curr, tot) => `${curr} of ${tot}`}
      // showNumber={false}
      scrollOffset={-500}
    />
  )
}
