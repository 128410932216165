import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IUserStatus } from '../../interfaces/IUser'
import { database } from '../../firebaseApp'
import { IOrg, IUser } from '../../interfaces'
import firebase from 'firebase/compat/app'
import { AppDispatch } from '../store'

interface UsersData {
  name: string
  gaClientId: string
  email: string
  id: string
  features: {
    discoveryExport?: boolean
    translation?: boolean
    translationDeepL?: boolean
  }
  createdAt: string // You might want to use a Date type instead of string if you plan to parse this date
  onboardingSteps: {
    transcriptionEdit?: boolean
    uploadVideos?: boolean
  }
}

interface UserStatusState {
  fetchStatusProgress: boolean
  fetchStatusSuccess: boolean
  fetchStatusFailure: boolean
  userStatus?: IUserStatus
  user: firebase.User
  users: UsersData | {}
  userData: IUser
  organizations: {}
  roles: {}
  Enterprise?: boolean | undefined
  error?: null | Error | string
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  showUpgradeModal: boolean
}

const initialState: UserStatusState = {
  fetchStatusProgress: false,
  fetchStatusSuccess: false,
  fetchStatusFailure: false,
  user: {} as firebase.User,
  users: {},
  userData: {} as IUser,
  organizations: {} as IOrg,
  roles: {} as { [key: string]: string },
  error: '',
  Enterprise: false,
  loading: 'idle',
  showUpgradeModal: false,
}

const userStatusSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserStatus: (state) => {
      ;(state.fetchStatusProgress = false),
        (state.fetchStatusSuccess = false),
        (state.fetchStatusFailure = false),
        (state.user = {} as firebase.User),
        (state.users = {}),
        (state.userData = {} as IUser),
        (state.organizations = {}),
        (state.roles = {}),
        (state.error = ''),
        (state.Enterprise = false),
        (state.loading = 'idle')
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload
    },
    setuserData: (state, action) => {
      state.userData = action.payload
      if (action.payload && action.payload.isEnterprise) {
        state.Enterprise = action.payload.isEnterprise
      } else {
        state.Enterprise = undefined
      }
    },
    setroles: (state, action) => {
      state.roles = action.payload
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setUsers: (state, action) => {
      state.users = action.payload
    },
    setShowUpgradeModal: (state, action) => {
      state.showUpgradeModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserById.pending, (state) => {
        state.fetchStatusProgress = true
        state.fetchStatusSuccess = false
        state.fetchStatusFailure = false
        state.loading = 'pending'
        state.error = null
      })
      .addCase(fetchUserById.fulfilled, (state) => {
        state.fetchStatusProgress = false
        state.fetchStatusSuccess = true
        state.fetchStatusFailure = false
        state.loading = 'succeeded'
        state.error = null
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.fetchStatusProgress = false
        state.fetchStatusSuccess = false
        state.fetchStatusFailure = true
        state.loading = 'failed'
        state.error = action.error.message
      })
  },
})

export const {
  resetUserStatus,
  setUserStatus,
  setuserData,
  setroles,
  setOrganizations,
  setUser,
  setUsers,
  setShowUpgradeModal,
} = userStatusSlice.actions

export default userStatusSlice.reducer

//------------------userStatus------------//

export const fetchUserById = createAsyncThunk(
  'user/fetchUserById',
  async (userId: string, { dispatch }) => {
    try {
      // const rolesQuery = database.collection('roles')
      // const rolesSnapShot = await rolesQuery.get()
      // const roles = rolesSnapShot.docs.map((doc) => doc.data())
      // dispatch(setroles(roles[0]))

      // const usersQuery = database.collection('users') // 'userId' is the document ID
      // const usersSnapShot = await usersQuery.get()
      // const usersData = {}
      // usersSnapShot.docs.forEach((doc) => {
      //   usersData[doc.id] = doc.data()
      // })
      // dispatch(setUsers(usersData))

      const auth = firebase.auth()
      auth.onAuthStateChanged((user) => {
        if (user) {
          const data = JSON.parse(JSON.stringify(user))
          dispatch(setUser(data))
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
)

const fetchuserData = createAsyncThunk(
  'userData',
  async (data: IUser, { dispatch }) => {
    try {
      dispatch(setuserData(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const helperUserData = ({ dispatch }: { dispatch: AppDispatch }) => {
  const userId = firebase.auth().currentUser?.uid
  const userDataQuery = database.collection('users').doc(userId)
  // Subscribe to real-time updates
  const unsubscribe = userDataQuery.onSnapshot((userDataSnapShot) => {
    if (userDataSnapShot.exists) {
      const userData = userDataSnapShot.data()
      const userDataWithId = { ...userData, id: userDataSnapShot.id }
      // Dispatch the updated data to your Redux store
      dispatch(fetchuserData(userDataWithId as IUser))
    }
  })
  // Cleanup the subscription when the component unmounts
  return () => unsubscribe()
}
