import React from 'react'
import Tour from 'reactour'
import { useTranslation } from 'react-i18next'
import '../../css/OnBoarding.css'

interface IProps {
  openTourGuide: boolean
  handleCloseTour: () => void
}

export default function TourGuide({ handleCloseTour, openTourGuide }: IProps) {
  const { t } = useTranslation()

  const steps = [
    {
      selector: '[data-tut="DropZone"]',
      content: () => {
        return (
          <div>
            <div className="header-text-tour">
              {' '}
              {t('uploadScreenTour.step1')}
            </div>
            <div className="tour-step">{t('uploadScreenTour.bodyStep1')}</div>
          </div>
        )
      },
    },
    {
      selector: '[data-tut="tour-table"]',
      content: () => {
        return (
          <div>
            <div className="header-text-tour">
              {' '}
              {t('uploadScreenTour.step2')}
            </div>
            <div className="tour-step">{t('uploadScreenTour.bodyStep2')}</div>
          </div>
        )
      },
    },
    {
      selector: '.gist-messenger-bubble-iframe iframe',
      content: () => {
        return (
          <div>
            <div className="header-text-tour">
              {t('uploadScreenTour.step3')}
            </div>
            <div className="tour-step">{t('uploadScreenTour.bodyStep3')}</div>
          </div>
        )
      },
    },
  ]

  return (
    <Tour
      steps={steps}
      isOpen={openTourGuide}
      onRequestClose={handleCloseTour}
      // nextButton={<button className="next-btn-tour">Next</button>}
      // prevButton={<button className="back-btn-tour">Back</button>}
      lastStepNextButton={
        <button className="next-btn-tour">{t('uploadScreenTour.done')}</button>
      }
      className="tour-box"
      accentColor={'rgb(37,218,60)'}
      badgeContent={(curr, tot) => `${curr} of ${tot}`}
      // showNumber={false}
    />
  )
}
