import React, { useCallback, useEffect, useState } from 'react'
import {
  Input,
  Button,
  Textarea,
  Dialog,
  DialogBody,
  DialogFooter,
  Select,
  Option,
} from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase/compat/app'

import Typography from '../components/Typography/Typography'
import SocialTabs from '../components/SocialReplies/SocialTabs'

import Toast from '../components/Toast/Toast'
import NotifyCard from '../components/NotifyCard'

import { useAppDispatch, useAppSelector } from '../store/store'
import { addContent } from '../store/features/contentSlice'

import contentMarketingGif from '../icons/content-marketing.gif'

import ContentIdeas from '../components/Tabs/ContentMarketing/ContentIdeas'
import {
  fetchContentIdeas,
  subscribeToContentIdeas,
} from '../store/features/contentIdeaSlice'
import { generateContentIdeas } from '../utils/generateContentIdeas'
import ContentCreated from '../components/Tabs/ContentMarketing/ContentCreated'

const ContentMarketing: React.FC = () => {
  const [activeTab, setActiveTab] = useState('content-ideas')
  const [platformFilter, setPlatformFilter] = useState('blog')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isContentSubmitting, setIsContentSubmitting] = useState(false)
  const [title, setTitle] = useState<string>('')
  const [desc, setDesc] = useState<string>('')
  const [isLoadingIdeas, setIsLoadingIdeas] = useState(false)

  const { currentProject } = useAppSelector((store) => store.project)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { contentIdeas } = useAppSelector((store) => store.contentIdeas)
  const { user } = useAppSelector((store) => store.user)

  console.log('useruser', user?.uid)

  const contentMarketingBetaTesting =
    currentAccount?.features?.contentMarketingBetaTesting

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const platformOptions = [{ label: 'Blog', value: 'blog' }]

  const handlePlatformChange = (value: string | undefined): void => {
    if (value) {
      console.log('^^^ value', value)
      setPlatformFilter(value)
    }
  }

  const handleOpen = (): void => {
    setIsModalOpen(!isModalOpen)
  }

  const handleSave = useCallback(async () => {
    setIsContentSubmitting(true)
    try {
      const data = {
        title,
        description: desc,
        projectId: currentProject?.id,
        platform: platformFilter,
        status: 'writing',
        contentLength: '1000-2000',
        toneOfVoice: 'professional',
        userId: user?.uid,
        createdAt: firebase.firestore.Timestamp.now(),
      }
      if (
        !currentAccount.remainingCredits ||
        parseInt(currentAccount.remainingCredits, 10) < 5
      ) {
        setIsModalOpen(false)
        setIsContentSubmitting(false)
        Toast({
          title: 'Insufficient Credits',
          text: 'You do not have enough credits to create content. Please upgrade your plan.',
          variant: 'error',
          options: { autoClose: 4000 },
        })
        return
      }
      setIsModalOpen(false)
      setIsContentSubmitting(false)
      Toast({
        title: 'Content will be created shortly',
        text: 'Content will be created shortly. Please wait...',
        variant: 'success',
        options: { autoClose: 4000 },
      })
      await dispatch(addContent(data))
    } catch (err) {
      console.log('_error saving content', err)
      Toast({
        title: 'Content Error',
        text: 'Error occured while creating content',
        variant: 'error',
        options: { autoClose: 4000 },
      })
    }
  }, [title, desc, platformFilter, currentProject])

  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value)
  }

  const handleDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setDesc(event.target.value)
  }

  const data = [
    {
      label: t && t('contentMarketing.contentIdeas.name'),
      value: 'content-ideas',
      desc: (
        <>
          <ContentIdeas
            handleSave={handleSave}
            projectId={currentProject.id}
            isLoadingIdeas={isLoadingIdeas}
            accountId={currentProject.accountId}
          />
        </>
      ),
    },

    {
      label: 'Content Created',
      value: 'content-created',
      desc: (
        <>
          <ContentCreated />
        </>
      ),
    },
  ]

  const handleContentIdeas = useCallback(async (): Promise<void> => {
    if (contentIdeas.length === 0) {
      await dispatch(fetchContentIdeas(currentProject.id)).then(async (res) => {
        const result = res.payload
        if (result && (result as any[]).length === 0) {
          setIsLoadingIdeas(true)
          console.log('is empty')
          const data = {
            accountId: currentProject.accountId,
            projectId: currentProject.id,
          }
          const result = await generateContentIdeas(data)
          if (result === 'Ideas generated successfully.') {
            subscribeToContentIdeas(currentProject.id)(dispatch)
            setIsLoadingIdeas(false)
            return
          } else {
            setIsLoadingIdeas(false)
            return
          }
        }
      })
    } else {
      subscribeToContentIdeas(currentProject.id)(dispatch)
    }
  }, [
    contentIdeas.length,
    currentProject.accountId,
    currentProject.id,
    dispatch,
  ])

  useEffect(() => {
    handleContentIdeas()
  }, [handleContentIdeas])

  return (
    <>
      {contentMarketingBetaTesting ? (
        <div className="flex flex-col mt-6 md:container md:mx-auto py-4 px-8 sm:overflow-x-scroll md:overflow-hidden">
          <>
            <div className="w-full flex justify-between">
              <Typography className="text-2xl font-medium text-lstnGray-900 my-5">
                Content Marketing
              </Typography>
              <Button
                className="h-[36px] bg-lstnGreen-500"
                size="sm"
                ripple={false}
                onClick={handleOpen}
                placeholder="Create Content"
              >
                + CREATE CONTENT
              </Button>
            </div>

            <SocialTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data}
            />
          </>
        </div>
      ) : (
        <div className="flex justify-center align-center items-center rounded-md h-[70vh]">
          <NotifyCard
            gif={contentMarketingGif}
            classes="h-[12em] w-[20em] lg:h-[25em] lg:w-[60em]"
            title={t && t('contentMarketing.notifyTitle')}
            subtitle={t && t('contentMarketing.notifySubtitle')}
          />
        </div>
      )}

      <Dialog
        open={isModalOpen}
        handler={handleOpen}
        placeholder={undefined}
        className="p-4"
        size="sm"
      >
        <DialogBody placeholder={undefined}>
          <Typography className="text-2xl font-medium text-lstnGray-900 mt-5 mb-3">
            Create new content
          </Typography>
          <Typography className="text-sm text-wellBlueGray-500 font-normal mt-0 pt-0">
            Easily write new blog posts, review and publish them in minutes.
            Creating an article consumes 5 credits.
          </Typography>
          <Typography
            variant="h6"
            className="mt-6 mb-3 text-sm font-medium text-lstnBlueGray-400"
          >
            {t && t('createProject.title')}
          </Typography>
          <Input
            autoFocus
            size="lg"
            value={title}
            placeholder="Name"
            className=" !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100"
            labelProps={{
              className: 'hidden',
            }}
            style={{ borderRadius: '8px' }}
            onChange={handleTitle}
            crossOrigin={undefined}
          />
          <Typography
            variant="h6"
            className="mt-6 mb-3 text-sm font-medium text-lstnBlueGray-400"
          >
            {t && t('createProject.description')}
          </Typography>
          <Textarea
            autoFocus
            value={desc}
            placeholder="Describe content details."
            className=" !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100"
            labelProps={{
              className: 'hidden',
            }}
            style={{ borderRadius: '8px' }}
            onChange={handleDescription}
          />
          <Typography
            variant="h6"
            className="mt-6 mb-3 text-sm font-medium text-lstnBlueGray-400"
          >
            Platform
          </Typography>
          <Select
            id="select"
            onChange={handlePlatformChange}
            animate={{
              mount: { y: 0 },
              unmount: { y: 25 },
            }}
            className={`border border-lstnBlueGray-600 h-[2.46rem] top-[-1px]`}
            value={platformFilter}
            labelProps={{
              className: 'hidden',
            }}
            placeholder={platformFilter}
            label={platformFilter}
            arrow={undefined}
          >
            {platformFilter &&
              platformOptions &&
              platformOptions.map((option) => (
                <Option
                  key={option.value}
                  className="z-20 flex items-center justify-between capitalize"
                  value={option.value}
                  data-cy={`data-project-export-file-type-${option.value}`}
                >
                  {option.label}
                </Option>
              ))}
          </Select>
        </DialogBody>
        <DialogFooter placeholder={undefined}>
          <Button
            placeholder={undefined}
            variant="text"
            color="black"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="h-[36px] bg-lstnGreen-500"
            size="sm"
            ripple={false}
            onClick={handleSave}
            placeholder="Create Content"
            disabled={title.length && desc.length ? false : true}
            loading={isContentSubmitting}
          >
            Write
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default ContentMarketing
