import { Button, Card, Input } from '@material-tailwind/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Selector } from '../../SocialReplies/Select'
import CustomTooltip from '../../Tooltip/Tooltip'
import Typography from '../../Typography/Typography'
import SearchIcon from '../../../icons/search.svg'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { useTranslation } from 'react-i18next'
import CachedIcon from '@material-ui/icons/Cached'
import { SparklesIcon, FilterBarsIcon } from '../../SvgIcons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
  IContentIdea,
  contentIdeaGenerated,
  hideContentIdea,
  subscribeToContentIdeas,
} from '../../../store/features/contentIdeaSlice'
import CustomMenu from '../../CustomMenu'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import Toast from '../../Toast/Toast'
import { filterOptions, isSameDay } from '../../../utils/dateFiltersConstant'
import { generateContentIdeas } from '../../../utils/generateContentIdeas'
import Lottie from 'lottie-react'
import waiting from '../../../lottie/loading-ideas-lottie.json'
import { customEvent } from '../../../utils/customHooks'
import firebase from 'firebase/compat/app'
import { addContent } from '../../../store/features/contentSlice'
import { useNavigate } from 'react-router'

interface IIdea {
  id: string
  ideaId: string
  title: string
  description: string
  archived: boolean
  createdAt: Date
  contentGenerated?: boolean
}

const ContentIdeas: React.FC<{
  projectId: string
  isLoadingIdeas: boolean
  accountId: string
  handleSave: () => void
}> = ({ projectId, isLoadingIdeas, accountId, handleSave }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { userStatus, user } = useAppSelector((store) => store.user)
  const { plans } = useAppSelector((store) => store.plan)
  const { currentProject } = useAppSelector((store) => store.project)
  const { contentIdeas, loading } = useAppSelector(
    (state) => state.contentIdeas,
  )

  const tableRef = useRef(null)

  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('All Time')
  const [allContentIdeas, setAllContentIdeas] = useState<IIdea[]>([])
  const [allContentIdeaDocs, setAllContentIdeasDocs] = useState<any[]>([])
  const [allIdeasArchived, setAllIdeasArchived] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  const isFreePlan = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      return userPlan?.stripe_metadata_isFreePlan !== 'true' ? false : true
    }
  }, [plans, userStatus])

  const scrollToTable = (): void => {
    if (tableRef.current) {
      ;(tableRef.current as HTMLElement).scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (loadingMore) {
      scrollToTable()
    }
  }, [loadingMore])

  const onSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target?.value?.toLowerCase()
      setSearch(event.target?.value)
      if (value?.length > 2) {
        const filteredMention = allContentIdeaDocs?.filter((item) => {
          return item.title?.toLocaleLowerCase()?.includes(value)
        })
        setAllContentIdeas(filteredMention)
      } else {
        setAllContentIdeas(allContentIdeaDocs)
      }
    },
    [allContentIdeaDocs, setSearch, setAllContentIdeas],
  )

  useEffect(() => {
    if (contentIdeas.length > 0) {
      const contentIdeasData: IIdea[] = []
      contentIdeas.map((item: IContentIdea) => {
        return item.ideas.forEach((idea) =>
          contentIdeasData.push({
            id: item.id,
            ideaId: idea.id,
            title: idea.title,
            description: idea.description,
            archived: idea.archived,
            contentGenerated: idea?.contentGenerated || false,
            createdAt: item.createdAt,
          }),
        )
      })
      const allArchived = contentIdeasData.every(
        (idea) => idea.archived === true,
      )
      if (allArchived) {
        setAllIdeasArchived(true)
      } else {
        setAllIdeasArchived(false)
        setAllContentIdeasDocs(contentIdeasData)
        setAllContentIdeas(contentIdeasData)
      }
    }
  }, [contentIdeas])

  const REPLIES_COLUMNS = [
    {
      title: 'Title',
      field: 'Title',
      width: '53%',
    },
    {
      title: 'Actions',
      field: 'Actions',
      width: '10%',
    },
  ]

  const handleFilterChange = useCallback(
    (value: string | undefined): void => {
      if (value) {
        setFilter(value)

        const oneDayInMs = 24 * 60 * 60 * 1000

        const today = new Date()
        const yesterday = new Date(today.getTime() - oneDayInMs)
        const lastWeek = new Date(today.getTime() - 7 * oneDayInMs)
        const last30Days = new Date(today.getTime() - 30 * oneDayInMs)
        const last90Days = new Date(today.getTime() - 90 * oneDayInMs)

        switch (value) {
          case 'All Time':
            setAllContentIdeas(allContentIdeaDocs)
            break
          case 'Today':
            setAllContentIdeas(
              allContentIdeaDocs.filter((item) =>
                isSameDay(today, item.createdAt.seconds * 1000),
              ),
            )
            break
          case 'Yesterday':
            setAllContentIdeas(
              allContentIdeaDocs.filter((item) =>
                isSameDay(yesterday, item.createdAt.seconds * 1000),
              ),
            )
            break
          case 'Last 7 days':
            setAllContentIdeas(
              allContentIdeaDocs.filter(
                (item) => item.createdAt.seconds * 1000 >= lastWeek.getTime(),
              ),
            )
            break
          case 'Last 30 days':
            setAllContentIdeas(
              allContentIdeaDocs.filter(
                (item) => item.createdAt.seconds * 1000 >= last30Days.getTime(),
              ),
            )
            break
          case 'Last 90 days':
            setAllContentIdeas(
              allContentIdeaDocs.filter(
                (item) => item.createdAt.seconds * 1000 >= last90Days.getTime(),
              ),
            )
            break
          default:
            setAllContentIdeas(allContentIdeaDocs)
            console.log('Default')
            break
        }
      }
    },
    [allContentIdeaDocs, setAllContentIdeas, setFilter],
  )

  const handleMenuItem = useCallback(
    async (id: string, ideaId: string): Promise<void> => {
      try {
        Toast({
          title: t && t('contentMarketing.contentIdeas.hideToast.title'),
          text: t && t('contentMarketing.contentIdeas.hideToast.text'),
          variant: 'success',
        })
        const data = {
          contentIdeaId: id,
          ideaId,
          archivedStatus: true,
        }
        await dispatch(hideContentIdea(data))
        subscribeToContentIdeas(projectId)(dispatch)

        customEvent('content_idea_hidden', {
          accountId: accountId,
          projectId: projectId,
          user_userId: user.uid,
          contentIdeasId: ideaId,
        })
      } catch (error) {
        console.error('Error hiding content idea:', error)
        Toast({
          title: 'Not Hided',
          text: 'Please, try again to hide the idea.',
          variant: 'error',
        })
      }
    },
    [t, dispatch, projectId, accountId, user.uid],
  )

  const onLoadMoreIdeas = useCallback(async (): Promise<void> => {
    setLoadingMore(true)
    try {
      Toast({
        title: 'Loading more ideas',
        text: 'Please wait ideas are generating',
        variant: 'info',
        options: { autoClose: 10000 },
      })
      const data = {
        accountId,
        projectId,
      }
      const response = await generateContentIdeas(data)
      if (response === 'Ideas generated successfully.') {
        subscribeToContentIdeas(projectId)(dispatch)
        Toast({
          title: 'Ideas Generated',
          text: 'Please look at the table',
          variant: 'success',
        })
      } else {
        Toast({
          title: 'Error',
          text: 'Error in generating ideas',
          variant: 'error',
        })
      }
    } catch (error) {
      console.error('Error:', error)
      Toast({
        title: 'Error',
        text: 'An error occurred while loading more ideas',
        variant: 'error',
      })
    }
    setLoadingMore(false)
  }, [accountId, projectId, dispatch])

  // const handleSelectAll = useCallback((): void => {
  //   const allSelected = allContentIdeas.length === checkedItems.length

  //   if (allSelected) {
  //     setCheckedItems([])
  //   } else {
  //     setCheckedItems(allContentIdeas)
  //   }
  //   setSelectAllChecked(!allSelected)
  // }, [allContentIdeas, checkedItems, setCheckedItems, setSelectAllChecked])

  // const changeCheckStatusData = useCallback(
  //   async (item: { title: string }): Promise<void> => {
  //     const isChecked = checkedItems.some(
  //       (checkedItem: { title: string }) => checkedItem.title === item.title,
  //     )
  //     if (isChecked) {
  //       setCheckedItems((prev: any) =>
  //         prev.filter((v: { title: string }) => v.title !== item.title),
  //       )
  //       setSelectAllChecked(false)
  //     } else {
  //       setCheckedItems((prev: any) => [...prev, item])
  //     }
  //   },
  //   [checkedItems, setCheckedItems, setSelectAllChecked],
  // )

  const handleGenerateArticle = useCallback(
    (title: string, description: string, id: string, ideaId: string) =>
      async () => {
        try {
          const data = {
            title,
            description,
            projectId: currentProject?.id,
            platform: 'blog',
            status: 'writing',
            contentLength: '1000-2000',
            toneOfVoice: 'professional',
            userId: user?.uid,
            createdAt: firebase.firestore.Timestamp.now(),
          }
          const contentIdeaData = {
            contentIdeaId: id,
            ideaId,
            contentGenerated: true,
          }
          Toast({
            title: 'Content will be created shortly',
            text: 'Content will be created shortly. Please wait...',
            variant: 'success',
            options: { autoClose: 4000 },
          })
          const content = await dispatch(addContent(data))
          await dispatch(contentIdeaGenerated(contentIdeaData))
          navigate(`/content-marketing/${content.payload}`)
        } catch (err) {
          console.log('_error saving content', err)
          Toast({
            title: 'Content Error',
            text: 'Error occured while creating content',
            variant: 'error',
            options: { autoClose: 4000 },
          })
        }
      },
    [handleSave, currentProject],
  )

  return (
    <>
      <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
        <div className="flex gap-2"></div>
        <div className="mt-6 flex justify-between">
          <div className="flex gap-2 grow">
            <div className="relative w-80 rounded-xl">
              <img
                src={SearchIcon}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
              />
              <Input
                type="text"
                size="md"
                placeholder="Search"
                value={search}
                className="pl-10 !border-lstnBlueGray-100 focus:!border-gray-900 rounded-xl text-blue-gray-400 placeholder:opacity-100"
                onChange={onSearch}
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
              />
            </div>
            <Button
              placeholder={undefined}
              className="bg-transparent outline-none shadow-none hover:shadow-none px-3"
            >
              <FilterBarsIcon />
            </Button>
            <Selector
              options={filterOptions}
              selectedValue={filter}
              onChange={handleFilterChange}
              className="relative text-sm font-medium w-24 border-none h-[1.5rem] filterSelector text-blue-gray-500  hover:text-lstnBlueGray-900 uppercase bg-transparent rounded-lg"
              children={undefined}
            />
          </div>
          <div className="flex items-center gap-2">
            {isFreePlan ? (
              <CustomTooltip
                content="Please upgrade to get more ideas."
                className="bg-lstnBlueGray-400 w-56"
              >
                <span>
                  <Button
                    className="h-9 bg-lstnGreen-500"
                    size="sm"
                    ripple={false}
                    disabled
                    onClick={onLoadMoreIdeas}
                    placeholder="Load more ideas"
                  >
                    <CachedIcon className="pr-1" /> Load more ideas
                  </Button>
                </span>
              </CustomTooltip>
            ) : (
              <Button
                className="h-9 bg-lstnGreen-500"
                size="sm"
                ripple={false}
                onClick={onLoadMoreIdeas}
                placeholder="Load more ideas"
              >
                <CachedIcon className="pr-1" /> Load more ideas
              </Button>
            )}
          </div>
        </div>
        <div>
          <Card
            className="h-full w-full mt-6 overflow-hidden border border-blue-gray-50"
            placeholder={undefined}
          >
            <table className="text-left">
              <thead>
                <tr>
                  <th
                    className={`border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10`}
                    style={{
                      width: '4%',
                    }}
                  >
                    {/* <Checkbox
                      checked={selectAllChecked}
                      onChange={handleSelectAll}
                      crossOrigin={undefined}
                    /> */}
                  </th>
                  {REPLIES_COLUMNS.map((val, index) => (
                    <th
                      key={index}
                      className="border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10 text-sm text-blue-gray-900 font-semibold leading-none"
                      style={{
                        width: val.width, // Set max-width to avoid columns extending beyond container
                      }}
                    >
                      <Typography
                        variant="small"
                        className="font-medium text-lstnBlueGray-900 text-base"
                      >
                        {val.title}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoadingIdeas ? (
                  <tr>
                    <td
                      colSpan={REPLIES_COLUMNS.length + 1}
                      className="text-lstnBlueGray-500 text-center w-full h-[350px]"
                    >
                      <div className="max-w-full animate-pulse text-center items-center flex flex-col">
                        <Lottie
                          animationData={waiting}
                          loop
                          autoPlay
                          style={{ width: '120px', height: '120px' }}
                        />
                        <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                          Creating content ideas
                        </Typography>
                        <Typography
                          variant="small"
                          className="text-base font-normal"
                        >
                          It may take few seconds, please wait
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : loading ? (
                  <tr>
                    <td
                      colSpan={REPLIES_COLUMNS.length + 1}
                      className="text-lstnBlueGray-500 text-center w-full h-[350px]"
                    >
                      <div className="max-w-full animate-pulse text-center items-center flex flex-col">
                        <Typography
                          as="div"
                          variant="h1"
                          className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          as="div"
                          variant="paragraph"
                          className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                          &nbsp;
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : allIdeasArchived && !loadingMore ? (
                  <tr>
                    <td
                      colSpan={REPLIES_COLUMNS.length + 1}
                      className="text-lstnBlueGray-500 text-center h-[350px]"
                    >
                      <div className="w-96 mx-auto">
                        <div className="flex justify-center mb-4">
                          <img src="/emptyglass.svg" alt="img" />
                        </div>
                        <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                          No Content Ideas Found
                        </Typography>
                        <Typography
                          variant="small"
                          className="text-base font-normal"
                        >
                          They will appear here as soon as available.
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : !contentIdeas.length && !loading ? (
                  <tr>
                    <td
                      colSpan={REPLIES_COLUMNS.length + 1}
                      className="text-lstnBlueGray-500 text-center h-[350px]"
                    >
                      <div className="w-96 mx-auto">
                        <div className="flex justify-center mb-4">
                          <img src="/emptyglass.svg" alt="img" />
                        </div>
                        <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                          No Content Ideas Found
                        </Typography>
                        <Typography
                          variant="small"
                          className="text-base font-normal"
                        >
                          They will appear here as soon as available.
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : !allContentIdeas.length && !loadingMore ? (
                  <tr>
                    <td
                      colSpan={REPLIES_COLUMNS.length + 1}
                      className="text-lstnBlueGray-500 text-center h-[350px]"
                    >
                      <div className="w-96 mx-auto">
                        <div className="flex justify-center mb-4">
                          <img src="/emptyFilter.svg" alt="img" />
                        </div>
                        <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                          No Content Ideas Found
                        </Typography>
                        <Typography
                          variant="small"
                          className="text-base font-normal"
                        >
                          Please broaden your filters
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ) : (
                  allContentIdeas.map((value: IIdea, index: number) => {
                    // const isChecked = checkedItems.some(
                    //   (checkedItem: any) => checkedItem.title === value.title,
                    // )
                    if (value.archived || value.contentGenerated) return null
                    return (
                      <tr className="border-b border-gray-300" key={index}>
                        <td>
                          {/* <Checkbox
                            checked={isChecked}
                            onChange={() => changeCheckStatusData(value)}
                            crossOrigin={undefined}
                          /> */}
                        </td>

                        <td>
                          <div className="py-2 mr-3">
                            {value.title && (
                              <Typography className="text-base text-lstnBlueGray-900 font-medium line-clamp-3">
                                {value.title}
                              </Typography>
                            )}
                            {value.description && (
                              <Typography className="text-base text-lstnBlueGray-900 font-normal line-clamp-3">
                                {value.description}
                              </Typography>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="flex items-center gap-5">
                            <Button
                              placeholder={'write'}
                              variant="outlined"
                              size="sm"
                              className={`border-lstnGreen-500 text-lstnGreen-500 flex items-center gap-3`}
                              onClick={handleGenerateArticle(
                                value.title,
                                value.description,
                                value.id,
                                value.ideaId,
                              )}
                            >
                              write
                              <SparklesIcon strokeColor="#9C47ED" />
                            </Button>
                            <CustomMenu
                              menuHandlerIcon={<MoreVertIcon />}
                              menuitems={[
                                {
                                  label:
                                    t &&
                                    t(
                                      'contentMarketing.contentIdeas.hideMenuItem',
                                    ),
                                  itemClick: async () =>
                                    await handleMenuItem(
                                      value.id,
                                      value.ideaId,
                                    ),
                                  icon: <VisibilityOffIcon />,
                                },
                              ]}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
            {loadingMore && (
              <div
                className="text-lstnBlueGray-500 text-center w-full h-[350px]"
                ref={tableRef}
              >
                <div className="max-w-full animate-pulse text-center items-center flex flex-col">
                  <Lottie
                    animationData={waiting}
                    loop
                    autoPlay
                    style={{ width: '120px', height: '120px' }}
                  />
                  <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                    Creating more content ideas
                  </Typography>
                  <Typography variant="small" className="text-base font-normal">
                    It may take a few seconds, please wait
                  </Typography>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  )
}

export default ContentIdeas
