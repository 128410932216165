export const filterOptions = [
  { label: 'All Time', value: 'All Time' },
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'Last 7 days', value: 'Last 7 days' },
  { label: 'Last 30 days', value: 'Last 30 days' },
  { label: 'Last 90 days', value: 'Last 90 days' },
]

export const isSameDay = (date1: Date, timestamp: number): boolean => {
  const date2 = new Date(timestamp)
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}
