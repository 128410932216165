export const planData: { [key: string]: string } = {
  starterMonthly: 'lstn-starter-monthly-eur',
  advancedMonthly: 'lstn-advanced-monthly-eur',
  proMonthly: 'lstn-pro-monthly-eur',
  starterYearly: 'lstn-starter-yearly-eur',
  advancedYearly: 'lstn-advanced-yearly-eur',
  proYearly: 'lstn-pro-yearly-eur',
}

export const planName = {
  'lstn-starter-monthly-eur': 'LSTN Starter Monthly EUR',
  'lstn-individual-monthly-eur': 'LSTN Individual Monthly EUR',
  'lstn-advanced-monthly-eur': 'LSTN Advanced Monthly EUR',
  'lstn-pro-monthly-eur': 'LSTN Pro Monthly EUR',
  'lstn-starter-yearly-eur': 'LSTN Individual Yearly EUR',
  'lstn-advanced-yearly-eur': 'LSTN Advanced Yearly EUR',
  'lstn-pro-yearly-eur': 'LSTN Pro Yearly EUR',
}
