// tslint:disable: ordered-imports
// @ts-nocheck
// import moment from "moment"
import React, { useState, useEffect, useCallback, useMemo } from 'react'
// import { connect } from 'react-redux'
import ReactGA from 'react-ga4'
// import { FirebaseReducer } from 'react-redux-firebase'
import { useLocation } from 'react-router-dom'
// import { withHandlers } from 'recompose'
// import { compose } from 'redux'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import { TransProps } from 'react-i18next'
import { Folder } from '@material-ui/icons'
import LinearProgress from '@material-ui/core/LinearProgress'
// @ts-ignore
import ProcessingImg from '../icons/pulse.gif'
import File from '@material-ui/icons/LibraryBooks'
import PeopleIcon from '@material-ui/icons/People'
// import { ProgressType } from "../enums"
import { ITranscript, ITranscriptRow, IUser, IFolders } from '../interfaces'
// import { IRootState } from '../store/reducers/rootReducer'
import { IUserStatusState } from '../store/reducers/userStatus'
// import { filterTranscriptData, parseToTranscriptList, parseToFoldersList, filterTranscriptDataAlone, filterTranscriptDataNoFolder, checkDuplicates } from "../utils/parseData"
import { foldersDataPropagation } from '../utils/foldersData'
// import ActionItems from './Transcripts/ActionPanelItems';
import TranscriptsListTable from '../components/TranscriptsList/Table'
import ConfirmationDialog from '../components/TranscriptsList/ConfirmationDialog'
import RenameDialog from '../components/TranscriptsList/RenameDialog'
import FolderDialog from '../components/TranscriptsList/FolderDialog'
import MoveDialog from '../components/TranscriptsList/MoveDialog'
import { ExportValues } from '../components/ExportMenu/ExportMenu'
import PopupContainer from './SharedList/PopupContainer'
import { database } from '../firebaseApp'
import Toast from './Toast/Toast'
import fileDownloader from '../utils/fileDownloader'
// import TranscriptPlaceholder from "./TranscriptPlaceholder"
import '../css/TranscriptsList.css'
import { roleType } from '../enums'
import { customEvent } from '../utils/customHooks'
import { useAppDispatch, useAppSelector } from '../store/store'
import { fetchTranscriptFolder } from '../store/features/transcript/helper'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-tailwind/react'

interface IProps {
  userId: string
  userStatus: IUserStatusState
  transcripts: ITranscript[]
  transcriptRows: ITranscriptRow[]
  folders?: IFolders[]
  foldersShareUserId?: IFolders[]
  // onBoardingComplete: (id: string, data: ) => void
  // firebase: FirebaseReducer.Reducer<>
  handleTranscriptIdSelected: (transcriptId: string) => void
  handleFolderIdSelected: (folderId: string) => void
  userData?: IUser[]
  folderData?: IFolders
  componentForceUpdate: () => void
  folderHierarchy?: IFolders[]
  getFolderHeirarchy: (userId: string) => void
  loading: boolean
  allTranscripts?: ITranscript[]
  allFolders?: IFolders[]
  // userRole:
  // transcriptfolder:
  // organizations:
  uploadref: HTMLElement
}
type SharedUserRoles = {
  [key: string]: string
}
interface TranscriptDoc {
  id: string
  // Define other properties if applicable
}
interface ApiResponse {
  data: Record<string, any>
  status: number
  statusText: string
  headers: Record<string, string>
  config: {
    url: string
    method: string
    data: string
    timeout: number
    responseType: string
    xsrfCookieName: string
    xsrfHeaderName: string
    maxContentLength: number
  }
  request: {
    __sentry_xhr__: {
      method: string
      url: string
      status_code: number
      body: string
    }
  }
}

//@ts-ignore
const TranscriptsList: React.FC<IProps & TransProps> = (props) => {
  const [openDeletionConfirmation, setOpenDeletionConfirmation] =
    useState<boolean>(false)
  const [openInprogressDialog, setOpenInprogressDialog] =
    useState<boolean>(false)
  const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false)
  const [openCreateFolderDialog, setOpenCreateFolderDialog] =
    useState<boolean>(false)
  const [checkedRowsData, setCheckedRowsData] = useState<ITranscriptRow[]>([])
  const [openMoveDialog, setOpenMoveDialog] = useState<boolean>(false)
  const [folders, setFolders] = useState<IFolders[]>([])
  // const [uploadStatus, setuploadStatus] = useState(false)
  const [sendFolder, setSendFolder] = useState<{
    showFolderDialogue: boolean
    folderData: ITranscriptRow[]
    parentId: string
    sharedUserRoles: SharedUserRoles
    sharedUserIds: string[]
    userId: string
  }>({
    showFolderDialogue: false,
    folderData: [],
    parentId: '',
    sharedUserRoles: {},
    sharedUserIds: [],
    userId: '',
  })
  const dispatch = useAppDispatch()
  const { roles, organizations, userData } = useAppSelector(
    (store) => store.user,
  )
  const userStatus = useAppSelector((store) => store.user)
  const {
    nestedBackup,
    allTranscripts,
    allFolders,
    transcriptRows,
    getFolders,
    foldersShareUserId,
  } = useAppSelector((store) => store.transcriptSlice)
  const { folderHierarchy } = useAppSelector((store) => store.folderHierarchy)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const location = useLocation()
  const { t } = useTranslation()
  const combinedProps = useMemo(
    () => ({
      folderData: props.folderData,
      userId: props.userId,
      allFolders: nestedBackup.allFolders,
      allSharedFolders: nestedBackup.allSharedFolders,
      allTranscripts: nestedBackup.allTranscripts,
      allSharedTranscripts: nestedBackup.allSharedTranscripts,
      currentAccountID: currentAccount?.id,
      currentProjectID: currentProject?.id,
    }),
    [
      nestedBackup,
      props.folderData,
      currentAccount,
      currentProject.id,
      dispatch,
    ],
  )
  // Get the account ID
  const accountId = currentAccount.id
  useEffect(() => {
    // if (( props.folderData && props.folderData.id !== folderDataCheck?.id) || (!isEmpty) || (nestedBackup )) {
    dispatch(
      fetchTranscriptFolder({
        id: combinedProps.userId,
        folderData: combinedProps.folderData,
        role: roles,
        folders: combinedProps.allFolders,
        folderShared: combinedProps.allSharedFolders,
        transcript: combinedProps.allTranscripts,
        transcriptShared: combinedProps.allSharedTranscripts,
        currentAccountID: combinedProps.currentAccountID,
        currentProjectID: combinedProps.currentProjectID,
      }),
    )
    // }
  }, [combinedProps])

  const [renameData, setRenameData] = useState<{
    data: ITranscriptRow[]
  } | null>(null)
  const [newfolderData, setNewfolderData] = useState<{
    id: string
    name: string
  } | null>(null)
  const [popup, setPopup] = useState<{
    isOpen: boolean
    rowsData: ITranscriptRow[] | null
  }>({
    isOpen: false,
    rowsData: null,
  })
  const [permissionData, setPermissionData] = useState<{
    isOpen: boolean
    name: string
  }>({
    isOpen: false,
    name: '',
  })

  useEffect(() => {
    if (
      getFolders !== undefined &&
      foldersShareUserId !== undefined &&
      (getFolders.length !== folders.length ||
        foldersShareUserId.length !== folders.length)
    ) {
      let data = [...getFolders, ...foldersShareUserId]
      if (data) {
        setFolders(data)
      }
    }
  }, [folders.length, getFolders, foldersShareUserId])

  const renderName = useCallback((data: ITranscriptRow) => {
    if (data.status === 'Folder') {
      return (
        <span>
          <Folder />
          <span>
            {data.name}
            {data.shareUserId && data.shareUserId.length > 0 && (
              <PeopleIcon className="iconSvg light" />
            )}
          </span>
        </span>
      )
    } else {
      if (data.status === 'DONE' || data.status === 'EDITED') {
        return (
          <span>
            <File className="iconSvg" />
            <span>
              {data.name}
              {data.shareUserId && data.shareUserId.length > 0 && (
                <PeopleIcon className="iconSvg light" />
              )}
            </span>
          </span>
        )
      } else if (data.status === 'FAILED') {
        return (
          <span>
            <svg width="20" height="20" focusable="false" aria-hidden="true">
              <use xlinkHref="#icon-warning" />
            </svg>
            <span>{data.name}</span>
          </span>
        )
      } else {
        return (
          <span>
            <img width={21} height={21} src={ProcessingImg} alt="" />
            <span>{data.name}</span>
          </span>
        )
      }
    }
  }, [])
  const renderStatus = useCallback(
    (data: ITranscriptRow) => {
      if (data.status == 'Folder') {
        return <span>{data.status}</span>
      }

      const getTranslation = (status: string) => {
        const prefix = 'transcripts.statuses'
        return `${prefix}.${status}`
      }
      // const { t } = props
      if (data.status && data.status.includes('Transcribing')) {
        const newStatus = data.status.split(' ')
        return (
          <span>
            {' '}
            {t &&
              t('transcripts.statuses.transcribing', {
                percent: newStatus[1],
                sign: newStatus[2],
              })}{' '}
          </span>
        )
      }
      if (data.status) {
        const status = data.status.toLowerCase()
        const translation = getTranslation(status)
        return <span> {t && t(`${translation}`)} </span>
      }
      return
    },
    [props, t],
  )

  const moveFolderBox = useCallback(
    (status: boolean, rows?: ITranscriptRow[]) => {
      if (rows) {
        setOpenMoveDialog(status)
        setCheckedRowsData(rows)
      } else {
        setOpenMoveDialog(status)
      }
    },
    [],
  )

  const handleDeleteButtonClicked = useCallback(
    (transcriptIds: ITranscriptRow[]) => {
      setCheckedRowsData(transcriptIds)
      setOpenDeletionConfirmation(true)

      customEvent('transcript_delete_confirm_modal_opened', {
        category: 'transcript',
        transcriptId: transcriptIds[0].id,
        user_userId: props.userId,
        accountId: accountId,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })
    },
    [],
  )

  //@ts-ignore
  const checkChildFoldersAccess = useCallback(
    (folderId: string, name: string) => {
      let check = true
      const userId = props.userId
      const transcripts =
        allTranscripts &&
        allTranscripts?.filter(
          (d) =>
            d.folderId === folderId &&
            (d.userId === props.userId ||
              d.sharedUserIds?.includes(props.userId)),
        )

      for (const snapshot of transcripts!) {
        const file = snapshot
        if (
          !(
            file.userId === props.userId ||
            (file.sharedUserIds?.includes(props.userId) &&
              file.sharedUserRoles &&
              file.sharedUserRoles[userId] === 'sharedEdit')
          )
        ) {
          setPermissionData({ isOpen: true, name: name })
          check = false
          return false
        }
      }

      let folders = allFolders?.filter(
        (d) =>
          (d.userId === props.userId ||
            d.sharedUserIds?.includes(props.userId)) &&
          d.parentFolderId === folderId,
      )
      for (const snapshot of folders!) {
        const file = snapshot
        if (
          !(
            file.userId === userId ||
            (file.sharedUserIds?.includes(props.userId) &&
              file.sharedUserRoles &&
              file.sharedUserRoles[props.userId] === 'sharedEdit')
          )
        ) {
          setPermissionData({ isOpen: true, name: name })
          check = false
          return false
        } else {
          check = checkChildFoldersAccess(file.id, name)
        }
      }

      if (folders?.length! < 1) {
        return true
      }
      return check
    },
    [allFolders, allTranscripts, props.userId],
  )

  const handleDeletionClose = useCallback(
    async (status?: boolean) => {
      setOpenDeletionConfirmation(false)
      // const { t } = props
      if (status) {
        // Display toast notification
        Toast({
          title: t && t('transcriptDetails.pleaseWait'),
          text: t && t('transcriptDetails.delete'),
          variant: 'success',
        })

        for (let index = 0; index < checkedRowsData.length; index++) {
          const rows = checkedRowsData[index]
          if (rows.status == 'Folder') {
            const permission = checkChildFoldersAccess(rows.id, rows.name)
            if (permission) {
              const folderRef = database.collection('folders').doc(rows.id)
              folderRef.update({
                archive: true,
              })
              // ReactGA.event({
              //   action: "folder_deleted",
              //   category: "folders",
              //   label: rows.id,
              // })
              customEvent('folder_deleted', {
                category: 'folders',
                folderId: rows.id,
                user_userId: props.userId,
                accountId: accountId,
                projectId:
                  currentProject && currentProject.id ? currentProject.id : '',
              })
              foldersDataPropagation(
                rows.id,
                { delete: true, userId: props.userId },
                allTranscripts!,
                allFolders!,
              )
            }
          } else {
            const transcriptRef = database
              .collection('transcripts')
              .doc(rows.id)
            transcriptRef.update({
              archive: true,
            })
            // ReactGA.event({
            //   action: "transcript_deleted",
            //   category: "transcripts",
            //   label: rows.id,
            // })

            customEvent('transcript_deleted', {
              category: 'transcript',
              transcriptId: rows.id,
              user_userId: props.userId,
              accountId: accountId,
              projectId:
                currentProject && currentProject.id ? currentProject.id : '',
            })
          }
        }
      }
    },
    [props, checkedRowsData, checkChildFoldersAccess, t],
  )

  const handleMoveFolder = useCallback(
    async (status?: boolean) => {
      setSendFolder((prevState) => ({
        ...prevState,
        showFolderDialogue: false,
      }))
      if (status) {
        const row = sendFolder?.folderData!
        const id = sendFolder?.parentId!
        const shareUserRoles = sendFolder?.sharedUserRoles!
        const shareUserIds = sendFolder?.sharedUserIds!
        const userId =
          sendFolder && sendFolder.userId ? sendFolder.userId : props.userId

        if (id) {
          row.map(async (file) => {
            // In case of Folder
            if (file.status === roleType.FOLDER) {
              const permission = await checkChildFoldersAccess(
                file.id,
                file.name,
              )
              if (permission) {
                // Data of shared folder
                const movefolderRef = database
                  .collection('folders')
                  .doc(file.id)
                await movefolderRef.update({
                  sharedUserRoles: shareUserRoles ? shareUserRoles : {},
                  sharedUserIds: shareUserIds ? shareUserIds : [],
                  parentFolderId: id === 'root' ? '' : id,
                  userId,
                })
                // ReactGA.event({
                //   action: "folder_moved",
                //   category: "folders",
                //   label: file?.id,
                // })
                customEvent('folder_moved', {
                  category: 'folders',
                  folderId: file.id,
                  user_userId: userId,
                  actionSource: 'from-root-folder',
                  accountId: accountId,
                  projectId:
                    currentProject && currentProject.id
                      ? currentProject.id
                      : '',
                })

                // To change roles in child folders
                await foldersDataPropagation(
                  file.id,
                  { userId, sharedUserRoles: {}, shareduserIds: [] },
                  allTranscripts!,
                  allFolders!,
                )
              }
              // case of transcript
            } else {
              const moveTranscriptRef = database
                .collection('transcripts')
                .doc(file.id)
              await moveTranscriptRef.update({
                sharedUserRoles: shareUserRoles ? shareUserRoles : {},
                sharedUserIds: shareUserIds ? shareUserIds : [],
                folderId: id === 'root' ? '' : id,
                userId,
              })
              // ReactGA.event({
              //   action: "transcript_moved",
              //   category: "transcripts",
              //   label: file?.id,
              // })
              customEvent('transcript_moved', {
                category: 'transcripts',
                transcriptId: file.id,
                user_userId: userId,
                actionSource: 'from-root-folder',
                accountId: accountId,
                projectId:
                  currentProject && currentProject.id ? currentProject.id : '',
              })
            }
          })
        }
      }
    },
    [
      sendFolder,
      props.userId,
      allTranscripts,
      allFolders,
      checkChildFoldersAccess,
    ],
  )

  const getItemsIds = useCallback(
    async (folderId: string) => {
      const folderIds: string[] = []
      const transcriptIds: string[] = []
      let children: React.ReactNode[] = []
      const getMainFolderId = async (folders: IFolders[]) => {
        await folders.map(async (folder) => {
          if (folder.id == folderId) {
            //console.log(folder.name)
            folderIds.push(folderId)
            if (folder.children && folder.children.length > 0) {
              //console.log('folder.children', folder.children)
              children = folder.children
            }
            await getTranscriptIds(folderId)
          } else {
            if (folder.children && folder.children.length > 0) {
              getMainFolderId(folder.children)
            }
          }
        })
      }

      const mapFolder = async (children: React.ReactNode) => {
        for (let i = 0; i < children.length; i++) {
          const folder: IFolders = children[i]
          folderIds.push(folder.id)
          await getTranscriptIds(folder.id)
          if (folder.children && folder.children.length > 0) {
            mapFolder(folder.children)
          }
        }
      }

      const getTranscriptIds = async (folderId: string) => {
        try {
          // To do: limitation of sharing transcripts with current user as the owner, for other files logic need to be updated
          const transcriptReference = await database
            .collection(`transcripts`)
            .where('userId', '==', props.userId)
            .where('folderId', '==', folderId)
            .get()
          const transcriptSharedReference = await database
            .collection(`transcripts`)
            .where('shareUserId', 'array-contains', props.userId)
            .where('folderId', '==', folderId)
            .get()
          transcriptReference.forEach((doc: TranscriptDoc) => {
            transcriptIds.push(doc.id)
          })
          transcriptSharedReference.forEach(
            (data: firebase.firestore.QueryDocumentSnapshot) => {
              if (data.id) transcriptIds.push(data.id)
            },
          )
        } catch (err) {
          console.log('transcriptions:get Transcripts List', err)
        }
      }
      try {
        if (folderHierarchy) {
          await getMainFolderId(folderHierarchy)
          await mapFolder(children)
        }
      } catch (err) {
        console.error('getItemsIds: TranscriptList, error', err)
      }
      return { folderIds, transcriptIds }
    },
    [props],
  )

  const moveFolderBoxSelect = useCallback(
    (id: string) => {
      async function moveList(id: string, row: ITranscriptRow[]) {
        if (id && id !== 'root') {
          // Data of new parent folder
          const folderRef = database.collection('folders').doc(id)
          const folderData = await folderRef.get()
          const data = folderData.data()
          row.map(async (file) => {
            // In case of Folder
            if (file.status === roleType.FOLDER) {
              const permission = await checkChildFoldersAccess(
                file.id,
                file.name,
              )
              if (permission) {
                // Data of shared folder
                const movefolderRef = database
                  .collection('folders')
                  .doc(file.id)
                const moveFolderData = (await movefolderRef.get()).data()
                const moveshareuserRoles: {} = data?.sharedUserRoles
                  ? { ...data?.sharedUserRoles }
                  : {}
                const moveshareuserIds: string[] = data?.sharedUserIds
                  ? [...data.sharedUserIds]
                  : []

                if (data && data.userId !== props.userId) {
                  moveshareuserRoles[data?.userId] = 'sharedEdit'
                  moveshareuserIds.push(data?.userId)
                }

                // Check parent of folder
                if (
                  data &&
                  data.userId === moveFolderData?.userId &&
                  JSON.stringify(data.sharedUserRoles) ===
                    JSON.stringify(moveFolderData?.sharedUserRoles!)
                ) {
                  await movefolderRef.update({
                    sharedUserRoles: moveshareuserRoles,
                    sharedUserIds: moveshareuserIds,
                    parentFolderId: id,
                    userId: data.userId,
                  })
                  // ReactGA.event({
                  //   action: "folder_moved",
                  //   category: "folders",
                  //   label: moveFolderData?.id,
                  // })

                  customEvent('folder_moved', {
                    category: 'folders',
                    folderId: file.id,
                    user_userId: data.userId,
                    actionSource: 'from-non-root-folder',
                    accountId: accountId,
                    projectId:
                      currentProject && currentProject.id
                        ? currentProject.id
                        : '',
                  })
                  // To change roles in child folders
                  await foldersDataPropagation(
                    file.id,
                    {
                      userId: data.userId,
                      sharedUserRoles: moveshareuserRoles,
                      shareduserIds: moveshareuserIds,
                    },
                    allTranscripts!,
                    allFolders!,
                  )
                } else {
                  setSendFolder({
                    folderData: row,
                    showFolderDialogue: true,
                    parentId: id,
                    sharedUserIds: moveshareuserIds,
                    sharedUserRoles: moveshareuserRoles,
                    userId: data?.userId,
                  })
                }
              }
              // Case of transcript
            } else {
              const moveTranscriptRef = database
                .collection('transcripts')
                .doc(file.id)
              const moveTranscriptData = (await moveTranscriptRef.get()).data()
              const moveshareuserRoles: {} = data?.sharedUserRoles
                ? data?.sharedUserRoles
                : {}
              const moveshareuserIds: string[] = data?.sharedUserIds
                ? data.sharedUserIds
                : []

              moveshareuserRoles[data?.userId] = 'sharedEdit'
              moveshareuserIds.push(data?.userId)

              if (
                data &&
                data.userId === moveTranscriptData?.userId &&
                JSON.stringify(data.sharedUserRoles) ===
                  JSON.stringify(moveTranscriptData?.sharedUserRoles!)
              ) {
                await moveTranscriptRef.update({
                  sharedUserRoles: moveshareuserRoles,
                  sharedUserIds: moveshareuserIds,
                  folderId: id,
                  userId: props.userId,
                })
                // ReactGA.event({
                //   action: "transcript_moved",
                //   category: "transcripts",
                //   label: moveTranscriptData?.id,
                // })
                customEvent('transcript_moved', {
                  category: 'transcripts',
                  transcriptId: file.id,
                  user_userId: data.userId,
                  actionSource: 'from-non-root-folder',
                  accountId: accountId,
                  projectId:
                    currentProject && currentProject.id
                      ? currentProject.id
                      : '',
                })
              } else {
                setSendFolder({
                  folderData: row,
                  showFolderDialogue: true,
                  parentId: id,
                  sharedUserIds: moveshareuserIds,
                  sharedUserRoles: moveshareuserRoles,
                  userId: data?.userId,
                })
              }
            }
          })
        } else {
          setSendFolder((prevState) => ({
            ...prevState,
            folderData: row,
            showFolderDialogue: true,
            parentId: id,
          }))
        }
      }
      moveFolderBox(false)
      const rows = checkedRowsData
      moveList(id, rows)
      props.getFolderHeirarchy(props.userId)
    },
    [moveFolderBox, checkedRowsData, props, checkChildFoldersAccess],
  )

  const handleRowClick = useCallback(
    (transcriptId: string, status?: 'completed' | 'inprogress' | 'Folder') => {
      if (status == 'Folder') {
        props.handleFolderIdSelected(transcriptId)
      } else {
        if (transcriptId !== undefined && status === 'completed') {
          props.handleTranscriptIdSelected(transcriptId)
        } else if (transcriptId && status === 'inprogress') {
          setOpenInprogressDialog(true)
        }
      }
    },
    [props],
  )

  const closeInprogressDialog = () => {
    setOpenInprogressDialog(false)
  }

  const exportTranscript = useCallback((id: string, data: ExportValues) => {
    return new Promise(async (resolve, reject) => {
      // ReactGA.event({
      //   action: "Exported Transcript",
      //   category: "transcript",
      //   label: data.fileType,
      // })
      customEvent('transcript_exported', {
        category: 'transcript',
        transcriptId: id,
        fileType: data.fileType,
        user_userId: props.userId,
        accountId: accountId,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })
      if (firebase.auth().currentUser) {
        // @ts-ignore
        const token = await firebase.auth().currentUser.getIdToken()
        axios
          .post(
            `${process.env.REACT_APP_FIREBASE_HTTP_CLOUD_FUNCTION_URL}/api/transcripts/${id}/export`,
            {
              data,
            },
            {
              headers: {
                authorization: `Bearer ${token}`,
                'Access-Control-Expose-Headers': 'Content-Disposition',
              },
              responseType: 'arraybuffer',
            },
          )
          .then((res: ApiResponse) => {
            fileDownloader(
              res,
              data.fileType,
              props.userId,
              id,
              accountId,
              currentProject,
            )
            //@ts-ignore
            resolve()
          })
          .catch(() => {
            reject()
          })
      }
    })
  }, [])

  const exportBulkTranscript = useCallback(
    (transcriptIds: string[], data: ExportValues) => {
      return new Promise(async (resolve, reject) => {
        // ReactGA.event({
        //   action: "Exported Transcript",
        //   category: "transcript",
        //   label: data.fileType,
        // })
        customEvent('transcript_exported', {
          category: 'transcript',
          transcriptId: JSON.stringify(transcriptIds),
          fileType: data.fileType,
          user_userId: props.userId,
          accountId: accountId,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        })
        if (firebase.auth().currentUser) {
          // @ts-ignore
          const token = await firebase.auth().currentUser.getIdToken()
          axios
            .post(
              `${process.env.REACT_APP_FIREBASE_HTTP_CLOUD_FUNCTION_URL}/api/transcriptionsBulkExport`,
              {
                data: {
                  ...data,
                  transcriptIds,
                },
              },
              {
                headers: {
                  authorization: `Bearer ${token}`,
                  'Access-Control-Expose-Headers': 'Content-Disposition',
                },
                responseType: 'arraybuffer',
              },
            )
            .then((res: ApiResponse) => {
              fileDownloader(
                res,
                data.fileType,
                props.userId,
                transcriptIds,
                accountId,
                currentProject,
              )
              //@ts-ignore
              resolve()
            })
            .catch(() => {
              reject()
            })
        }
      })
    },
    [],
  )

  const handleExportClicked = useCallback(
    async (transcriptIds: string[], data: ExportValues) => {
      // const { t } = props
      // Display toast notification
      Toast({
        title: t && t('transcriptDetails.pleaseWait'),
        text: t && t('transcriptDetails.export'),
        variant: 'success',
      })
      if (data.fileType === 'xls' && data.advancedOptions.singleFile) {
        await exportBulkTranscript(transcriptIds, data)
      } else {
        for (const id of transcriptIds) {
          await exportTranscript(id, data)
        }
      }
    },
    [exportBulkTranscript, exportTranscript, props, t],
  )

  const handleEditButton = (renameData: { data: ITranscriptRow[] }) => {
    setOpenRenameDialog(true)
    setRenameData(renameData)
    if (renameData.data[0].status == 'Folder') {
      customEvent('folder_rename_modal_opened', {
        category: 'folders',
        folderId: renameData.data[0].id,
        user_userId: props.userId,
        accountId: accountId,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })
    } else {
      customEvent('transcript_rename_modal_opened', {
        category: 'transcript',
        transcriptId: renameData.data[0].id,
        user_userId: props.userId,
        accountId: accountId,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })
    }
  }

  const handleCreateNewFolder = (newfolderData: {
    id: string
    name: string
  }) => {
    setOpenCreateFolderDialog(true)
    setNewfolderData(newfolderData)
  }

  const handleCreateNewFolderClose = useCallback(
    async (status: boolean, value?: string) => {
      if (status && value) {
        if (newfolderData && newfolderData.id) {
          // await database.collection("folders").doc(newfolderData.id).update({
          // 	name: value
          //   });
          //   getFolderHeirarchy(userId);
        } else {
          const folderData = {
            createdAt: new Date().toISOString(),
            lastModified: new Date().toISOString(),
            name: value,
            parentFolderId:
              props.folderData && props.folderData.id
                ? props.folderData.id
                : '',
            userId: props.userId,
            userRoles: 'owner',
            accountId: currentAccount.id,
            projectId:
              currentProject && currentProject.id ? currentProject.id : '',
          }
          const addedFolder = await database
            .collection('folders')
            .add(folderData)
          await database
            .collection('folders')
            .doc(addedFolder.id)
            .set({
              ...folderData,
              id: addedFolder.id,
            })
          // ReactGA.event({
          //   action: "folder_created",
          //   category: "folders",
          //   label: addedFolder.id,
          // })

          customEvent('folder_created', {
            category: 'folders',
            folderId: addedFolder.id,
            user_userId: props.userId,
            actionSource:
              location.pathname == '/transcripts'
                ? 'within-root-folder'
                : 'within-non-root-folder',
            accountId: accountId,
          })
          let shareUserId: string[] = []
          let shareUserRoles: {} = []

          if (props.folderData && props.folderData.id) {
            shareUserId = props.folderData.sharedUserIds!
            shareUserRoles = props.folderData.sharedUserRoles!

            if (
              props.folderData.userId != props.userId &&
              shareUserId.indexOf(props.folderData.userId) == -1
            ) {
              shareUserRoles[props.folderData.userId] = 'sharedEdit'
              shareUserId.push(props.folderData.userId)
            }

            if (shareUserId && shareUserId.length > 0) {
              await database
                .collection('folders')
                .doc(addedFolder.id)
                .update({
                  sharedUserIds: shareUserId,
                  sharedUserRoles:
                    shareUserRoles !== null ? shareUserRoles : {},
                })
            }
          }
          props.getFolderHeirarchy(props.userId)
        }
      }
      setOpenCreateFolderDialog(false)
      setNewfolderData(null)
      // setNewfolderData("");
    },
    [newfolderData, props, currentProject.id],
  )

  const handleEditClose = useCallback(
    async (status: boolean, value?: string) => {
      setOpenRenameDialog(false)

      if (status && renameData) {
        const { data } = renameData
        if (data && data.length > 0) {
          data.map(async (d) => {
            const { id } = d
            let cat = ''
            try {
              if (d.status === 'Folder') {
                const transcriptRef = await database
                  .collection('folders')
                  .doc(id)
                await transcriptRef.update({
                  name: value,
                })
                // ReactGA.event({
                //   action: "folder_renamed",
                //   category: "folders",
                //   label: id,
                // })
                customEvent('folder_renamed', {
                  category: 'folders',
                  folderId: id,
                  user_userId: props.userId,
                  accountId: accountId,
                  projectId:
                    currentProject && currentProject.id
                      ? currentProject.id
                      : '',
                })
                cat = 'folders'
              } else {
                const transcriptRef = await database
                  .collection('transcripts')
                  .doc(id)
                await transcriptRef.update({
                  name: value,
                })
                // ReactGA.event({
                //   action: "transcript_renamed",
                //   category: "transcripts",
                //   label: id,
                // })

                customEvent('transcript_renamed', {
                  category: 'transcript',
                  transcriptId: id,
                  user_userId: props.userId,
                  accountId: accountId,
                  projectId:
                    currentProject && currentProject.id
                      ? currentProject.id
                      : '',
                })

                cat = 'transcripts'
              }
              console.log(cat)
            } catch (error) {
              console.error(error)
              // ReactGA.exception({
              //   description: error.message,
              //   fatal: false,
              //   category: cat,
              // })
              ReactGA.gtag('event', 'transcript_renamed_exception', {
                description: (error as Error).message,
                fatal: false,
                category: cat,
              })
            }
          })
        }
      }
    },
    [renameData, setOpenRenameDialog],
  )

  const closePopup = () => {
    setPopup({ isOpen: false, rowsData: [] })
  }
  const openPopup = (data: ITranscriptRow[]) => {
    setPopup({ isOpen: true, rowsData: data })
  }

  let userFeatures = (userData && userData.features) || {}

  const columnMappings = [
    {
      title: t && t('transcripts.title'),
      field: 'name',
      width: '40%',
      render: (rowData: ITranscriptRow) => renderName(rowData),
    },
    {
      title: t && t('transcripts.status'),
      field: 'status',
      width: '12%',
      render: (rowData: ITranscriptRow) => renderStatus(rowData),
    },
    {
      title: t && t('transcripts.duration'),
      field: 'duration',
      width: '12%',
    },
    {
      title: t && t('transcripts.uploaded'),
      field: 'uploadedOn',
      width: '15%',
    },
    {
      title: t && t('transcripts.modified'),
      field: 'lastModified',
      width: '15%',
    },
  ]
  const transcriptRowsTableData = transcriptRows.map((o) => ({ ...o }))
  let folderName = t ? t('transcripts.transcriptsTitle') : ''
  if (props.folderData && props.folderData.name) {
    folderName = folderName + ' > ' + props.folderData.name
  }

  return (
    <div
      className="trans-list-container"
      data-cy="data-project-transcripts-list"
    >
      <ConfirmationDialog
        delBtn
        open={openDeletionConfirmation}
        headerText={t && t('transcripts.delete')}
        bodyText={t && t('transcripts.confirmation')}
        onClose={handleDeletionClose}
        primaryLabel={t && t('transcripts.confirmDelete')}
        cy="delete"
      />
      <ConfirmationDialog
        open={openInprogressDialog}
        headerText={t && t('transcripts.inprogessHeader')}
        bodyText={t && t('transcripts.inprogressBody')}
        onClose={closeInprogressDialog}
        primaryLabel={t && t('transcripts.dialogOk')}
        noSecondaryAction
        cy="progress"
      />
      <ConfirmationDialog
        delBtn
        open={sendFolder ? sendFolder.showFolderDialogue : false}
        headerText={t && t('folder.move')}
        bodyText={t && t('folder.moveConfirm')}
        onClose={handleMoveFolder}
        primaryLabel={t && t('videoInfo.proceed')}
        cy="move-confirm"
      />
      <ConfirmationDialog
        open={permissionData ? permissionData.isOpen : false}
        headerText={t && t('permission.heading')}
        bodyText={t && t('permission.message') + permissionData?.name!}
        onClose={() => setPermissionData({ name: '', isOpen: false })}
        cy="permission"
      />
      <RenameDialog
        open={openRenameDialog && renameData ? true : false}
        initialValue={renameData ? renameData.data[0].name : ''}
        onClose={handleEditClose}
      />
      <FolderDialog
        open={openCreateFolderDialog && newfolderData ? true : false}
        initialValue={newfolderData ? newfolderData.name : ''}
        onClose={handleCreateNewFolderClose}
        data={newfolderData}
      />
      <PopupContainer
        {...{
          userId: props.userId,
          rowsData: popup?.rowsData,
          isOpen: popup ? popup.isOpen : false,
          closePopup: closePopup,
          checkChildFoldersAccess: checkChildFoldersAccess,
        }}
      />
      <MoveDialog
        open={openMoveDialog}
        folderHierarchy={folderHierarchy}
        checkedRowsData={checkedRowsData}
        moveFolderBoxSelect={moveFolderBoxSelect}
        moveFolderBox={moveFolderBox}
        folderData={props.folderData}
      />

      <Typography className="text-2xl text-blue-gray-900 font-medium mb-0">
        {folderName}
      </Typography>
      <div>
        <TranscriptsListTable
          // @ts-ignore
          columnMappings={columnMappings}
          openPopup={openPopup}
          dataMappings={transcriptRowsTableData}
          onDelete={handleDeleteButtonClicked}
          onExport={handleExportClicked}
          onRename={handleEditButton}
          onNewFolder={handleCreateNewFolder}
          onClick={handleRowClick}
          moveFolderBox={moveFolderBox}
          userStatus={userStatus}
          userFeatures={userFeatures}
          // @ts-ignore
          folders={folders}
          getItemsIds={getItemsIds}
          // @ts-ignore
          organizations={organizations}
          userId={props.userId}
          getFolderHeirarchy={props.getFolderHeirarchy}
          loading={props.loading}
          location={location}
          accountId={accountId}
          uploadref={props.uploadref}
        />
        {props.loading && <LinearProgress color="primary" />}
      </div>
    </div>
  )
}

// const mapStateToProps = (state: IRootState, props: ) => {
//   // console.log(state.firestore)
//   let transcriptList: ITranscript[] = []
//   let transcriptListShareUserId: ITranscript[] = []
//   let transcriptListNoFolder: ITranscript[] = []

//   let transcripts: ITranscript[] = []
//   let transcriptRows: ITranscriptRow[] = []

//   let folders: ITranscriptRow[] = []
//   let foldersShareUserId: ITranscriptRow[] = []

//   let roles:  = {}
//   if (state.firestore.data.roles) roles = state.firestore.data.roles[Object.keys(state.firestore.data.roles)[0]]

//   // console.log(state, state.firestore.ordered)
//   // console.log("State FIRESTORE Ordered >>>>>>>>>", state.firestore.ordered)
//   if (state.firestore.ordered.transcriptList) {
//     transcriptList = filterTranscriptData(state.firestore.ordered.transcriptList)
//     // console.log(state.firestore.ordered.transcriptList,'transcriptList');

//   }
//   if (state.firestore.ordered.transcriptListShareUserId) {
//     transcriptListShareUserId = filterTranscriptData(state.firestore.ordered.transcriptListShareUserId)
//   }

//   if (state.firestore.ordered.folders && state.firestore.ordered.folders.length > 0) {
//     folders = parseToFoldersList(state.firestore.ordered.folders, roles, state.firestore.ordered.users[0].id)
//   }

//   if (state.firestore.ordered.transcriptListNoFolder && state.firestore.ordered.transcriptListNoFolder.length > 0 && !state.transcript.folder) {
//     transcriptListNoFolder = filterTranscriptDataNoFolder(state.firestore.ordered.transcriptListNoFolder)
//   }

//   if (state.firestore.ordered.foldersShareUserId && state.firestore.ordered.foldersShareUserId.length > 0) {
//     foldersShareUserId = parseToFoldersList(state.firestore.ordered.foldersShareUserId, roles, state.firestore.ordered.users[0].id)
//   }

//   let aloneSharedTranscript = filterTranscriptDataAlone(
//     [...transcriptList, ...transcriptListShareUserId],
//     state.firestore.ordered.transcriptListShareUserIdAlone ? state.firestore.ordered.transcriptListShareUserIdAlone : [],
//     props.folderHierarchy ? props.folderHierarchy : [],
//     state.firebase.auth.uid,
//     state.transcript.folder,
//   )

//   transcripts = [...transcriptList, ...transcriptListShareUserId, ...aloneSharedTranscript, ...transcriptListNoFolder]

//   if (transcripts.length > 0) {
//     transcriptRows = parseToTranscriptList(transcripts, roles, state.firestore.ordered.users[0].id)
//   }
//   transcriptRows = [...checkDuplicates([...folders, ...foldersShareUserId]), ...transcriptRows]
//   // let organization: IOrg[] = state.firestore.ordered.organizations

//   let allTranscripts: ITranscript[] = []
//   let allFolders: IFolders[] = []

//   if (state.firestore.ordered.allTranscripts) {
//     allTranscripts = state.firestore.ordered.allTranscripts
//   }
//   if (state.firestore.ordered.allSharedTranscripts) {
//     allTranscripts = [...allTranscripts, ...state.firestore.ordered.allSharedTranscripts]
//   }

//   if (state.firestore.ordered.allFolders) {
//     allFolders = state.firestore.ordered.allFolders
//   }
//   if (state.firestore.ordered.allSharedFolders) {
//     allFolders = [...allFolders, ...state.firestore.ordered.allSharedFolders]
//   }
//   return {
//     // transcriptfolder: state.transcript.folder,
//     // userRole: roles,
//     // organizations: organization && organization[0] ? organization[0] : undefined,
//     // folders,
//     // foldersShareUserId,
//     // transcriptRows,
//     // transcripts,
//     // userStatus: state.userStatus,
//     // userData: state.firestore.ordered.users,
//     // allTranscripts,
//     // allFolders,
//   }
// }
export default TranscriptsList
// export default compose<React.ElementType>(
//   connect((state: IRootState) => ({
//     auth: state.firebase.auth,
//     firebase: state.firebase,
//   })),
// firestoreConnect((props: FirebaseReducer.Reducer<>) =>
//   //console.log(props.auth.uid, 'i am here', props),
//   [
//     {
//       collection: 'transcripts',
//       orderBy: [['createdAt', 'desc']],
//       storeAs: 'transcriptListNoFolder',
//       where: [['userId', '==', props.auth.uid]],
//     },
//     {
//       collection: 'transcripts',
//       orderBy: [['createdAt', 'desc']],
//       storeAs: 'transcriptList',
//       where: [
//         ['userId', '==', props.auth.uid],
//         //@ts-ignore
//         ['folderId', '==', props.folderData ? props.folderData.id : ''],
//       ],
//       //      where: ["userIds", "array-contains", props.auth.uid],
//     },
//     {
//       collection: 'transcripts',
//       storeAs: 'transcriptListShareUserId',
//       where: [
//         ['sharedUserIds', 'array-contains', props.auth.uid],
//         //@ts-ignore
//         ['folderId', '==', props.folderData ? props.folderData.id : ''],
//       ],
//     },
//     {
//       collection: 'transcripts',
//       storeAs: 'transcriptListShareUserIdAlone',
//       where: [['sharedUserIds', 'array-contains', props.auth.uid]],
//     },
//     {
//       collection: 'folders',
//       storeAs: 'folders',
//       where: [
//         ['userId', '==', props.auth.uid],
//         //@ts-ignore
//         ['parentFolderId', '==', props.folderData ? props.folderData.id : ''],
//       ],
//     },
//     {
//       collection: 'folders',
//       storeAs: 'foldersShareUserId',
//       //@ts-ignore
//       where: props.folderData
//         ? [
//             ['sharedUserIds', 'array-contains', props.auth.uid],
//             //@ts-ignore
//             ['parentFolderId', '==', props.folderData ? props.folderData.id : ''],
//           ]
//         : [['sharedUserIds', 'array-contains', props.auth.uid]],
//     },
//   ],
// ),

// withHandlers({
//   onBoardingComplete: (props: WithFirestoreProps) => (id: string, data: ) => {
//     props.firestore.update(`users/${id}`, {
//       ...data,
//     })
//   },
// }),
//   withTranslation(),
// )(withRouter(TranscriptsList))
