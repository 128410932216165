import React, { memo, useCallback } from 'react'

import { Input, Textarea, Select, Option } from '@material-tailwind/react'
import firebase from 'firebase/compat/app'

import infoIcon from '../../../icons/info.svg'

import CustomTooltip from '../../Tooltip/Tooltip'
import { useAppSelector } from '../../../store/store'
import { database } from '../../../firebaseApp'
import { IContent } from '../../../interfaces'
import { customEvent } from '../../../utils/customHooks'
import TailwinButton from '../../Button/TailwindButton'

const STATUS_MAP = [
  { name: 'Draft', value: 'draft' },
  { name: 'Finalized', value: 'finalized' },
  { name: 'Published', value: 'published' },
]

const TONE_OF_VOICE_MAP = [
  { name: 'Friendly', value: 'friendly' },
  { name: 'Professional', value: 'professional' },
  { name: 'Academic', value: 'academic' },
  { name: 'Informational', value: 'informational' },
  { name: 'Funny', value: 'funny' },
]

const CONTENT_LENGTH_MAP = [
  { name: 'Short', value: '500-1000' },
  { name: 'Medium', value: '1000-2000' },
  { name: 'Long', value: 'above-2000' },
]

interface IAboutContentProps {
  activeVersion: string
  setActiveVersion: (status: string) => void
  handleRegenerateContent: () => void
  contentVersions: string[]
}

const AboutContent: React.FC<IAboutContentProps> = ({
  activeVersion,
  setActiveVersion,
  handleRegenerateContent,
  contentVersions,
}) => {
  const { selectedContent } = useAppSelector((store) => store.content)
  const { user } = useAppSelector((store) => store.user)
  const { currentProject } = useAppSelector((store) => store.project)
  const { currentAccount } = useAppSelector((store) => store.account)

  const updateArticle = useCallback(
    async (data: IContent) => {
      try {
        const { id } = data
        const userRef = database.collection(`content`).doc(id)
        await userRef.update({ ...data })
      } catch (error) {
        console.error('error', error)
      }
    },
    [database],
  )

  const handleChangeTitle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const articleData = { ...selectedContent }
      event.target.style.height = 'auto'
      event.target.style.height = event.target.scrollHeight + 'px'
      articleData.title = event.target.value
      if (articleData?.id) {
        updateArticle(articleData)
        customEvent('content_title_edited', {
          category: 'content',
          contentId: articleData.id,
          user_userId: user.uid,
          actionSource: 'from-sidepanel-info',
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        })
      }
    },
    [currentProject, user, selectedContent, updateArticle],
  )

  const handleChangeDescription = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const articleData = { ...selectedContent }
      event.target.style.height = 'auto'
      event.target.style.height = event.target.scrollHeight + 'px'
      articleData.description = event.target.value
      if (articleData?.id) {
        updateArticle(articleData)
        customEvent('content_description_edited', {
          category: 'content',
          contentId: articleData.id,
          user_userId: user.uid,
          actionSource: 'from-sidepanel-info',
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        })
      }
    },
    [currentProject, user, selectedContent, updateArticle],
  )
  const handleChangeVersion = useCallback(
    (value: string | undefined) => {
      if (value?.length) {
        setActiveVersion(value)

        const articleData = { ...selectedContent }
        const { contentGenerated } = articleData

        let updatedContentGenerated = {}
        contentVersions?.forEach((vk) => {
          if (vk === value) {
            updatedContentGenerated = {
              ...updatedContentGenerated,
              [vk]: {
                active: true,
                content: contentGenerated[vk]?.content,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              },
            }
          } else {
            updatedContentGenerated = {
              ...updatedContentGenerated,
              [vk]: {
                active: false,
                content: contentGenerated[vk]?.content,
              },
            }
          }
        })

        articleData.contentGenerated = updatedContentGenerated

        updateArticle(articleData)

        customEvent('editor_version_changed', {
          category: 'content',
          contentId: articleData.id,
          user_userId: user.uid,
        })
      }
    },
    [currentProject, user, selectedContent, updateArticle],
  )

  const handleChangeStatus = useCallback(
    (value: string | undefined) => {
      if (value?.length) {
        const articleData = { ...selectedContent }
        articleData.status = value
        updateArticle(articleData)

        customEvent('content_status_changed', {
          category: 'content',
          contentId: selectedContent?.id,
          user_userId: user.uid,
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
          actionSource: selectedContent?.status,
          actionDestination: value,
          actionMetadata: 'user-action',
        })
      }
      console.log('EVWNT', value)
    },
    [currentProject, user, selectedContent, updateArticle, currentAccount],
  )

  const handleChangeTone = useCallback(
    (value: string | undefined) => {
      if (value?.length) {
        const articleData = { ...selectedContent }

        articleData.toneOfVoice = value

        updateArticle(articleData)
        customEvent('content_tone_of_voice_changed', {
          category: 'content',
          contentId: selectedContent?.id,
          user_userId: user.uid,
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
          actionSource: selectedContent?.toneOfVoice,
          actionDestination: value,
        })
      }
    },
    [currentProject, user, selectedContent, updateArticle, currentAccount],
  )

  const handleContentLength = useCallback(
    (value: string | undefined) => {
      if (value?.length) {
        const articleData = { ...selectedContent }
        const destLength =
          value === '500-1000'
            ? 'short'
            : value === '1000-2000'
              ? 'medium'
              : 'long'

        const actionLength =
          selectedContent?.contentLength === '500-1000'
            ? 'short'
            : selectedContent?.contentLength === '1000-2000'
              ? 'medium'
              : 'long'

        articleData.contentLength = value

        updateArticle(articleData)

        customEvent('content_tone_of_voice_changed', {
          category: 'content',
          contentId: selectedContent?.id,
          user_userId: user.uid,
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
          actionSource: actionLength,
          actionDestination: destLength,
        })
      }
    },
    [currentProject, user, selectedContent, updateArticle],
  )

  return (
    <div className="px-4 py-3 w-full min-h-screen">
      <h2 className="text-base font-sans font-medium text-blue-gray-900">
        Version
      </h2>
      {contentVersions && (
        <Select
          onChange={handleChangeVersion}
          labelProps={{
            className: 'hidden',
          }}
          value={activeVersion}
          placeholder={undefined}
          className="!border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100 my-0 pt-0"
        >
          {contentVersions?.map((vr: string, i: number) => {
            console.log('VRRR', vr, activeVersion)
            return (
              <Option key={i} value={vr}>
                {vr}
              </Option>
            )
          })}
        </Select>
      )}

      <h2 className="text-base font-sans font-medium text-blue-gray-900 my-2">
        Status
      </h2>
      <Select
        onChange={handleChangeStatus}
        labelProps={{
          className: 'hidden',
        }}
        placeholder={undefined}
        className="!border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100 pt-0"
        value={selectedContent?.status}
      >
        {STATUS_MAP?.map((sts) => (
          <Option key={sts.value} value={sts.value}>
            {sts.name}
          </Option>
        ))}
      </Select>
      <div className="-mx-4 border-b-2 my-3" />
      <div className="flex align-center">
        <h2 className="text-lg font-sans font-bold">Content Creation</h2>
        <CustomTooltip
          content={'Details of content'}
          className="mt-3 bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
        >
          <img src={infoIcon} className="h-4 w-4 mt-[6px] ml-2" />
        </CustomTooltip>
      </div>

      <h2 className="text-base font-sans font-medium text-blue-gray-900">
        Title
      </h2>
      <Input
        placeholder="Title of content..."
        className=" !border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100"
        crossOrigin={undefined}
        labelProps={{
          className: 'hidden m-0 p-0',
        }}
        defaultValue={selectedContent?.title}
        onChange={handleChangeTitle}
      />
      <h3 className="text-base font-sans font-medium text-blue-gray-900 my-2">
        {`Additional info (optional)`}
      </h3>
      <Textarea
        className=" !border-blue-gray-100 focus:!border-gray-300  placeholder:text-blue-gray-300 placeholder:opacity-100"
        labelProps={{
          className: 'hidden',
        }}
        placeholder="Lorem ipsum dolor sit amet consectetur. Quis sagittis vitae ac sed pellentesque ipsum quam."
        value={selectedContent?.description}
        onChange={handleChangeDescription}
      />

      <h2 className="text-base font-sans font-medium text-blue-gray-900 my-2">
        Tone
      </h2>
      <Select
        onChange={handleChangeTone}
        labelProps={{
          className: 'hidden',
        }}
        placeholder={undefined}
        className="!border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100 pt-0"
        value={selectedContent?.toneOfVoice || TONE_OF_VOICE_MAP[0].value}
      >
        {TONE_OF_VOICE_MAP?.map((tone) => (
          <Option key={tone.value} value={tone.value}>
            {tone.name}
          </Option>
        ))}
      </Select>

      <h2 className="text-base font-sans font-medium text-blue-gray-900 my-2">
        Content Length
      </h2>
      <Select
        onChange={handleContentLength}
        labelProps={{
          className: 'hidden',
        }}
        placeholder={undefined}
        className="!border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100 pt-0"
        value={selectedContent?.contentLength || CONTENT_LENGTH_MAP[1].value}
      >
        {CONTENT_LENGTH_MAP?.map((content) => (
          <Option key={content.value} value={content.value}>
            {content.name}
          </Option>
        ))}
      </Select>
      <div className="mt-6 w-full flex justify-center items-center">
        <TailwinButton
          variant="filled"
          size="lg"
          onClick={handleRegenerateContent}
          className="text-white bg-lstnGreen-500 w-full"
        >
          {/* <img src={exportFile} className="mr-1" /> */}
          Rewrite
        </TailwinButton>
      </div>
    </div>
  )
}

export default memo(AboutContent)
