import React from 'react'
// import React, { useEffect, useCallback} from "react";
// import ReactGA from "react-ga4";
// import { customEvent } from "../utils/customHooks";
// import { withRouter, RouteComponentProps } from 'react-router-dom'

// interface Location {
//   pathname: string;
// }

// interface Router {
//   history: {
//     location: Location;
//     listen(callback: (location: Location) => void): () => void;
//   };
// }

// const RouterContext = React.createContext<Router | undefined>(undefined);

interface GAListenerProps {
  children: React.ReactNode
}

function GAListener({ children }: GAListenerProps) {
  // const router = useContext(RouterContext);

  // const sendPageView = useCallback((location: Location) => {
  //   customEvent("pageview", {
  //     app_name: "LSTN",
  //     screen_name: location.pathname,
  //   })
  // }, []);

  // useEffect(() => {
  // let unlisten: () => void;
  // if (location) {
  // sendPageView(router.history.location);
  // unlisten = router.history.listen(sendPageView);

  //  sendPageView(location);
  // }

  // return () => {
  //   if (unlisten) {
  //     unlisten();
  //   }
  // };

  // }, [location]);

  return <>{children}</>
}

export default GAListener
