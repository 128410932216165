import React, { memo } from 'react'
import { Select, Option, Typography } from '@material-tailwind/react'
import type { SelectProps } from '@material-tailwind/react'

interface IProps {
  accounts: Array<{
    accountId: string
    projectId: string
    accountName: string
    projectName: string
  }>
  selectedAccount?: string
  selectClassName?: string
  onChange: (value: string) => void
}

const Selector: React.FC<IProps & SelectProps> = (props) => {
  return (
    <div className="w-auto">
      <Select
        size="lg"
        onChange={props.onChange}
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        className={props.selectClassName}
        value={props.selectedAccount}
        labelProps={{
          className: 'hidden',
        }}
        containerProps={{
          className: props.className,
        }}
        placeholder={undefined}
        data-cy="data-account-select"
      >
        {props.accounts &&
          props.accounts.map((account) => (
            <Option
              key={account.projectId}
              value={account.projectId}
              className="z-10"
            >
              <Typography
                className="font-bold text-lstnBlueGray-900 text-lg"
                placeholder={undefined}
              >
                {account.projectName}
              </Typography>
              <Typography
                className="font-medium text-lstnBlueGray-500 text-md"
                placeholder={undefined}
              >
                {account.accountName}
              </Typography>
            </Option>
          ))}
      </Select>
    </div>
  )
}

export default memo(Selector)
