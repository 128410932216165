import React from 'react'
import Avatar from '@material-ui/core/Avatar'

interface IUserProfileAvatar {
  userName: string
  avatarUrl?: string
  font?: string
  width?: string
  height?: string
}

const UserProfileAvatar = ({
  userName,
  avatarUrl,
  font,
  width,
  height,
}: IUserProfileAvatar): JSX.Element => {
  const getInitials = (name: string): string => {
    return name
      .split(' ')
      .map((part) => part[0])
      .join('')
      .toUpperCase()
  }
  return (
    <Avatar
      alt={userName}
      src={avatarUrl}
      style={{
        backgroundColor: '#9C47ED',
        width: `${width}`,
        height: `${height}`,
        fontSize: `${font}`,
      }}
    >
      {getInitials(userName)}
    </Avatar>
  )
}

export default UserProfileAvatar
