import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react'
import { Editor, Path, Transforms, createEditor, Range } from 'slate'
import { withHistory } from 'slate-history'
import {
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button as TailwindButton,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'

import { useTranslation } from 'react-i18next'
import firebase from 'firebase/compat/app'
import { useNavigate } from 'react-router'

import { Button, Icon } from './components'
import Typography from '../Typography/Typography'
import CustomTooltip from '../Tooltip/Tooltip'
import { PublishIcon } from '../SvgIcons'
import withLinks from './withLinks'
import Link from './Link'
import Toast from '../Toast/Toast'

import { slateToMarkdown } from '../../utils/slateParse'
import { markdownToSlate } from '../../utils/markdownToSlate'

import Undo from '../../icons/Undo.svg'
import Redo from '../../icons/Redo.svg'
import exportFile from '../../icons/export.svg'
import verticalDotIcon from '../../icons/vertical-dots.svg'

import { customEvent } from '../../utils/customHooks'
import { useAppSelector } from '../../store/store'

import './Toolbar.css'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list', 'link']

interface IEditorProps {
  sectionContent: { content: string; active: boolean }
  updateSection: (value: string, version: string) => void
  toolbarId?: string
  sectionNum?: number
  handleContentCopy: (text: string) => void
  handleDeleteContent: () => void
  isRegenerated: boolean
  isContentLoading: boolean
  setIsRegenerated: (status: boolean) => void
  contentVersion: string
  slateToDocx: (content: string, fileName: string) => void
}

interface IWordpressCred {
  domain: string
  credentials: { token: string; username: string }
}

const RichTextExample: React.FC<IEditorProps> = ({
  sectionContent,
  updateSection,
  setIsRegenerated,
  isRegenerated,
  contentVersion,
  handleContentCopy,
  handleDeleteContent,
  isContentLoading,
  slateToDocx,
}) => {
  const [value, setValue] = useState(initialValue)
  const [isWPModalOpen, setIsWPModalOpen] = useState(false)
  const [wordpressCred, setWordpressCred] = useState<IWordpressCred>()
  const [isWPPublishModalOpen, setIsWPPublishModalOpen] = useState(false)
  const { currentProject } = useAppSelector((state) => state.project)
  const { selectedContent } = useAppSelector((state) => state.content)
  const { currentAccount } = useAppSelector((state) => state.account)
  const { user } = useAppSelector((state) => state.user)

  const navigate = useNavigate()

  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
  const editor = useMemo(
    () => withHistory(withReact(withLinks(createEditor()))),
    [],
  )
  const { t } = useTranslation()

  const handleExport = useCallback(async () => {
    const fileName = `${currentProject.id}.docx`

    const formatted = markdownToSlate(sectionContent.content)
    if (firebase.auth().currentUser) {
      Toast({
        title: 'Please wait',
        text: 'Your file is downloading',
        variant: 'success',
      })
      slateToDocx(formatted, fileName)
    }
  }, [sectionContent])

  const handleChangeText = async (val: any) => {
    const initVal = JSON.stringify(value)
    const slateVal = JSON.stringify(val)

    if (initVal !== slateVal) {
      setValue(val)
      const marked = await slateToMarkdown(val)
      const stringf = JSON.stringify(marked)

      customEvent('editor_words_changed', {
        category: 'content',
        contentId: selectedContent.id,
        user_userId: user.uid,
        actionMetadata:
          stringf?.length > sectionContent?.content?.length
            ? 'character-added'
            : 'character-removed',
      })

      updateSection(stringf, contentVersion)
    }
  }

  const handleCopy = () => {
    const marked = slateToMarkdown(value)
    handleContentCopy(marked)
  }

  const handleUndo = (editor: any) => () => {
    editor.undo()
    customEvent('editor_action_undo', {
      category: 'content',
      contentId: selectedContent.id,
      user_userId: user.uid,
    })
  }

  const handleRedo = (editor: any) => () => {
    editor.redo()

    customEvent('editor_action_redo', {
      category: 'content',
      contentId: selectedContent.id,
      user_userId: user.uid,
    })
  }

  useEffect(() => {
    if (isRegenerated && sectionContent?.content) {
      const formatted = markdownToSlate(sectionContent?.content)
      setValue(formatted)
      setIsRegenerated(false)
    }
  }, [isRegenerated, sectionContent])

  useEffect(() => {
    if (sectionContent?.content) {
      const formatted = markdownToSlate(sectionContent?.content)
      setValue(formatted)
    }
  }, [sectionContent, setValue])

  const handlePublish = useCallback(async () => {
    if (firebase.auth().currentUser) {
      const fetchedCred = currentAccount?.integrations?.find((intg) => {
        if (intg?.type === 'wordpress-org') {
          return intg
        }
      })

      if (!fetchedCred) {
        setIsWPModalOpen(true)
        return
      }
      setWordpressCred(fetchedCred)
      setIsWPPublishModalOpen(true)
    }
  }, [selectedContent])

  const handleCloseModal = useCallback(
    (type: string) => () => {
      if (type === 'integration') {
        setIsWPModalOpen(false)
      } else {
        setIsWPPublishModalOpen(false)
      }
    },
    [setIsWPModalOpen, setIsWPPublishModalOpen],
  )

  const handleNavigateIntegration = useCallback(() => {
    setIsWPModalOpen(false)
    navigate('/integrations')
  }, [setIsWPModalOpen, navigate])

  const handlePublishConfirm = useCallback(async () => {
    const token = await firebase.auth().currentUser?.getIdToken()
    setIsWPPublishModalOpen(false)

    Toast({
      title: 'Please wait',
      text: 'Your article is publishing',
      variant: 'success',
    })

    fetch(`${process.env.REACT_APP_FIREBASE_API}/publishBlog`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: sectionContent?.content,
        title: selectedContent?.title,
        accountId: currentAccount?.id,
        slug: selectedContent?.slug,
        contentId: selectedContent?.id,
        status: 'publish',
        type: 'wordpress-org',
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error('Failed to convert to DOCX')
        }
        const result = await response.json()
        console.log('**** result -->', result)

        Toast({
          title: 'Success',
          text: 'Your article has been published',
          variant: 'success',
        })
      })
      .catch((error) => {
        console.error('Error:', error)
        Toast({
          title: 'Error',
          text: 'Please try again or contact support',
          variant: 'error',
        })
      })
  }, [selectedContent, currentAccount, currentProject])

  function isPreviousListItemEmpty(editor: any, path: any) {
    const prevNode = Editor.previous(editor, { at: path })
    if (prevNode) {
      const [prevMatch] = prevNode
      return prevMatch?.type === 'list-item' || isEmptyListItem(prevMatch)
    }
    return false // No previous item, assume not empty
  }

  function isEmptyListItem(listItem: any) {
    const isEmp = listItem.children.every((child: any) => child.text === '')
    return isEmp
  }

  return (
    <div className="mb-8">
      <Slate editor={editor} value={value} onChange={handleChangeText}>
        <div className="flex justify-between mx-[5%]">
          <div className="flex items-center gap-1.5">
            <CustomTooltip
              content={t('shortcutDialog.undo')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  size="md"
                  variant="outlined"
                  className={`w-11 h-10 border border-wellBlueGray-${
                    !editor.history || editor.history.undos.length < 1
                      ? '200'
                      : '500'
                  } rounded-lg`}
                  disabled={!editor.history || editor.history.undos.length < 1}
                  onClick={handleUndo(editor)}
                  data-cy="history.undo-button"
                  placeholder={undefined}
                >
                  <img
                    src={Undo}
                    className={`w-4 h-3.5 color-wellBlueGray-${
                      !editor.history || editor.history.redos.length < 1
                        ? '500'
                        : '200'
                    }`}
                    alt="img"
                  />
                </IconButton>
              </span>
            </CustomTooltip>
            <CustomTooltip
              content={t('shortcutDialog.redo')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  size="md"
                  variant="outlined"
                  className={`w-11 h-10 border border-wellBlueGray-${
                    !editor.history || editor.history.redos.length < 1
                      ? '200'
                      : '500'
                  } rounded-lg`}
                  disabled={!editor.history || editor.history.redos.length < 1}
                  onClick={handleRedo(editor)}
                  data-cy="history.redo-button"
                  placeholder={undefined}
                >
                  <img
                    src={Redo}
                    className={`w-4 h-3.5 color-wellBlueGray-${
                      !editor.history || editor.history.redos.length < 1
                        ? '500'
                        : '200'
                    }`}
                    alt="img"
                  />
                </IconButton>
              </span>
            </CustomTooltip>
            <div className="border p-2 border-wellBlueGray-300 rounded-lg flex">
              <div className="mr-6">
                <MarkButton format="bold" icon="format_bold" />
                <MarkButton format="italic" icon="format_italic" />
                <MarkButton format="underline" icon="format_underlined" />
              </div>
              {/* <div className="mr-6"> */}
              <BlockButton format="numbered-list" icon="format_list_numbered" />
              <BlockButton format="bulleted-list" icon="format_list_bulleted" />
              <BlockButton
                format="align-left"
                icon="format_align_left"
                classes="mr-6"
              />
              {/* </div> */}
              <div>
                <BlockButton format="link" icon="insert_link" />
                {/* <BlockButton format="image" icon="image" />
                <BlockButton format="video" icon="theaters" /> */}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start m-0 p-0 gap-4">
            <div data-tut="Export-tour">
              <Menu>
                <MenuHandler>
                  <TailwindButton
                    variant="outlined"
                    size="sm"
                    // onClick={handleExport}
                    className="text-lstnGreen-500 border-lstnGreen-500 border flex"
                    placeholder={undefined}
                  >
                    <img src={exportFile} className="mr-1" />
                    Export
                  </TailwindButton>
                </MenuHandler>
                <MenuList placeholder={undefined}>
                  <MenuItem placeholder={undefined} onClick={handleExport}>
                    Export as Word File
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
            <div data-tut="Publish-tour">
              <TailwindButton
                variant="filled"
                size="sm"
                onClick={handlePublish}
                className="text-white bg-lstnGreen-500 flex"
                placeholder={'upgrade'}
              >
                <div className="mt-[1px] mr-[2px]">
                  <PublishIcon />
                </div>
                Publish
              </TailwindButton>
            </div>

            <span>
              <Menu allowHover>
                <MenuHandler>
                  <Button variant="text" className="min-w-0 h-auto p-0">
                    <img src={verticalDotIcon} />
                  </Button>
                </MenuHandler>
                <MenuList placeholder={undefined}>
                  <MenuItem placeholder={undefined} onClick={handleCopy}>
                    Copy content
                  </MenuItem>
                  <MenuItem
                    placeholder={undefined}
                    onClick={handleDeleteContent}
                  >
                    Delete Content
                  </MenuItem>
                </MenuList>
              </Menu>
            </span>
          </div>
        </div>

        {/* <Toolbar className="flex">
          <div className="mr-6">
            <BlockButton format="undo" icon="undo" />
            <BlockButton format="redo" icon="redo" />
            <MarkButton format="bold" icon="format_bold" />
            <MarkButton format="italic" icon="format_italic" />
            <MarkButton format="underline" icon="format_underlined" />
          </div>
          <div className="mr-6">
            <BlockButton format="numbered-list" icon="format_list_numbered" />
            <BlockButton format="bulleted-list" icon="format_list_bulleted" />
            <BlockButton format="align-left" icon="format_align_left" />
          </div>
          <div className="mr-6">
            <BlockButton format="link" icon="insert_link" />
            <BlockButton format="image" icon="image" />
            <BlockButton format="video" icon="theaters" />
          </div>
          <div className="">
            <MarkButton format="code" icon="code" />
          </div>
        </Toolbar> */}
        {isContentLoading ? (
          <div className="w-full animate-pulse flex flex-col justify-center p-4">
            <Typography
              as="div"
              variant="h1"
              className="mb-4 h-3 w-[60%] rounded-full bg-gray-300"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-[70%] rounded-full bg-gray-300"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-[80%] rounded-full bg-gray-300"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-[90%] rounded-full bg-gray-300"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-full rounded-full bg-gray-300"
            >
              &nbsp;
            </Typography>
          </div>
        ) : (
          <div
            className="shadow p-4 mt-12 border rounded-lg bg-white"
            data-tut="Paragraph-tour"
          >
            <Editable
              className="p-4"
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Enter some rich text…"
              spellCheck
              autoFocus
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  // const { selection } = editor;
                  const [match] = Editor.nodes(editor, {
                    match: (n) => n.type === 'list-item',
                  })
                  if (match) {
                    const path = match[1]
                    const listItem = match[0]

                    // Check if current list item and the previous one are empty
                    if (
                      isEmptyListItem(listItem) &&
                      isPreviousListItemEmpty(editor, path)
                    ) {
                      event.preventDefault()
                      // Convert both to paragraph and unwrap list
                      Transforms.setNodes(
                        editor,
                        { type: 'paragraph' },
                        { at: path },
                      )
                      Transforms.unwrapNodes(editor, {
                        match: (n: any) =>
                          ['bulleted-list', 'numbered-list'].includes(n.type),
                        split: true,
                      })
                      return
                    }

                    // Other logic...

                    for (const hotkey in HOTKEYS) {
                      if (isHotkey(hotkey, event)) {
                        event.preventDefault()
                        const mark = HOTKEYS[hotkey]
                        toggleMark(editor, mark)
                      }
                    }
                  }
                }
              }}
            />
          </div>
        )}
      </Slate>
      <>
        <Dialog
          size="xs"
          open={isWPModalOpen}
          placeholder={undefined}
          handler={handleCloseModal('integration')}
        >
          <DialogHeader className="mx-4 mt-2" placeholder={undefined}>
            Publish Content
          </DialogHeader>
          <DialogBody placeholder={undefined} className="mt-0 mx-4">
            <Typography className="text-lstnBlueGray-500 text-lg font-normal">
              Connect your Wordpress website to WellShared to start publishing
              your created content in a click. It takes 1 minutes
            </Typography>
          </DialogBody>
          <DialogFooter placeholder={undefined}>
            <Button
              variant="text"
              // color="blue-gray-900"
              onClick={handleCloseModal('integration')}
              data-cy="data-project-delete-cancel"
              className="mr-1 uppercase"
            >
              <Typography className="text-lstnBlueGray-500 font-bold text-xs">
                Cancel
              </Typography>
            </Button>
            <TailwindButton
              // variant="text"
              onClick={handleNavigateIntegration}
              data-cy="data-project-delete-submit"
              className="uppercase text-white bg-lstnGreen-500 ml-4 px-4 hover:bg-lstnGreen-400"
              placeholder={'Connect Wordpress'}
            >
              <span>Connect Wordpress</span>
            </TailwindButton>
          </DialogFooter>
        </Dialog>
        <Dialog
          size="xs"
          open={isWPPublishModalOpen}
          placeholder={undefined}
          handler={handleCloseModal('publish')}
        >
          <DialogHeader placeholder={undefined} className="mx-4 mt-2">
            Publish Content
          </DialogHeader>
          <DialogBody placeholder={undefined} className="mt-0 mx-4">
            <Typography className="text-lstnBlueGray-500 text-lg font-normal">
              {`Automatically create a blog post in your connected wordpress site
              ”${wordpressCred?.domain}” using the blog slug ”${selectedContent?.slug}”. If
              you already published this article, content will be overwritten.`}
            </Typography>
          </DialogBody>
          <DialogFooter placeholder={undefined}>
            <Button
              variant="text"
              // color="blue-gray-900"
              onClick={handleCloseModal('publish')}
              data-cy="data-project-delete-cancel"
              className="mr-1 uppercase"
            >
              <Typography className="text-lstnBlueGray-500 font-bold text-xs">
                {t('renameDialog.cancel')}
              </Typography>
            </Button>
            <TailwindButton
              variant="text"
              onClick={handlePublishConfirm}
              data-cy="data-project-delete-submit"
              className="uppercase text-white bg-lstnGreen-500 ml-4 px-4 hover:bg-lstnGreen-400"
              placeholder={'Publish Blog'}
            >
              <span>Publish</span>
            </TailwindButton>
          </DialogFooter>
        </Dialog>
      </>
    </div>
  )
}

const handleInsertLink = (editor: any) => {
  const url = prompt('Enter a URL')
  insertLink(editor, url)
}

const createLinkNode = (href: string, text: string) => ({
  type: 'link',
  href,
  children: [{ text }],
})

const removeLink = (editor: any) => {
  Transforms.unwrapNodes(editor, {
    split: true,
    match: (n) => n.type === 'link',
  })
}

const createParagraphNode = (type: any, children = [{ text: '' }]) => ({
  type: type ? type : 'paragraph',
  children,
})

const insertLink = (editor: any, url: string | null) => {
  if (!url) return

  const { selection } = editor
  const link = createLinkNode(url, 'New Link')

  ReactEditor.focus(editor)

  if (selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path,
    )
    if (parentNode.type === 'link') {
      removeLink(editor)
    }

    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(editor, createParagraphNode([link]), {
        at: Path.next(parentPath),
        select: true,
      })
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, link, { select: true })
    } else {
      Transforms.wrapNodes(editor, link, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, createParagraphNode([link]))
  }
}

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type as string),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive.match ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive.match && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format)

  if (isActive?.match) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: any, format: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  })

  // Check if the block is a heading
  const [headingMatch] = Editor.nodes(editor, {
    match: (n) =>
      n.type === 'heading-three' ||
      n.type === 'heading-four' ||
      n.type === 'heading-five',
  })

  if (headingMatch) {
    return { headingSpotted: true, match: !!match }
  }

  return { headingSpotted: false, match: !!match }
}

const isMarkActive = (editor: any, format: any) => {
  const marks = Editor.marks(editor)

  const [headingMatch] = Editor.nodes(editor, {
    match: (n) =>
      n.type === 'heading-three' ||
      n.type === 'heading-four' ||
      n.type === 'heading-five',
  })

  if (headingMatch) {
    return {
      headingSpotted: true,
      match: marks ? marks[format] === true : false,
    }
  }

  return {
    headingSpotted: false,
    match: marks ? marks[format] === true : false,
  }
}

const Element = (props: any) => {
  const { attributes, element, children } = props
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return <Link {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon, classes }: any) => {
  const editor = useSlate()
  const isButtonActive = isBlockActive(editor, format)
  return (
    <Button
      className={`${classes} mr-1`}
      active={isButtonActive}
      onMouseDown={(event: any) => {
        event.preventDefault()
        if (format === 'link' && isButtonActive?.headingSpotted) {
          handleInsertLink(editor)
        } else if (format === 'image' || format === 'video') {
          return
        } else {
          toggleBlock(editor, format)
        }
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      className="mr-1"
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const initialValue = [{ type: 'paragraph', children: [{ text: '' }] }]

export default RichTextExample
