import React, { useEffect, useState } from 'react'
import { Select, Option } from '@material-tailwind/react'

interface IProps {
  onSelect: (val: string) => void
}

const LanguageSelector: React.FC<IProps> = ({ onSelect }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  useEffect(() => {
    const storedLanguage =
      localStorage.getItem('wellshared-display-lng') || 'en'
    setSelectedLanguage(storedLanguage)
  }, [])

  const languages = [
    { value: 'en', label: 'English' },
    { value: 'it', label: 'Italian' },
    { value: 'es', label: 'Spanish' },
  ]

  return (
    <div className="flex w-24 mr-2 selectIcon border-none">
      <Select
        // @ts-ignore
        onChange={onSelect}
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        className="w-full border-none relative text-sm font-normal"
        value={selectedLanguage}
        children={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {languages &&
          languages.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          ))}
      </Select>
    </div>
  )
}

export default LanguageSelector
