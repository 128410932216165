import React, { useEffect } from 'react'
// import { withRouter } from 'react-router'

import Portal from './Portal'
import { withTranslation, TransProps } from 'react-i18next'
import { compose } from 'redux'
import { useCallback } from 'react'
import { TFunction } from 'react-i18next'
//@ts-ignore
interface IProps extends TransProps {
  handlePrimaryAction: () => void
  handleSecondaryAction?: () => void
  heading: string
  description: string
  t: TFunction
}

const FileTypeErrorModalWindow: React.FC<IProps> = ({
  handlePrimaryAction,
  handleSecondaryAction,
  heading,
  description,
  t,
}) => {
  const preventScreenScrolling = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  useEffect(() => {
    document.body.addEventListener('scroll', preventScreenScrolling)

    return () => {
      document.body.removeEventListener('scroll', preventScreenScrolling)
    }
  }, [])
  return (
    <Portal>
      <div className="modal_overlay">
        <div className="modal_container">
          <div className="modal_header">{heading}</div>
          <div className="modal_content">{description}</div>
          <div className="modal_action-footer">
            <button onClick={handlePrimaryAction} className="org-btn lstn-btn">
              {t && t('modal.upgrade')}
            </button>
            {handleSecondaryAction && (
              <button
                onClick={handleSecondaryAction}
                className="org-btn org-btn--secondary"
              >
                {t && t('modal.cancel')}
              </button>
            )}
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default compose<React.ElementType>(withTranslation())(
  FileTypeErrorModalWindow,
)
