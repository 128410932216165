import React from 'react'

const TidycalIframe = (): JSX.Element => (
  <iframe
    src={process.env.REACT_APP_TIDYCALL_URL}
    title="Tidycal Booking"
    width="100%"
    height="650"
    frameBorder="0"
  />
)

export default TidycalIframe
