import React from 'react'
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react'

interface CustomMenuProps {
  menuHandlerIcon: JSX.Element | string
  menuitems: {
    label: string
    icon?: JSX.Element
    itemClick: () => Promise<void>
  }[]
}

const CustomMenu: React.FC<CustomMenuProps> = ({
  menuHandlerIcon,
  menuitems,
}) => {
  return (
    <Menu placement="bottom-start" allowHover={true}>
      <MenuHandler>
        <Button
          placeholder={undefined}
          variant="text"
          className="min-w-0 h-auto shadow-none hover:shadow-none p-0 m-0 hover:bg-white"
        >
          {menuHandlerIcon}
        </Button>
      </MenuHandler>
      <MenuList placeholder={undefined} className="w-4">
        {menuitems.map((item) => (
          <MenuItem
            key={item.label}
            placeholder={undefined}
            onClick={item.itemClick}
          >
            <Typography
              className="text-lstnBlueGray-500 font-medium text-base flex gap-2"
              placeholder={item.label}
            >
              {item.icon && item.icon}
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default CustomMenu
