import React, { useState } from 'react'
import { TransProps } from 'react-i18next'
import '../css/Projects.css'
import { useAppSelector } from '../store/store'
// import SplashScreen from '../components/SplashScreen'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-tailwind/react'
import Button from '../components/Button/TailwindButton'
import UploadIcon from '../icons/Upload.svg'
import CreateProjectDialog from '../components/Projects/CreateProjectDialog'
import ProjectCard from '../components/Projects/ProjectCard'
import {
  addProject,
  editProject,
  archiveProject,
} from '../store/features/projectSlice'
import { customEvent } from '../utils/customHooks'

const Projects: React.FC<
  //@ts-ignore
  TransProps
> = () => {
  const { user, userData } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { projects, roles } = useAppSelector((store) => store.project)
  const { t } = useTranslation()
  const [openCreateProject, setOpenCreateProject] = useState(false)
  const [openEditProject, setOpenEditProject] = useState(false)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string | undefined>('')
  const [projectId, setProjectId] = useState<string>('')

  const filteredProjects = projects.filter((project) => project.users[user.uid])

  const handleOpenCreate = (): void => {
    setOpenCreateProject(!openCreateProject)
  }

  const handleCreateProject = async (
    name: string,
    description?: string,
  ): Promise<void> => {
    customEvent('project_create_requested', {
      accountId: currentAccount.id,
      user_userId: user.uid,
    }),
      await addProject(currentAccount.id, name, user.uid, description)
    handleOpenCreate()
  }

  const handleOpenEdit = (): void => {
    setOpenEditProject(!openEditProject)
  }

  const handleEdit = (id: string, name: string, description?: string): void => {
    setProjectId(id)
    setName(name)
    setDescription(description)
    handleOpenEdit()
  }

  const handleEditProject = async (
    id: string,
    name: string,
    description?: string,
  ): Promise<void> => {
    await editProject(id, name, description)
    handleOpenEdit()
  }

  const handleArchive = async (id: string): Promise<void> => {
    await archiveProject(id)
    customEvent('project_archived', {
      accountId: currentAccount.id,
      projectId: id,
      actionSource: 'project-card',
      user_userId: userData.id,
    })
  }

  return (
    <main className="projects-container flex flex-col">
      {user && user.uid ? (
        <>
          <div className="flex justify-between items-center">
            <Typography
              placeholder="Your Projects"
              className="text-2xl text-blue-gray-900 font-medium"
            >
              {t && t('projects.title')}
            </Typography>
            <Button
              color="green"
              variant="filled"
              className="py-2 font-sans"
              onClick={handleOpenCreate}
            >
              <span className="text-base pr-4">+</span>
              {t('projects.addProject')}
            </Button>
          </div>
          {filteredProjects.length === 0 ? (
            <div className="flex flex-1 flex-col items-center justify-center h-full">
              <img src={UploadIcon} alt="Icon" className="mb-8" />
              <Typography
                className="text-2xl font-medium mb-3"
                placeholder="Your Projects"
              >
                {t('projects.getStarted')}
              </Typography>
              <Typography
                className="text-base font-normal text-lstnBlueGray-500 text-center mb-5"
                placeholder="Your Projects"
              >
                {t('projects.description')}
              </Typography>
              <Typography
                className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnGreen-500 flex items-center justify-center"
                placeholder="Your Projects"
                onClick={handleOpenCreate}
              >
                <div className="inline-block lstn-btn w-3 h-3 p-0 rounded-full text-center relative mr-1">
                  <svg width="12" height="12" aria-hidden="true">
                    <use xlinkHref="#icon-pluss" />
                  </svg>
                </div>
                {t('projects.createFirst')}
              </Typography>
            </div>
          ) : (
            <div className="justify-center flex flex-row flex-wrap xl:justify-between mb-6">
              {filteredProjects.map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  handleEdit={handleEdit}
                  handleArchive={handleArchive}
                  roles={roles}
                />
              ))}
            </div>
          )}
        </>
      ) : null}
      <CreateProjectDialog
        open={openCreateProject}
        handleOpen={handleOpenCreate}
        dialogType="create"
        handleCreate={handleCreateProject}
      />
      <CreateProjectDialog
        open={openEditProject}
        dialogType="edit"
        handleOpen={handleOpenEdit}
        handleEdit={handleEditProject}
        projectId={projectId}
        name={name}
        description={description}
      />
    </main>
  )
}

export default Projects
