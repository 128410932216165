import React from 'react'
import { IconButton } from '@material-tailwind/react'
import type { IconButtonProps } from '@material-tailwind/react'
import { ThemeProvider } from '@material-tailwind/react'

// interface CheckboxStylesType {
//   checkbox: {
//     defaultProps: {
//       color: string
//     }
//     styles: {
//       base: {
//         label: object
//       }
//       colors: object
//     }
//   }
// }
// interface props {
//   checked: boolean
//   uncheckedColor?: string
// }

export default function CustomIconButton(
  props: IconButtonProps,
): React.JSX.Element {
  const theme = {
    iconButton: {
      styles: {
        variants: {
          filled: {
            gray: {
              backgroud: 'bg-gray-500',
              color: 'text-white',
              shadow: 'shadow-md shadow-gray-500/20',
              hover: 'hover:shadow-lg hover:shadow-gray-500/40',
              focus: 'focus:shadow-none',
              active: 'active:shadow-none',
            },
          },
        },
      },
    },
  }

  return (
    <ThemeProvider value={theme}>
      {/* @ts-ignore */}
      <IconButton placeholder={undefined} {...props} />
    </ThemeProvider>
  )
}
