import React, { useCallback } from 'react'
import { Button, ButtonGroup, Tooltip } from '@material-tailwind/react'
import {
  // AnnotationIcon,
  InfoIcon,
  ChartIcon,
} from '../SvgIcons'
import CommentBox from './CommentBox'
import MenuHeader from './MenuHeader'
import AboutContent from './AboutContent'
import SEO from './SEO'
import { customEvent } from '../../utils/customHooks'
import { useAppSelector } from '../../store/store'

interface IGroupButtonProps {
  userId: string
  activeVersion: string
  setActiveVersion: (status: string) => void
  handleRegenerateContent: () => void
  contentVersions: string[]
}

const GroupButton: React.FC<IGroupButtonProps> = ({
  userId,
  activeVersion,
  setActiveVersion,
  handleRegenerateContent,
  contentVersions,
}) => {
  const [menuOne, setMenuOne] = React.useState(false)
  const [menuTwo, setMenuTwo] = React.useState(false)
  const [menuThree, setMenuThree] = React.useState(false)
  const [strokeColor1, setStrokeColor1] = React.useState('#607D8B')
  const [strokeColor2, setStrokeColor2] = React.useState('#607D8B')
  const [strokeColor3, setStrokeColor3] = React.useState('#607D8B')
  const [buttonBGColor1, setButtonBGColor1] = React.useState('bg-white')
  const [buttonBGColor2, setButtonBGColor2] = React.useState('bg-white')
  const [buttonBGColor3, setButtonBGColor3] = React.useState('bg-white')

  const { currentAccount } = useAppSelector((store) => store.account)

  console.log(strokeColor3, buttonBGColor3)

  const commentData = [
    {
      name: 'Tania Andrew',
      avatar: `${process.env.PUBLIC_URL}/Avatar.png`,
      date: '. 2 days ago',
      comment: 'I think this is great!',
    },
    {
      name: 'Vito',
      // avatar: AvatarLogo,
      date: '. 1 minute ago',
      comment:
        'Indeed, I am so glad he spoke about the need of safety considerations.',
    },
  ]
  const position = useCallback(() => {
    // setActiveVersion('v2')
    if (menuOne || menuTwo || menuThree) return 'relative'
    return 'absolute'
  }, [menuOne, menuTwo, menuThree])

  return (
    <>
      <div className={`flex mt-12 gap-3 ${position()}`}>
        {menuOne && (
          <div className="w-80 mb-auto bg-white rounded-lg shadow-lg">
            <MenuHeader
              setMenuOne={setMenuOne}
              setMenuTwo={setMenuTwo}
              setMenuThree={setMenuThree}
              setButtonBgColor1={setButtonBGColor1}
              setButtonBgColor2={setButtonBGColor2}
              setButtonBgColor3={setButtonBGColor3}
              setStrokeColor1={setStrokeColor1}
              setStrokeColor2={setStrokeColor2}
              setStrokeColor3={setStrokeColor3}
              headerName="About the content"
              userId={userId}
            />
            <AboutContent
              handleRegenerateContent={handleRegenerateContent}
              activeVersion={activeVersion}
              setActiveVersion={setActiveVersion}
              contentVersions={contentVersions}
            />
          </div>
        )}
        {menuTwo && (
          <div className="w-80 mb-auto bg-white rounded-lg shadow-lg">
            <MenuHeader
              setMenuOne={setMenuOne}
              setMenuTwo={setMenuTwo}
              setMenuThree={setMenuThree}
              setButtonBgColor1={setButtonBGColor1}
              setButtonBgColor2={setButtonBGColor2}
              setButtonBgColor3={setButtonBGColor3}
              setStrokeColor1={setStrokeColor1}
              setStrokeColor2={setStrokeColor2}
              setStrokeColor3={setStrokeColor3}
              headerName="SEO"
              userId={userId}
            />
            <SEO activeVersion={activeVersion} />
          </div>
        )}
        {menuThree && (
          <div className="w-80 mb-auto bg-white rounded-lg shadow-lg min-h-screen">
            <MenuHeader
              setMenuOne={setMenuOne}
              setMenuTwo={setMenuTwo}
              setMenuThree={setMenuThree}
              setButtonBgColor1={setButtonBGColor1}
              setButtonBgColor2={setButtonBGColor2}
              setButtonBgColor3={setButtonBGColor3}
              setStrokeColor1={setStrokeColor1}
              setStrokeColor2={setStrokeColor2}
              setStrokeColor3={setStrokeColor3}
              headerName="Comments"
              userId={userId}
            />
            <div className="bg-blue-gray-50 m-3 rounded-lg flex flex-col items-center p-5 gap-3">
              {commentData.map((comment, index) => (
                <CommentBox
                  key={index}
                  name={comment.name}
                  avatar={comment?.avatar}
                  date={comment.date}
                  comment={comment.comment}
                  length={commentData.length}
                  index={index + 1}
                />
              ))}
            </div>
          </div>
        )}
        <ButtonGroup
          className="flex-col w-14 h-32 mr-4 relative items-center justify-evenly bg-white border-2 rounded-lg border-lstnBlueGray-100 z-10"
          variant="text"
          placeholder={undefined}
        >
          <Tooltip
            content="About"
            placement="left"
            className="bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
            animate={{
              mount: { scale: 1, x: 0 },
              unmount: { scale: 0, x: 60 },
            }}
          >
            <Button
              className={`border-none shadow-none hover:shadow-none hover:bg-lstnGreen-50 px-4 ${buttonBGColor1}`}
              onClick={() => {
                setMenuOne((prevState) => !prevState)
                setMenuTwo(false)
                setMenuThree(false)
                setStrokeColor1(() => {
                  const color = !menuOne ? '#9C47ED' : '#607D8B'
                  return color
                })
                setStrokeColor2('#607D8B')
                setStrokeColor3('#607D8B')
                setButtonBGColor1(() => {
                  const color = !menuOne ? 'bg-lstnGreen-50' : 'bg-white'
                  return color
                })
                setButtonBGColor2('bg-white')
                setButtonBGColor3('bg-white')
                customEvent('actions_sidepanel_toggled', {
                  category: 'ActionsSidepanelEvents',
                  user_userId: userId,
                  accountId: currentAccount.id,
                  actionSource: 'action-info',
                  actionMetadata: menuOne ? 'closed' : 'opened',
                })
              }}
              placeholder={undefined}
            >
              <InfoIcon strokeColor={strokeColor1} />
            </Button>
          </Tooltip>
          <Tooltip
            content="SEO"
            placement="left"
            className="bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
            animate={{
              mount: { scale: 1, x: 0 },
              unmount: { scale: 0, x: 60 },
            }}
          >
            <Button
              className={`border-none shadow-none hover:shadow-none hover:bg-lstnGreen-50 px-4 ${buttonBGColor2}`}
              onClick={() => {
                setMenuTwo((prevState) => !prevState)
                setMenuOne(false)
                setMenuThree(false)
                setStrokeColor1('#607D8B')
                setStrokeColor2(() => {
                  const color = !menuTwo ? '#9C47ED' : '#607D8B'
                  return color
                })
                setStrokeColor3('#607D8B')
                setButtonBGColor1('bg-white')
                setButtonBGColor2(() => {
                  const color = !menuTwo ? 'bg-lstnGreen-50' : 'bg-white'
                  return color
                })
                setButtonBGColor3('bg-white')
                customEvent('actions_sidepanel_toggled', {
                  category: 'ActionsSidepanelEvents',
                  user_userId: userId,
                  accountId: currentAccount.id,
                  actionSource: 'action-seo',
                  actionMetadata: menuTwo ? 'closed' : 'opened',
                })
              }}
              placeholder={undefined}
            >
              <ChartIcon strokeColor={strokeColor2} />
            </Button>
          </Tooltip>
          {/* <Tooltip
            content="Comments"
            placement="left"
            className="bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
            animate={{
              mount: { scale: 1, x: 0 },
              unmount: { scale: 0, x: 60 },
            }}
          >
            <Button
              className={`border-none shadow-none hover:shadow-none hover:bg-lstnGreen-50 px-4 ${buttonBGColor3}`}
              onClick={() => {
                setMenuThree((prevState) => !prevState)
                setMenuOne(false)
                setMenuTwo(false)
                setStrokeColor1('#607D8B')
                setStrokeColor2('#607D8B')
                setStrokeColor3(() => {
                  const color = !menuThree ? '#9C47ED' : '#607D8B'
                  return color
                })
                setButtonBGColor1('bg-white')
                setButtonBGColor2('bg-white')
                setButtonBGColor3(() => {
                  const color = !menuThree ? 'bg-lstnGreen-50' : 'bg-white'
                  return color
                })
                customEvent('actions_sidepanel_toggled', {
                  category: 'ActionsSidepanelEvents',
                  user_userId: userId,
                  accountId: currentAccount.id,
                  actionSource: 'action-comments',
                  actionMetadata: menuThree ? 'closed' : 'opened',
                })
              }}
              placeholder={undefined}
            >
              <AnnotationIcon strokeColor={strokeColor3} />
            </Button>
          </Tooltip> */}
        </ButtonGroup>
      </div>
    </>
  )
}
export default GroupButton
