import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  List,
  ListItem,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import { Typeahead } from 'react-bootstrap-typeahead'
import { IProject, IUser } from '../../interfaces'
import UserProfileAvatar from '../UserProfileAvatar'
import Selector from '../SelectInput/Select'
import { editMemberRole } from '../../store/features/projectSlice'
import { useNavigate } from 'react-router-dom'
import { customEvent } from '../../utils/customHooks'

interface IProps {
  open: boolean
  handleOpen: () => void
  project: IProject
  members: IUser[]
  joinedMembers: Array<{
    id: string
    name: string
    email: string
    role: string
  }>
  addMember: (projectId: string, memberId: string, role: string) => void
  accountRole: string
  source: string
  userId: string
}

export default function AddDialog({
  open,
  handleOpen,
  members,
  joinedMembers,
  project,
  addMember,
  accountRole,
  source,
  userId,
}: IProps): React.JSX.Element {
  const { t } = useTranslation()
  const typeaheadInput = useRef(null)
  const [member, setMember] = useState<IUser[]>([])
  const [role, setRole] = useState('projectEditor')
  const [update, setUpdate] = useState(false)
  const [changedRoles, setChangedRoles] = useState<{
    [key: string]: { role: string }
  }>({})

  useEffect(() => {
    if (changedRoles === project.users) setUpdate(false)
  }, [changedRoles, project.users])

  useEffect(() => {
    if (open) {
      customEvent('project_share_dialog_opened', {
        accountId: project.accountId,
        projectId: project.id,
        actionSource: source,
        user_userId: userId,
      })
    }
  }, [project, userId, open, source])

  const navigate = useNavigate()

  const options = [
    {
      label: 'Viewer',
      value: 'projectViewer',
    },
    {
      label: 'Commenter',
      value: 'projectCommenter',
    },
    {
      label: 'Editor',
      value: 'projectEditor',
    },
    {
      label: 'Admin',
      value: 'projectAdmin',
    },
  ]

  const handleChangeRole = async (
    value: string,
    memberId: string,
  ): Promise<void> => {
    setChangedRoles((prevRoles) => ({
      ...prevRoles,
      [memberId]: { role: value },
    }))
    setUpdate(true)
  }

  const handleShare = (): void => {
    if (member.length !== 0) {
      addMember(project.id, member[0].id!, role)
    }
    handleOpen()
    setMember([])
    setChangedRoles({})
    if (update) {
      editMemberRole(project.id, changedRoles)
      for (const user of Object.keys(changedRoles)) {
        customEvent('project_collaborator_role_edited', {
          accountId: project.accountId,
          projectId: project.id,
          userRole: changedRoles[user].role,
          actionSource: `from-role-${project.users[user].role}`,
          user_userId: userId,
        })
      }
    }
    setUpdate(false)
  }

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      placeholder={undefined}
      size="sm"
      className="p-4"
    >
      <DialogHeader
        id="alert-dialog-slide-title"
        className="py-2 px-4 flex flex-col items-start"
        placeholder={undefined}
      >
        <Typography className="text-2xl font-semibold text-blue-gray-900">
          {t('addProjectMember.title')}
        </Typography>
        <Typography className="text-lg font-normal text-blue-gray-500">
          {t('addProjectMember.subHeading')}
        </Typography>
        {accountRole === 'owner' || accountRole === 'admin' ? (
          <Typography className="text-lg font-normal text-blue-gray-500">
            {t('addProjectMember.adminSubHeading')}
            <Typography
              className="text-lg font-normal text-blue-gray-500 underline hover:cursor-pointer inline-block"
              onClick={() => navigate('/account-settings?tab=members')}
            >
              {t('addProjectMember.link')}
            </Typography>
            .
          </Typography>
        ) : (
          <Typography className="text-lg font-normal text-blue-gray-500">
            {t('addProjectMember.nonAdminSubHeading')}
          </Typography>
        )}
      </DialogHeader>
      <DialogBody placeholder={undefined}>
        <Typography
          variant="h6"
          className="mb-2 text-sm font-medium text-lstnBlueGray-400"
        >
          {t && t('createProject.title')}
        </Typography>
        <Typeahead
          ref={typeaheadInput}
          filterBy={['name', 'email']}
          id="project-members"
          labelKey="email"
          onChange={(selected) => setMember(selected as IUser[])}
          options={members}
          placeholder="Name"
          selected={member}
          minLength={1}
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
            <div className="relative flex w-full items-center">
              {/* @ts-ignore */}
              <Input
                {...inputProps}
                ref={(node) => {
                  inputRef(node)
                  referenceElementRef(node)
                }}
                size="lg"
                // value={title}
                placeholder="Name"
                className=" !border-blue-gray-200 focus:!border-gray-400 placeholder:text-blue-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                style={{ borderRadius: '8px' }}
                // onChange={handleTitle}
                crossOrigin={undefined}
              />
              <Selector
                options={options}
                onChange={(value) => setRole(value!)}
                selectClassName="border-none"
                selectedValue={role}
                className="border-none text-xs w-24 min-w-[95px] !absolute right-0 top-0"
                menuClassName="min-w-[130px]"
                children={undefined}
              />
            </div>
          )}
          renderMenuItemChildren={({ name, email, profilePicture }: any) => {
            return (
              <div className="flex items-center mt-2">
                <UserProfileAvatar
                  userName={name}
                  avatarUrl={profilePicture}
                  width="36px"
                  height="36px"
                />
                <div className="ml-2">
                  <Typography className="text-sm font-medium">
                    {name}
                  </Typography>
                  <Typography className="text-xs font-normal">
                    {email}
                  </Typography>
                </div>
              </div>
            )
          }}
        />
        {joinedMembers.map((member) => (
          <List placeholder={undefined} key={member.id} className="p-0">
            <ListItem
              placeholder={undefined}
              className="flex justify-between px-0"
              ripple={false}
            >
              <div className="items-center">
                <Typography className="text-sm font-medium">
                  {member.name}
                </Typography>
                <Typography className="text-xs font-normal">
                  {member.email}
                </Typography>
              </div>
              <Selector
                options={options}
                onChange={(value) => handleChangeRole(value!, member.id!)}
                selectClassName="border-none"
                selectedValue={member.role}
                className="border-none text-xs w-24 min-w-[95px] right-0 top-0"
                menuClassName="min-w-[130px]"
                children={undefined}
              />
            </ListItem>
          </List>
        ))}
      </DialogBody>
      <DialogFooter placeholder={undefined} className="gap-0.5">
        <Button
          onClick={() => {
            handleOpen()
            setMember([])
            setChangedRoles({})
          }}
          variant="text"
          className="text-xs font-bold uppercase hover:bg-transparent"
          placeholder={undefined}
        >
          {t('createProject.cancel')}
        </Button>
        <Button
          onClick={() => handleShare()}
          className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
          placeholder={undefined}
          variant="filled"
          disabled={!(member.length !== 0 || update)}
        >
          {t('addProjectMember.shareBtn')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
