export const markdownToSlate = (markdown: string) => {
  const parseed = JSON.parse(markdown)
  // console.log('****** parseed', parseed)
  const lines = parseed.split('\n\n')
  const nodes: any = []
  let result = {}
  let currentList: { children?: any; type?: string } | null = null

  //   let resultBullet = {}
  let bulletList: { children?: any; type?: string } | null = null
  //   const length = lines.length

  lines.forEach((line: string) => {
    const trimmedLine = line.replace(/^\s+/, '')
    if (trimmedLine.startsWith('> ')) {
      const quoteText = trimmedLine.slice(2).trim()
      nodes.push({ type: 'block-quote', children: [{ text: quoteText }] })
    } else if (trimmedLine.startsWith('- ') || trimmedLine.startsWith('* ')) {
      const bulletLines = trimmedLine.split('\n')

      bulletLines?.forEach((bullLine) => {
        const formatedBulletLine = processParagraph(
          bullLine.replace(/\*\s+/, ''),
        )
        if (!bulletList) {
          bulletList = { type: 'bulleted-list', children: [] }
          bulletList.children.push({
            type: 'list-item',
            children: formatedBulletLine.length
              ? formatedBulletLine
              : [{ text: '' }],
          })
        } else {
          bulletList.children.push({
            type: 'list-item',
            children: formatedBulletLine.length
              ? formatedBulletLine
              : [{ text: '' }],
          })
        }
      })
      nodes.push(bulletList)
      bulletList = null

      // if (lines[length - 1] === line) {
      //   resultBullet = { ...bulletList }
      //   bulletList = null
      //   nodes.push(resultBullet)
      // } else if (!lines[i + 1].startsWith('* ')) {
      //   // nodes.push(result)
      //   resultBullet = { ...bulletList }
      //   bulletList = nulls
      //   nodes.push(resultBullet)
      // }
    } else if (trimmedLine.startsWith('1.')) {
      const numberedLines = trimmedLine.split('\n')
      numberedLines.forEach((numL) => {
        // const triNumLine = numL.replace(/^\s+/, '')
        const triNumLine = numL.replace(/^\d+\./, '')
        // const numLine = processParagraph(numL.replace(/^\d+\./, ''))
        const numLine = processParagraph(triNumLine.replace(/^\s+/, ''))
        if (!currentList || triNumLine.startsWith('1.')) {
          currentList = {
            type: 'numbered-list',
            children: [
              {
                type: 'list-item',
                children: numLine.length ? numLine : [{ text: '' }],
              },
            ],
          }
          result = { ...currentList }
          return
        } else {
          currentList.children.push({
            type: 'list-item',
            children: numLine.length ? numLine : [{ text: '' }],
          })
        }
      })
      nodes.push(result)
    } else if (trimmedLine.startsWith('# ')) {
      const heading = trimmedLine.slice(2)
      const heading1 = processParagraph(heading)
      nodes.push({
        type: 'heading-one',
        children: heading1.length ? heading1 : [{ text: '' }],
      })
    } else if (trimmedLine.startsWith('## ')) {
      const heading = trimmedLine.slice(3)
      const heading2 = processParagraph(heading)
      nodes.push({
        type: 'heading-two',
        children: heading2.length ? heading2 : [{ text: '' }],
      })
    } else if (trimmedLine.startsWith('### ')) {
      const heading = trimmedLine.slice(4)
      const heading3 = processParagraph(heading)
      nodes.push({
        type: 'heading-three',
        children: heading3.length ? heading3 : [{ text: '' }],
      })
    } else if (trimmedLine.startsWith('#### ')) {
      const heading = trimmedLine.slice(5)
      const heading4 = processParagraph(heading)
      nodes.push({
        type: 'heading-four',
        children: heading4.length ? heading4 : [{ text: '' }],
      })
    } else {
      const content = processParagraph(line)
      nodes.push({
        type: 'paragraph',
        children: content.length ? content : [{ text: '' }],
      })
    }
  })
  return nodes
}

const processParagraph = (markdown: string) => {
  const formatted = []
  let lastIndex = 0
  const regex =
    /(?:_([^_]+)_|\*\*([^*]+)\*\*|\[([^\]]+)]\(([^)]+)\)|([^_*\\[\\]]+)|\+\+([^+]+)\+\+|(<u>([^<]+)<\/u>)|(```([^`]+(?:``[^`]+)*)```))/g

  let match
  while ((match = regex.exec(markdown)) !== null) {
    const text =
      match[1] || match[2] || match[4] || match[6] || match[8] || match[9]
    const startIndex = match.index
    const simpleText = markdown.substring(lastIndex, startIndex)

    if (simpleText) {
      formatted.push({ text: simpleText })
    }

    if (match[1]) {
      if (match[1].startsWith('**') && match[2].endsWith('**')) {
        formatted.push({ text, italic: true, bold: true })
      } else if (match[1].startsWith('```') && match[1].endsWith('```')) {
        const codeIt = match[1].slice(3, -3)
        formatted.push({ text: codeIt, italic: true, code: true })
      } else {
        formatted.push({ text, italic: true })
      }
    } else if (match[2]) {
      if (match[2].startsWith('_') && match[2].endsWith('_')) {
        const boldIt = match[2].slice(1, -1)

        if (boldIt.startsWith('```') && boldIt.endsWith('```')) {
          const codeIt = match[2].slice(4, -4)
          formatted.push({ text: codeIt, bold: true, italic: true, code: true })
        } else {
          formatted.push({ text: boldIt, bold: true, italic: true })
        }
      } else if (match[2].startsWith('```') && match[2].endsWith('```')) {
        const boldCode = match[2].slice(3, -3)
        formatted.push({ text: boldCode, bold: true, code: true })
      } else {
        formatted.push({ text, bold: true })
      }
    } else if (match[3]) {
      let linkText = ''
      if (match[3].startsWith('**') && match[3].endsWith('**')) {
        const linkSlice = match[3].slice(2, -2)
        linkText = linkSlice
      } else if (match[3].startsWith('_') && match[3].endsWith('_')) {
        const linkSlice = match[3].slice(1, -1)
        linkText = linkSlice
      } else {
        linkText = match[3]
      }
      formatted.push({
        type: 'link',
        href: text,
        children: [{ text: linkText, bold: match[3].startsWith('**') }],
      })
    } else if (match[4]) {
      formatted.push({ text })
    }
    // else if (match[6]) {
    //   formatted.push({ text: match[6], underline: true })
    // }
    else if (match[8]) {
      if (match[8].startsWith('**') && match[8].endsWith('**')) {
        const underlineBold = match[8].slice(2, -2)
        if (underlineBold.startsWith('_') && underlineBold.endsWith('_')) {
          const underlineItalics = match[8].slice(3, -3)
          formatted.push({
            text: underlineItalics,
            underline: true,
            bold: true,
            italic: true,
          })
        } else {
          formatted.push({
            text: underlineBold,
            underline: true,
            bold: true,
          })
        }
      } else if (match[8].startsWith('_') && match[8].endsWith('_')) {
        const underlineIt = match[8].slice(1, -1)
        formatted.push({
          text: underlineIt,
          underline: true,
          italic: true,
        })
      } else {
        formatted.push({ text: match[8], underline: true })
      }
    } else if (match[9]) {
      formatted.push({ text: match[9], code: true })
    }

    lastIndex = startIndex + match[0].length
  }

  const remainingText = markdown.substring(lastIndex)
  if (remainingText) {
    formatted.push({ text: remainingText }) // needs to be improve
  }
  return formatted
}
