import React, { useCallback, useEffect, useState } from 'react'
// import ReactGA from "react-ga"
import { useNavigate } from 'react-router-dom'
import CrownIcon from '../../icons/Crown.svg'
import { IMetadata, ITranscript, IUserFeatures } from '../../interfaces'
import { IUserStatus } from '../../interfaces/IUser'
import { useTranslation } from 'react-i18next'
import {
  translationSourceLanguages,
  translationTargetLanguagesDeepL,
  unsupportedDeepLSourceLanguages,
} from '../../utils/translationLanguages'
import firebase from 'firebase/compat/app'
import Toast from '../Toast/Toast'
import { customEvent } from '../../utils/customHooks'
import { useAppSelector } from '../../store/store'
import downloadIcon from '../../icons/download.png'
import Selector from '../SelectInput/Select'
import Checkbox from '../Checkbox/Checkbox'
import Typography from '../Typography/Typography'
import {
  Menu,
  MenuHandler,
  MenuList,
  Button,
  Radio,
} from '@material-tailwind/react'

export interface ExportValues {
  fileType: 'docx' | 'xls' | 'srt' | 'xmp' | 'audio' | 'discovery_script'
  advancedOptions: {
    timeCodes: boolean
    speakerNames: boolean
    paragraphNotes: boolean
    transcriptionNotes: boolean
    singleFile: boolean
  }
  exportType: 'highlights' | 'entire'
}

interface IProps {
  onSave: (vals: ExportValues, timeOffset: boolean) => void
  userStatus?: IUserStatus
  disabled: boolean
  transcriptIds?: string[]
  timeCodePreferrence?: boolean
  userFeatures?: IUserFeatures
  metadata?: IMetadata
  transcriptId?: string
}

function CustomizedMenu({
  onSave,
  userStatus,
  disabled,
  transcriptIds,
  timeCodePreferrence,
  userFeatures,
}: IProps) {
  const [openMenu, setOpenMenu] = useState(false)
  const [timeOffset, setTimeOffset] = useState(false)
  const navigate = useNavigate()
  const [advancedOptions, setAdvancedOptions] = React.useState({
    timeCodes: false,
    speakerNames: false,
    paragraphNotes: false,
    transcriptionNotes: false,
    singleFile: false,
  })
  const { transcript } = useAppSelector((store) => store.getTranscript)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)

  const metadata = (transcript as ITranscript)?.metadata
  const transcriptId = (transcript as ITranscript)?.id

  useEffect(() => {
    if (timeCodePreferrence !== timeOffset) {
      setTimeOffset(timeCodePreferrence as boolean)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCodePreferrence])
  const [fileType, setFileType] = React.useState<
    'docx' | 'xls' | 'srt' | 'xmp' | 'audio' | 'discovery_script'
  >('docx')
  const [exportType, setExportType] = React.useState<'highlights' | 'entire'>(
    'entire',
  )
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState('none')
  const [translationEngine, setTranslationEngine] = React.useState(
    userFeatures?.translationDeepL ? 'deepl' : '',
  )

  const { t } = useTranslation()
  const hasDiscoveryExportEnabled = userFeatures && userFeatures.discoveryExport

  const fileTypes = [
    { value: 'docx', label: 'Microsoft Word' },
    { value: 'srt', label: 'Subtitles SRT' },
    { value: 'xmp', label: 'Premiere Pro XMP' },
    { value: 'xls', label: 'Microsoft Excel' },
    { value: 'audio', label: 'Audio' },
  ].concat(
    hasDiscoveryExportEnabled
      ? [{ value: 'discovery_script', label: 'Discovery script' }]
      : [],
  )

  const exportTypes = [
    { value: 'entire', label: t('exportMenu.entire') },
    { value: 'highlights', label: t('exportMenu.highlights') },
  ]

  const translationEngineTypes = [
    {
      value: 'deepl',
      label: 'DeepL',
      disabled: !userFeatures?.translationDeepL,
    },
    // { value: "google", label: "Google" },
  ]

  const handleTypeChange = (value: string | undefined) => {
    if (value) {
      // @ts-ignore
      setFileType(value)
    }
  }

  const handleExportChange = (value: string | undefined) => {
    if (value) {
      // @ts-ignore
      setExportType(value)
    }
  }

  const handleClose = () => {
    setOpenMenu(false)
  }
  const handleAdvancedOptions =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setAdvancedOptions({ ...advancedOptions, [name]: event.target.checked })
    }
  const handleSaveBtn = async () => {
    try {
      // Call translation API
      if (
        translationTargetLanguage &&
        translationTargetLanguage !== 'none' &&
        translationEngine
      ) {
        const token = await firebase.auth().currentUser?.getIdToken()
        const response = await fetch(
          `${process.env.REACT_APP_VM}/transcription/translate`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              transcriptId: transcriptIds?.[0] || transcriptId || '',
              language: translationTargetLanguage,
              translateEngine: translationEngine,
              exportType: exportType,
              format: fileType,
              advancedOptions: advancedOptions,
            }),
          },
        )
        console.log(response)
        if (response.status !== 200) {
          Toast({
            title: 'Error',
            text: 'Please try again or contact support',
            variant: 'error',
          })
          return
        }
        Toast({
          title: t('transcriptDetails.translationStarted'),
          text: t('transcriptDetails.translationDetails'),
          variant: 'success',
          options: { autoClose: 15000 },
        })
        setTranslationTargetLanguage('none')
        handleClose()
      } else {
        handleClose()
        const data: ExportValues = {
          fileType,
          exportType,
          advancedOptions,
        }
        onSave(data, timeOffset)
      }
      customEvent('transcript_export_requested', {
        category: 'transcript',
        transcriptId: transcriptId,
        fileType: fileType,
        accountId: currentAccount.id,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })
    } catch (error) {
      console.error(error)
      Toast({
        title: 'Error',
        text: 'Please try again or contact support',
        variant: 'error',
      })
    }
  }
  const handleBulkExport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const options = { ...advancedOptions }
    if (event.target.value === 'singleFile') {
      options.singleFile = true
      setAdvancedOptions(options)
    } else {
      options.singleFile = false
      setAdvancedOptions(options)
    }
  }

  const handleTranslationTargetLanguage = useCallback(
    (value: string | undefined) => {
      if (value) {
        setTranslationTargetLanguage(value)
      }
    },
    [],
  )

  const handleTranslationEngine = useCallback((value: string | undefined) => {
    if (value) {
      setTranslationEngine(value)
    }
  }, [])

  const getAdvancedOptions = () => {
    const upgradeScreen = () => {
      navigate('/payment-plan')

      // ReactGA.event({
      //   action: "upgrade button pressed",
      //   category: "payment",
      // })
    }

    if (
      (fileType === 'docx' || fileType === 'xls') &&
      userStatus &&
      (userStatus.status === 'active' || userStatus.status === 'in_trial')
    ) {
      return (
        <>
          <div className="pl-1" data-cy="data-project-export-advanced-options">
            <div data-cy="data-project-export-advanced-timecodes">
              <Checkbox
                label={t('exportMenu.timeCodes')}
                color="green"
                checked={timeCodes}
                onChange={handleAdvancedOptions('timeCodes')}
                value="timeCodes"
              />
            </div>
            <div data-cy="data-project-export-advanced-speaker-names">
              <Checkbox
                label={t('exportMenu.speakerNames')}
                color="green"
                checked={speakerNames}
                onChange={handleAdvancedOptions('speakerNames')}
                value="speakerNames"
              />
            </div>
            {fileType === 'docx' && (
              <div data-cy="data-project-export-advanced-description">
                <Checkbox
                  checked={transcriptionNotes}
                  color="green"
                  label={t('exportMenu.description')}
                  onChange={handleAdvancedOptions('transcriptionNotes')}
                  value="transcriptionNotes"
                />
              </div>
            )}
          </div>
          {fileType === 'xls' && transcriptIds && transcriptIds.length > 1 && (
            <div className="pl-1.5 pr-5 w-full">
              <div className="flex flex-row">
                <Radio
                  defaultChecked
                  name="excel"
                  color="green"
                  value="multipleFiles"
                  label={
                    <div>
                      <Typography className="text-base font-normal text-lstnBlueGray-900">
                        Multiple Files
                      </Typography>
                    </div>
                  }
                  data-cy="data-project-export-advanced-multiple"
                  crossOrigin={undefined}
                />
                <Radio
                  name="excel"
                  color="green"
                  value="singleFile"
                  label={
                    <div>
                      <Typography className="text-base font-normal text-lstnBlueGray-900">
                        Single File
                      </Typography>
                    </div>
                  }
                  data-cy="data-project-export-advanced-single"
                  crossOrigin={undefined}
                />
              </div>
            </div>
          )}
        </>
      )
    } else if (fileType === 'docx' || fileType === 'xls') {
      return (
        <div className="upgradeText">
          <div>{t('exportMenu.advancedOptions')}</div>
          <Typography className="text-sm font-semibold text-lstnBlueGray-900">
            {t('exportMenu.advancedOptions')}
          </Typography>
          {/* @ts-ignore */}
          <Button onClick={upgradeScreen} size="md" color="lstn-400">
            {t('exportMenu.upgrade')}
          </Button>
        </div>
      )
    }
    return null
  }

  const getAudioAdvancedOptions = () => {
    return (
      <div className="pl-2 pr-5 w-full">
        <div className="flex flex-row">
          <Radio
            defaultChecked
            color="green"
            name="audio"
            value="multipleFiles"
            label={
              <div>
                <Typography className="text-base font-normal text-lstnBlueGray-900">
                  Seperate Clips
                </Typography>
              </div>
            }
            onChange={handleBulkExport}
            crossOrigin={undefined}
          />
          <Radio
            color="green"
            name="audio"
            value="singleFile"
            label={
              <div>
                <Typography className="text-base font-normal text-lstnBlueGray-900">
                  One Clip
                </Typography>
              </div>
            }
            onChange={handleBulkExport}
            crossOrigin={undefined}
          />
        </div>
      </div>
    )
  }

  const getTranslationOptions = useCallback(() => {
    if (
      (fileType === 'docx' || fileType === 'srt') &&
      userStatus &&
      (userStatus.status === 'active' || userStatus.status === 'in_trial') &&
      (!transcriptIds || transcriptIds?.length === 1) &&
      userFeatures?.translation &&
      metadata?.languageCodes[0] &&
      unsupportedDeepLSourceLanguages.indexOf(metadata.languageCodes[0]) === -1
    ) {
      return (
        <>
          <div className="pl-4 text-sm mt-4 mb-2">
            <img
              src={CrownIcon}
              width={13}
              height={22}
              alt=""
              className="mb-2 relative t-1 mr-2.5"
            />
            <Typography className="text-sm font-semibold text-lstnBlueGray-900">
              {t('exportMenu.translation')}
            </Typography>
          </div>
          <Typography className="pl-4 mt-1 text-sm font-normal text-lstnBlueGray-900">
            {t('exportMenu.translationLanguageTitle', {
              language:
                translationSourceLanguages[
                  metadata?.languageCodes[0] || 'en-US'
                ],
            })}
          </Typography>
          <div
            data-cy="data-project-export-translation-language"
            className="px-[15px]"
          >
            <Selector
              selectClassName="border border-blue-gray-100 rounded-lg"
              className="w-auto"
              options={translationTargetLanguagesDeepL}
              selectedValue={translationTargetLanguage}
              onChange={handleTranslationTargetLanguage}
              children={undefined}
            />
          </div>
          {translationTargetLanguage &&
            translationTargetLanguage !== 'none' && (
              <>
                <Typography className="pl-4 mt-2.5 text-sm font-normal text-lstnBlueGray-900">
                  {t('exportMenu.translationEngineTitle')}
                </Typography>
                <div
                  data-cy="data-project-export-translation-engine "
                  className="px-[15px]"
                >
                  <Selector
                    selectClassName="border border-blue-gray-100 rounded-lg"
                    className="w-auto"
                    options={translationEngineTypes}
                    selectedValue={translationEngine}
                    onChange={handleTranslationEngine}
                    children={undefined}
                  />
                </div>
              </>
            )}
        </>
      )
    } else {
      return <></>
    }
  }, [
    fileType,
    userStatus,
    t,
    translationTargetLanguage,
    handleTranslationTargetLanguage,
    translationEngineTypes,
    translationEngine,
    handleTranslationEngine,
    metadata,
    transcriptIds,
    userFeatures,
  ])

  const { timeCodes, speakerNames, transcriptionNotes } = advancedOptions
  const showAdvancedOptions =
    fileType === 'docx' || fileType === 'xls' || fileType === 'audio'
  const showExportTypes = fileType === 'docx' || fileType === 'audio'
  const showAudioAdvancedOptions =
    fileType === 'audio' && exportType === 'highlights'

  return (
    <>
      <div data-cy="data-project-export">
        <Menu
          dismiss={{
            itemPress: false,
          }}
          open={openMenu}
          handler={setOpenMenu}
          placement="bottom-end"
        >
          <MenuHandler>
            <Button
              placeholder={undefined}
              size="md"
              className="flex items-center bg-lstnGreen-500 "
              data-tut="SaveAs-details-tour"
              aria-controls="customized-menu"
              aria-haspopup="true"
              disabled={disabled}
            >
              <img src={downloadIcon} alt="img" width={14} className="mr-3" />
              {t('exportMenu.saveAs')}
            </Button>
          </MenuHandler>
          <MenuList
            placeholder={undefined}
            className="w-auto min-w-[275px] py-2 px-3"
            data-cy="data-project-export-menu"
          >
            <div className="pl-4 text-base mt-2">
              <Typography className="text-sm font-semibold text-lstnBlueGray-900">
                {t('exportMenu.fileType')}
              </Typography>
            </div>
            <div
              data-cy="data-project-export-file-type"
              className="mt-2 px-[15px]"
            >
              <Selector
                selectClassName="border border-blue-gray-100 rounded-lg isolate"
                className="w-auto"
                selectedValue={fileType}
                options={fileTypes}
                onChange={handleTypeChange}
                children={undefined}
              />
            </div>
            {showAdvancedOptions && (
              <div className="pl-4 text-sm mt-4 mb-2">
                <img
                  src={CrownIcon}
                  width={13}
                  height={22}
                  className="mb-2"
                  alt=""
                />
                <Typography className="text-sm font-semibold text-lstnBlueGray-900">
                  {t('exportMenu.advanced')}
                </Typography>
              </div>
            )}

            {getAdvancedOptions()}

            {showExportTypes &&
              userStatus &&
              (userStatus.status === 'active' ||
                userStatus.status === 'in_trial') && (
                <div
                  data-cy="data-project-export-type"
                  className="mt-2 px-[15px]"
                >
                  <Selector
                    selectClassName="border border-blue-gray-100 rounded-lg"
                    className="w-auto"
                    options={exportTypes}
                    selectedValue={exportType}
                    onChange={handleExportChange}
                    children={undefined}
                  />
                </div>
              )}

            {showAudioAdvancedOptions && getAudioAdvancedOptions()}

            {getTranslationOptions()}

            <div className="mt-2.5 px-4 w-full">
              <Button
                placeholder={undefined}
                color="green"
                size="sm"
                className="h-10 gap-2 text-xs font-medium uppercase bg-lstnGreen-500"
                variant="filled"
                onClick={handleSaveBtn}
                fullWidth
                data-cy="data-project-export-save"
              >
                {t('exportMenu.save')}
              </Button>
            </div>
          </MenuList>
        </Menu>
      </div>
    </>
  )
}

// const mapStateToProps = (state: IRootState) => ({
//   metadata: state.transcript.metadata,
//   transcriptId: state.transcript.id,
// });
export default CustomizedMenu
