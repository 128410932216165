import React from 'react'
import { Select, Option, Chip } from '@material-tailwind/react'
import type { SelectProps } from '@material-tailwind/react'
import imgIcon from '../../icons/calendar.svg'

interface IProps {
  options: Array<{
    value: string
    label: string
  }>
  selectedValue?: string
  onChange: (value: string) => void
}

export const Selector: React.FC<IProps & SelectProps> = (props) => {
  return (
    <div className={`${props.className} border-none relative min-w-[100px]`}>
      <label className="absolute top-2 cursor-pointer" htmlFor="select">
        <Chip
          icon={<img src={imgIcon} className="w-5" alt="img" />}
          open={true}
          animate={{
            mount: { y: 0 },
            unmount: { y: 50 },
          }}
          size="sm"
          className="bg-[#ECEFF1] text-[#78909C] font-medium w-fit capitalize"
          value={props.selectedValue}
        />
      </label>
      <Select
        id="select"
        onChange={props.onChange}
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        className={`${props.className} border border-lstnBlueGray-600 h-[2.46rem] top-[-1px]`}
        value={props.selectedValue}
        labelProps={{
          className: 'hidden',
        }}
        placeholder={props.selectedValue}
        label={props.selectedValue}
        arrow={undefined}
      >
        {props.options &&
          props.options.map((option) => (
            <Option
              key={option.value}
              className="z-20 flex items-center justify-between capitalize"
              value={option.value}
              data-cy={`data-project-export-file-type-${option.value}`}
            >
              {option.label}
            </Option>
          ))}
      </Select>
    </div>
  )
}
