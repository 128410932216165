// tslint:disable: ordered-imports
// @ts-nocheck
import * as React from 'react'
import { useState, useRef, useEffect, useCallback } from 'react'
import ReactGA from 'react-ga4'
// import { connect } from "react-redux"
// import { compose } from 'redux'
import { useNavigate, useParams } from 'react-router'
// import { ActionCreators } from "redux-undo"
// import { firestoreConnect } from 'react-redux-firebase'
// import { withHandlers } from 'recompose'
// import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
// import { lstnBasictheme } from '../theme/lstn-theme-basic'
import { functions } from '../firebaseApp'
import { ITranscript, IUser } from '../interfaces'
import EditorsRoleArrange from '../components/RolesAccessComponents/EditorsRolesArrange'
import Toast from '../components/Toast/Toast'
// import ConfirmationDialog from '../components/TranscriptsList/ConfirmationDialog'
import { ExportValues } from '../components/ExportMenu/ExportMenu'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import fileDownloader from '../utils/fileDownloader'
import { IUserStatusState } from '../store/reducers/userStatus'
import '../css/TranscriptDetails.css'
import RenameDialog from '../components/TranscriptsList/RenameDialog'
import '../css/TranscriptDetails.css'
import TranscriptDetailsTour from '../components/onBoarding/ContentDetailsTour'
import { TransProps } from 'react-i18next'
import { KeyboardBackspace } from '@material-ui/icons'
import { roleType } from '../enums'
import { useAppDispatch, useAppSelector } from '../store/store'
import { fetchTransctipt } from '../store/features/transcript/helper'
import { selectTranscript } from '../store/features/paragraphSlice'
import { customEvent } from '../utils/customHooks'
import { database } from '../firebaseApp'
import { useTranslation } from 'react-i18next'
import Typography from '../components/Typography/Typography'
import { Textarea } from '@material-tailwind/react'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import Button from '../components/Button/TailwindButton'
import GroupButton from '../components/GroupButton'

interface IProps {
  transcripts: { [key: string]: ITranscript }
  user: firebase.UserInfo
  userStatus: IUserStatusState
  userData: IUser[]
  errors: []
  // onBoardingComplete: (id: string, data: ) => Promise<void>
  transcriptFetched: boolean
}

interface IReduxStateToProps {
  transcript: ITranscript & { paragraphsForTranscript: string }
}

interface IReduxDispatchToProps {
  clearHistory: () => void
  selectTranscript: (transcriptId: string, transcript: ITranscript) => void
  // updateDescription: (id: string, data: ) => void
}

interface IonBoarding {
  uploadVideos?: boolean
  transcriptionEdit?: boolean
}

const Transcript: React.FC<
  IProps & IReduxStateToProps & IReduxDispatchToProps & TransProps
> = (props) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const navigate = useNavigate()
  const params = useParams()
  // const [highlight, setHighlight] = useState<boolean>(false)
  const [openDeletionConfirmation, setOpenDeletionConfirmation] =
    useState<boolean>(false)
  const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false)
  const [openTour, setOpenTour] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')

  const dispatch = useAppDispatch()
  const { transcript, errors, transcriptFetched } = useAppSelector(
    (store) => store.getTranscript,
  )
  const { userData, roles } = useAppSelector((store) => store.user)
  const userStatus = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const { t } = useTranslation()

  const actionSidePanel: boolean = currentAccount?.features?.actionsidepanel
  useEffect(() => {
    dispatch(fetchTransctipt(params?.id))
  }, [params.id])

  useEffect(() => {
    if (
      userData &&
      userData.onboardingSteps &&
      !userData.onboardingSteps.transcriptionEdit
    ) {
      runTranscriptionOnboarding()
    }

    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (
      userData &&
      userData.onboardingSteps &&
      !userData.onboardingSteps.transcriptionEdit &&
      !openTour
    ) {
      runTranscriptionOnboarding()
    }

    const transcriptId = params?.id
    // const { transcriptFetched } = props
    if (
      transcriptId !== undefined &&
      transcript !== undefined &&
      transcriptFetched
    ) {
      if (transcript && Object.keys(transcript).length > 0) {
        // props.selectTranscript(transcriptId, transcript)
        dispatch(selectTranscript({ transcriptId, transcript }))
      }
    }

    if (transcript.paragraphs !== undefined) {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'auto'
        textAreaRef.current.style.height =
          textAreaRef.current.scrollHeight + 'px'
      }
    }
  }, [transcriptFetched, transcript])

  const runTranscriptionOnboarding = useCallback(() => {
    setOpenTour(true)
  }, [])

  const closeOnboarding = useCallback(async () => {
    const onboardingSteps = { ...userData.onboardingSteps }
    onboardingSteps.transcriptionEdit = true
    await onBoardingComplete(props.user.uid, { onboardingSteps })
    setOpenTour(false)
  }, [])

  const checkTranscriptRole = useCallback(
    (checkrole: string) => {
      let role = []

      if (
        props.user &&
        props.user.uid &&
        transcript &&
        transcript.sharedUserRoles
      ) {
        const userValue = transcript.sharedUserRoles[props.user.uid]
        if (transcript.userId === props.user.uid) {
          role = roles['transcripts'][roleType.OWNER]
        } else if (userValue && userValue === roleType.SHAREDEDIT) {
          role = roles['transcripts'][roleType.EDITOR]
        } else {
          role = roles['transcripts'][roleType.VIEWER]
        }
      }

      return role.includes(checkrole)
    },
    [props.user, transcript, roles],
  )

  const handleEditButton = useCallback(() => {
    if (checkTranscriptRole('editTranscript') || checkTranscriptRole('*'))
      setOpenRenameDialog(true)
    customEvent('transcript_rename_modal_opened', {
      category: 'transcript',
      transcriptId: transcript.id,
      user_userId: props.user.uid,
      accountId: currentAccount.id,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })
  }, [checkTranscriptRole])

  const handleEditClose = useCallback(
    async (status: boolean, value?: string) => {
      const clientId: string = ''
      // ReactGA.ga((tracker: ) => {
      //   clientId = tracker.get("clientId")
      // })
      setOpenRenameDialog(false)
      if (status) {
        const id = params?.id
        // ReactGA.event({
        //   action: "Updating Transcript name",
        //   category: "transcript",
        // })
        customEvent('transcript_renamed', {
          category: 'transcript',
          transcriptId: id,
          user_userId: props.user.uid,
          accountId: currentAccount.id,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        })
        const updateTranscript = functions.httpsCallable('updateTranscript')

        try {
          await updateTranscript({
            transcriptId: id,
            transcriptData: { name: value },
            clientId,
          })
        } catch (error) {
          console.error(error)
          // ReactGA.exception({
          //   description: error.message,
          //   fatal: false,
          //   category: "transcript",
          // })
          ReactGA.gtag('event', 'transcript_renamed_exception', {
            description: error.message,
            fatal: false,
          })
        }
      }
    },
    [params.id],
  )

  const handleDeleteButtonClicked = useCallback(() => {
    setOpenDeletionConfirmation(true)
    customEvent('transcript_delete_confirm_modal_opened', {
      category: 'transcript',
      transcriptId: params.id,
      user_userId: props.user.uid,
      accountId: currentAccount.id,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })
  }, [])

  const handleDeletionClose = useCallback(() => {
    // const clientId: string = ''
    // ReactGA.ga((tracker: ) => {
    //   clientId = tracker.get("clientId")
    // })
    setOpenDeletionConfirmation(false)
  }, [])
  const handleDeletionConfirm = useCallback((status?: boolean) => {
    const clientId: string = ''
    setOpenDeletionConfirmation(false)
    if (status) {
      handleDeletion(clientId)
    }
  }, [])

  const handleDescription = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (checkTranscriptRole('editTranscript') || checkTranscriptRole('*')) {
        // const transcriptId = props.match.params.id
        const data = { ...transcript }
        ev.target.style.height = 'auto'
        ev.target.style.height = ev.target.scrollHeight + 'px'
        data.description = ev.target.value
        setDescription(ev.target.value)
        updateDescription(data.id, data)
      }
    },
    [params.id, checkTranscriptRole],
  )

  async function updateDescription(id: string, data: ITranscript) {
    try {
      const userRef = database.collection(`transcripts`).doc(id)
      await userRef.update({ ...data })
      // console.log('&&&&',userRef);
    } catch (error) {
      console.error('error', error)
    }
  }

  async function onBoardingComplete(id: string, data: IonBoarding) {
    try {
      const userRef = database.collection(`users`).doc(id)
      await userRef.update({ ...data })
      // console.log('&&&&',userRef);
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleExportTranscriptButtonClicked = useCallback(
    async (data: ExportValues, timeOffset: boolean) => {
      // ReactGA.event({
      //   action: "export button pressed",
      //   category: "transcript",
      //   label: data.fileType,
      // })
      customEvent('transcript_exported', {
        category: 'transcript',
        transcriptId: params.id,
        user_userId: props.user.uid,
        fileType: data.fileType,
        accountId: currentAccount.id,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })
      // const { t } = props
      const transcriptId = params.id
      const id = transcriptId
      if (firebase.auth().currentUser) {
        // Display toast notification
        Toast({
          title: t && t('transcriptDetails.pleaseWait'),
          text: t && t('transcriptDetails.export'),
          variant: 'success',
        })

        const token = await firebase.auth().currentUser.getIdToken()
        axios
          .post(
            `${process.env.REACT_APP_FIREBASE_HTTP_CLOUD_FUNCTION_URL_API}/api/transcripts/${id}/export`,
            { data, timeOffset },
            {
              headers: {
                authorization: `Bearer ${token}`,
                'Access-Control-Expose-Headers': 'Content-Disposition',
              },
              responseType: 'arraybuffer',
            },
          )
          .then((res) => {
            fileDownloader(
              res,
              data.fileType,
              props.user.uid,
              transcriptId,
              currentAccount.id,
              currentProject,
            )
          })
          .catch((err) => {
            if (err && err.response && err.response.status == 422) {
              Toast({
                title: 'Error',
                text: 'Something is not right. Please contact support',
                variant: 'warn',
              })
            } else {
              Toast({
                title: 'Error',
                text: 'Please try again or contact support',
                variant: 'error',
              })
            }
          })
      }
    },
    [params.id, t],
  )

  const handleDeletion = useCallback(
    async (clientId: string) => {
      // ReactGA.event({
      //   action: "delete button pressed",
      //   category: "transcript",
      // })
      customEvent('transcript_deleted', {
        category: 'transcript',
        transcriptId: params.id,
        user_userId: props.user.uid,
        accountId: currentAccount.id,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })

      const { t } = props

      // Display toast notification
      Toast({
        title: t && t('transcriptDetails.pleaseWait'),
        text: t && t('transcriptDetails.delete'),
        variant: 'success',
      })
      const transcriptId = params.id
      const archiveTranscript = functions.httpsCallable('archiveTranscript')

      try {
        navigate('/transcripts/')
        await archiveTranscript({ transcriptId, clientId })
      } catch (error) {
        console.error(error)
        // ReactGA.exception({
        //   description: error.message,
        //   fatal: false,
        // })
        ReactGA.gtag('event', 'transcript_deleted_exception', {
          description: error.message,
          fatal: false,
        })
      }
    },
    [params.id],
  )

  // const handleHighlightButtonClicked = useCallback(() => {
  //   setHighlight(true)
  // }, [])

  // const highlightCompleted = useCallback(() => {
  //   setHighlight(false)
  // }, [])

  // const transcript = props.transcript
  const transcriptId = params.id
  // const { userStatus } = props

  // const { roles } = props

  if (Object.entries(transcript).length === 0) {
    if (errors.byQuery['transcript'] !== undefined) {
      return (
        <div className="permission">
          <div>
            <div className="permissionText">
              <h3>{t && t('transcripts.permission')}</h3>
            </div>
            <button onClick={() => navigate(-1)} className="go-back">
              <KeyboardBackspace />
              <span href="">{t && t('transcripts.goback')}</span>
            </button>
          </div>
        </div>
      )
    }
    return 'Loading'
  } else {
    return (
      <main className="transcript-details">
        <div className="flex flex-row-reverse gap-4">
          {actionSidePanel && <GroupButton userId={props.user.uid} />}
          <div className="transcript-details-container">
            <RenameDialog
              open={openRenameDialog}
              initialValue={transcript.name ? transcript.name : ''}
              onClose={handleEditClose}
            />
            <div className="transcript-details-title">
              <Typography
                className="text-2xl font-medium text-lstnBlueGray-900"
                data-cy="data-project-transcript-details-title"
                onClick={handleEditButton}
              >
                {transcript.name}
              </Typography>
            </div>
            <section className="transcript-details-description">
              <Textarea
                data-cy="transcript-details-description-data"
                className="min-h-[20px] pt-1 text-sm font-normal text-lstnBlueGray-400"
                labelProps={{ className: 'hidden' }}
                rows={1}
                ref={textAreaRef}
                onChange={handleDescription}
                placeholder={t && t('transcriptDetails.description')}
                value={description}
              />
            </section>
            <div>
              {transcript && (
                <EditorsRoleArrange
                  transcript={transcript}
                  transcriptId={transcriptId}
                  userStatus={userStatus}
                  onDelete={handleDeleteButtonClicked}
                  onExport={handleExportTranscriptButtonClicked}
                  userData={userData}
                  roles={roles}
                />
              )}
            </div>
            {transcript && (
              <TranscriptDetailsTour
                openTour={openTour}
                closeTour={closeOnboarding}
              />
            )}
          </div>
        </div>
        {/* <MuiThemeProvider theme={lstnBasictheme}> */}
        <Dialog
          size="xs"
          open={openDeletionConfirmation}
          // headerText={t && t('transcripts.delete')}
          // bodyText={t && t('transcripts.confirmation')}
          // onClose={handleDeletionClose}
        >
          <DialogHeader>{t && t('transcripts.delete')}</DialogHeader>
          <DialogBody>{t && t('transcripts.confirmation')}</DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="blue-gray-900"
              onClick={handleDeletionClose}
              data-cy="data-project-delete-cancel"
              className="mr-1 uppercase"
            >
              <span>{t('renameDialog.cancel')}</span>
            </Button>
            <Button
              variant="text"
              color="red"
              onClick={handleDeletionConfirm}
              data-cy="data-project-delete-submit"
              className="uppercase"
            >
              <span>{t('transcripts.delete')}</span>
            </Button>
          </DialogFooter>
        </Dialog>
        {/* </MuiThemeProvider> */}
      </main>
    )
  }
}

// const mapStateToProps = (state: State) => {
//   let transcript: ITranscript = {}
//   if (state.firestore.ordered.transcript && state.firestore.ordered.transcript[0]) {
//     console.log(state.firestore.ordered.transcript[0],'tr');
//     transcript = state.firestore.ordered.transcript[0]
//   }

//   let roles
//   if (state.firestore.data.roles) roles = state.firestore.data.roles[Object.keys(state.firestore.data.roles)[0]]

//   return {
//     // transcript,
//     // transcripts: filterTranscriptObject(state.firestore.data.transcriptListShareUserId ? state.firestore.data.transcriptListShareUserId : state.firestore.data.transcriptList),
//     // userData: state.firestore.ordered.users,
//     // userStatus: state.userStatus,
//     // auth: state.firebase.auth,
//     // errors: state.firestore.errors,
//     // transcriptFetched: state.firestore.status.requested.transcript,
//     // roles,
//   }
// }

// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     clearHistory: () => dispatch(ActionCreators.clearHistory()),
//     selectTranscript: (transcriptId: string, transcript: ITranscript) => dispatch(selectTranscript(transcriptId, transcript)),
//   }
// }

// export default compose(
//   // connect( mapDispatchToProps),
//   firestoreConnect((props: ) => {
//     return [
//       {
//         collection: 'transcripts',
//         doc: props.match.params.id,
//         storeAs: 'transcript',
//       },
//       {
//         collection: 'users',
//         doc: props.user.uid,
//       },
//     ]
//   }),
// withHandlers({
//   onBoardingComplete: (props: WithFirestoreProps) => async (id: string, data: ) => {
//     console.log('hell2');
//     await props.firestore.update(`users/${id}`, { ...data })
//   },
// }),
// withHandlers({
//   updateDescription: (props) => (id: string, data: ITranscript) => {
//     console.log('hello');

//     props.firestore.update(`transcripts/${id}`, { ...data })
//   },
// }),
//   withTranslation(),
// )(Transcript)
export default Transcript
